import { Injectable } from "@angular/core";
import { Mixin } from "@vmw/ngx-utils";
import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";
import { ENGLISH } from "./clr-common-strings-service.l10n";
import { TranslateFunction } from "./l10n.model";

@Injectable({
    providedIn: "root",
})
@Mixin([LocalizedComponent], {
    L10nKey: "clr-common-strings-service",
    L10nSourceMap: ENGLISH,
})
export class CceClrCommonStringsService {
    translate!: TranslateFunction<typeof ENGLISH>;

    constructor(private l10nService: L10nService) {}

    public getLocalizedClrCommonStrings(): any {
        return {
            expand: this.translate("clarity-common-strings.expand"),
            collapse: this.translate("clarity-common-strings.collapse"),
            open: this.translate("clarity-common-strings.open"),
            close: this.translate("clarity-common-strings.close"),
            show: this.translate("clarity-common-strings.show"),
            hide: this.translate("clarity-common-strings.hide"),
            more: this.translate("clarity-common-strings.more"),
            select: this.translate("clarity-common-strings.select"),
            selectAll: this.translate("clarity-common-strings.select-all"),
            previous: this.translate("clarity-common-strings.previous"),
            next: this.translate("clarity-common-strings.next"),
            current: this.translate("clarity-common-strings.current"),
            info: this.translate("clarity-common-strings.info"),
            success: this.translate("clarity-common-strings.success"),
            warning: this.translate("clarity-common-strings.warning"),
            danger: this.translate("clarity-common-strings.danger"),
            rowActions: this.translate("clarity-common-strings.row-actions"),
            pickColumns: this.translate("clarity-common-strings.pick-columns"),
            showColumns: this.translate("clarity-common-strings.show-columns"),
            sortColumn: this.translate("clarity-common-strings.sort-column"),
            firstPage: this.translate("clarity-common-strings.first-page"),
            lastPage: this.translate("clarity-common-strings.last-page"),
            nextPage: this.translate("clarity-common-strings.next-page"),
            previousPage: this.translate("clarity-common-strings.previous-page"),
            currentPage: this.translate("clarity-common-strings.current-page"),
            totalPages: this.translate("clarity-common-strings.total-pages"),
            minValue: this.translate("clarity-common-strings.min-value"),
            maxValue: this.translate("clarity-common-strings.max-value"),
            showColumnsMenuDescription: this.translate("clarity-common-strings.show-columns-menu-description"),
            allColumnsSelected: this.translate("clarity-common-strings.all-columns-selected"),
            loading: this.translate("clarity-common-strings.loading"),
            datepickerToggleChooseDateLabel: this.translate("clarity-common-strings.date-picker-toggle"),
            datepickerToggleChangeDateLabel: this.translate("clarity-common-strings.date-picker-toggle"),
            datepickerPreviousMonth: this.translate("clarity-common-strings.previous-month"),
            datepickerCurrentMonth: this.translate("clarity-common-strings.current-month"),
            datepickerNextMonth: this.translate("clarity-common-strings.next-month"),
            datepickerPreviousDecade: this.translate("clarity-common-strings.previous-decade"),
            datepickerNextDecade: this.translate("clarity-common-strings.next-decade"),
            datepickerCurrentDecade: this.translate("clarity-common-strings.current-decade"),
            datepickerSelectMonthText: this.translate("clarity-common-strings.date-picker-select-month"),
            datepickerSelectYearText: this.translate("clarity-common-strings.date-picker-select-year"),
            singleActionableAriaLabel: this.translate("clarity-common-strings.available-actions"),
            alertCloseButtonAriaLabel: this.translate("clarity-common-strings.close"),
            signpostToggle: this.translate("clarity-common-strings.signpost-toggle"),
            timelineStepNotStarted: this.translate("clarity-common-strings.not-started"),
            timelineStepCurrent: this.translate("clarity-common-strings.current"),
            timelineStepProcessing: this.translate("clarity-common-strings.in-progress"),
            timelineStepSuccess: this.translate("clarity-common-strings.completed"),
            timelineStepError: this.translate("clarity-common-strings.error"),
            detailExpandableAriaLabel: this.translate("clarity-common-strings.toggle-more-row-content"),
            singleSelectionAriaLabel: this.translate("clarity-common-strings.select"),
            daypickerSRCurrentMonthPhrase: this.translate("clarity-common-strings.day-picker-sr-current-month-phrase"),
            daypickerSRCurrentYearPhrase: this.translate("clarity-common-strings.day-picker-sr-current-year-phrase"),
            daypickerSRCurrentDecadePhrase: this.translate("clarity-common-strings.day-picker-sr-current-decade-phrase"),
            stackViewChanged: this.translate("clarity-common-strings.stack-view-changed"),
            datagridExpandableBeginningOf: this.translate("clarity-common-strings.datagrid-expandable-beginning-of"),
            datagridExpandableEndOf: this.translate("clarity-common-strings.datagrid-expandable-end-of"),
            datagridExpandableRowContent: this.translate("clarity-common-strings.datagrid-expandable-row-content"),
            datagridExpandableRowsHelperText: this.translate("clarity-common-strings.datagrid-expandable-row-helper-text"),

            modalContentStart: this.translate("clarity-common-strings.modal-content-start"),
            modalContentEnd: this.translate("clarity-common-strings.modal-content-end"),
            signpostClose: this.translate("clarity-common-strings.signpost-close"),
            detailPaneStart: this.translate("clarity-common-strings.detail-pane-start"),
            detailPaneEnd: this.translate("clarity-common-strings.detail-pane-end"),
            verticalNavToggle: this.translate("clarity-common-strings.vertical-nav-toggle"),
        };
    }
}
