export const USAGE_MANAGEMENT_ROUTE_SEGMENT: string = "usage-management/";

export const METRIC_ROUTE_SEGMENT: string = "metrics";
export const SERVICE_ROUTE_SEGMENT: string = "services";
export const SELLER_PARAM_KEY: string = "seller";
export const USAGE_MANAGEMENT_TITLE: string = "CCE Cost + Usage";

export const METRIC_USAGE_OVERVIEW_URL = "/commerce/cost-and-usage/api/{orgId}/usage/overview";
export const METRIC_SEARCH_URL = "/commerce/cost-and-usage/api/{orgId}/metrics/search";
export const TOP_USAGE_URL = "/commerce/cost-and-usage/api/{orgId}/usage/top-items";
export const USAGE_HISTORY_URL = "/commerce/cost-and-usage/api/{orgId}/usage/historical-records";

export const SERVICE_DEFINITION_ID_PARAM = "service_definition_id";
export const METRIC_ID_PARAM = "metric_id";
export const BEFORE_TIME_PARAM = "before_time";
export const LOCALE_PARAM = "locale";
export const PAGE_PARAM = "page";
export const PAGE_SIZE_PARAM = "size";
export const SORT_PARAM = "sort";
export const COUNT_PARAM = "count";
export const START_DATE_PARAM = "start_date";
export const SELLER_PARAM = "seller";
export const END_DATE_PARAM = "end_date";
export const AGGREGATION_PERIOD_PARAM = "aggregation_period";
export const AGGREGATION_TYPE_PARAM = "aggregation_type";
export const LAST_UPDATED_PARAM = "usage_date_time";

export const MONTHLY_SERVICE_IDS = [
    "I8_vUYLiPxopN_YFGqhgbH6rrAI_", // vRLIC staging
    "7cJ2ngS_hRCY_bIbWucM4KWQwOo_", // vRLIC production
];

export const SERVICES_NO_USAGE = [
    "da17a299-1c87-47dd-8a89-de9af20a89a4", // Arctic staging
    "ee1b1892-b97c-4268-a610-b05cde019213", // Arctic production

    "1fb3790a-248d-4d53-bd8e-53ee163cdb53", // vRealize Subscription Manager staging
    "7b3d98d8-6d1b-4cee-bcca-0743894c5f2b", // vRealize Subscription Manager production
];

/**
 * Set a number of pixels to act as the max width of each bar on the chart
 */
export const MAX_BAR_THICKNESS = 150;

export const SELLER_MULTIPLE = "MULTIPLE";
export const SELLER_AWS = "AWS";
export const SELLER_VMWARE = "VMWARE";

export const DAY_IN_MILLISECONDS = 86400000;
