import { Component, Input } from "@angular/core";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";

import { VmwDateRangePickerPosition, VmwDateRangePickerDateRange, VmwDateRangePickerRanges } from "@vmw/ngx-components/date-range-picker";

import { Mixin } from "@vmw/ngx-utils";
import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";
import { ENGLISH } from "./date-range-picker.component.l10n";
import { TranslateFunction } from "@cce/core";
import { CceUsageMetricAggregationPeriod } from "../../usage-management.model";
import { CspFeatureFlagClient } from "@vmw/csp-ngx-ff-sdk";

import { CostAndUsageFeatureFlags } from "../../../feature-flags.enum";
import { DAY_IN_MILLISECONDS } from "../../usage-management.const";

@Component({
    selector: "cce-date-range-picker",
    templateUrl: "./date-range-picker.component.html",
    styleUrls: ["./date-range-picker.component.scss"],
})
@Mixin([LocalizedComponent], {
    L10nKey: "date-range-picker",
    L10nSourceMap: ENGLISH,
})
export class DateRangePickerComponent {
    public readonly CceUsageMetricAggregationPeriod = CceUsageMetricAggregationPeriod;
    public readonly VmwDateRangePickerRanges = VmwDateRangePickerRanges;
    public readonly VmwDateRangePickerPosition = VmwDateRangePickerPosition;

    public translate: TranslateFunction<typeof ENGLISH>;
    public monthlyAggregationFlag: boolean = false;

    public form: FormGroup;
    public originalAggregationPeriod: CceUsageMetricAggregationPeriod = CceUsageMetricAggregationPeriod.DAILY;
    public originalDateRange: VmwDateRangePickerDateRange;
    public currentDateRange: VmwDateRangePickerDateRange;
    public isValid: boolean = true;

    @Input() maxDate: Date;
    @Input() minDate: Date;

    constructor(public l10nService: L10nService, private formBuilder: FormBuilder, private ffClient: CspFeatureFlagClient) {
        this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableMonthlyAggregation).subscribe((enabled) => {
            this.monthlyAggregationFlag = enabled;
        });
        this.form = this.formBuilder.group({
            dateRange: new FormControl(),
            aggregationPeriod: new FormControl(this.originalAggregationPeriod),
        });
    }

    public onRangeChange(range: VmwDateRangePickerDateRange) {
        // record the original date range the first time the range is emitted
        if (!this.originalDateRange) {
            this.originalDateRange = range;
        }

        this.currentDateRange = range;

        const diff = this.calculateRangeDifferenceInDays(range);

        if (diff <= 3) {
            this.form.get("aggregationPeriod").setValue(CceUsageMetricAggregationPeriod.HOURLY);
        } else if (this.monthlyAggregationFlag && diff > 90) {
            this.form.get("aggregationPeriod").setValue(CceUsageMetricAggregationPeriod.MONTHLY);
        } else {
            this.form.get("aggregationPeriod").setValue(CceUsageMetricAggregationPeriod.DAILY);
        }
    }

    private calculateRangeDifferenceInDays(range: VmwDateRangePickerDateRange): number {
        // remove timezone offset to ensure that if daylight-savings is involved, we don't
        // get fractional days
        const start = +range.start - range.start.getTimezoneOffset() * 60 * 1000;
        const end = +range.end - range.end.getTimezoneOffset() * 60 * 1000;

        let diff = end - start;

        return diff / DAY_IN_MILLISECONDS;
    }

    public cancelDateRangePicker() {
        this.form.get("aggregationPeriod").setValue(this.originalAggregationPeriod);
        this.currentDateRange = this.originalDateRange;
    }

    public applyDateRangeChanges() {
        this.originalAggregationPeriod = this.form.get("aggregationPeriod").value;
        this.originalDateRange = this.currentDateRange;
    }
}
