import { Component, Input } from "@angular/core";

import { Subject } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

import { Mixin } from "@vmw/ngx-utils";
import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";
import { CspCrossOrgSwitcherService, CspApiService, Service } from "@vmw/csp-ngx-components";

import { L10nKey, TranslateFunction } from "@cce/core";

import { ENGLISH } from "./service-filter.component.l10n";
import { CceUsageMetricUsageRecordViewModel } from "../usage-management.model";

@Component({
    selector: "service-filter",
    templateUrl: "service-filter.component.html",
    styleUrls: ["./service-filter.component.scss"],
})
@Mixin([LocalizedComponent], {
    L10nKey: "service-filter",
    L10nSourceMap: ENGLISH,
})
export class ServiceFilterComponent {
    public selectedServices: string[] = [];
    public translate: TranslateFunction<typeof ENGLISH>;
    public changes = new Subject<string[]>();

    public services: Service[] = [];

    @Input()
    public totalGridRows: number;

    @Input()
    public currentGridData: CceUsageMetricUsageRecordViewModel[];

    constructor(
        public l10nService: L10nService,
        private cspApiService: CspApiService,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService
    ) {}

    ngOnInit() {
        this.crossOrgSwitcherService.orgDetails$
            .pipe(
                filter(Boolean),
                switchMap((org) => {
                    this.services = [];
                    this.selectedServices = [];
                    return this.cspApiService.getOrgServices(org.id);
                })
            )
            .subscribe({
                next: (services) => {
                    if (this.totalGridRows > this.currentGridData.length) {
                        this.services = services;
                    } else {
                        this.services = this.getUsedServicesOnly(services);
                    }
                },
            });
    }

    isActive(): boolean {
        return this.selectedServices.length > 0;
    }

    accepts() {
        return true;
    }

    toggleServiceSelection(serviceId: string) {
        const index = this.selectedServices.indexOf(serviceId);
        if (index > -1) {
            this.selectedServices.splice(index, 1);
        } else {
            this.selectedServices.push(serviceId);
        }
        this.changes.next(this.selectedServices);
    }

    onClearSelection() {
        this.selectedServices = [];
        this.changes.next(this.selectedServices);
    }

    private getUsedServicesOnly(services: Service[]) {
        // only return the services that appear in the data grid
        const gridServiceIds = this.currentGridData.map((s) => s.serviceId);
        return services.filter((s) => gridServiceIds.includes(s.id));
    }
}
