export const ENGLISH = {
    overview: "Overview",
    "total-usages": "Total Usage Types",
    "below-commitment": "Below Commitment",
    "over-commitment": "Over Commitment",
    "at-commitment": "At Commitment",
    "on-demand": "On-demand",
    "insight-cards-heading": "Insights",
    "last-30-days": "Last 30 Days",
    "no-insights": "No Current Insights",
    "no-insights-message": "We currently do not have any suggestions for you.",
};
