import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { ClarityModule } from "@clr/angular";

import { CspComponentsModule, CspRecommendationsModuleLib, CspCrossOrgSwitcherModule } from "@vmw/csp-ngx-components";
import { CspHeaderModule } from "@vmw/csp-ngx-components/header";
import { CspServiceSdkModule } from "@vmw/csp-ngx-service-sdk";
import { VmwComponentsModule } from "@vmw/ngx-components";
import { VmwLegendModule } from "@vmw/ngx-charts";
import { VmwBarChartModule } from "@vmw/ngx-charts/bar-chart";
import { VmwContextualHelpModule } from "@vmw/ngx-contextual-help";
import { OAuth2ModuleConfiguration, OAuth2Module } from "@vmw/ngx-csp-oauth2";
import { VmwThemeToolsModule } from "@vmw/ngx-utils";
import { VmwClrDatagridPersistenceModule } from "@vmw/ngx-utils/clr-datagrid-persistence";
import { VmwTextClipperDirectiveModule } from "@vmw/ngx-utils/text-clipper";
import { VmwSafeHtmlPipeModule } from "@vmw/ngx-utils";
import { VIPModule } from "@vmw/ngx-vip";
import { VmwDateRangePickerModule as VmwDateRangePickerComponentModule } from "@vmw/ngx-components/date-range-picker";
import { CspFeatureFlagClientModule } from "@vmw/csp-ngx-ff-sdk";

import {
    APP_ENVIRONMENT,
    EARLY_PRELOAD_PROVIDER,
    CONTEXTUAL_HELP_PRELOAD_PROVIDER,
    VIP_PRELOAD_PROVIDER,
    CcePageTitleService,
    CceNotificationService,
    UmAPIInterceptor,
} from "@cce/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { oauth2ConfigFactory } from "./oauth.config";

import { UsageManagementOverviewComponent } from "./usage-management-overview/usage-management-overview.component";
import { MetricDetailComponent } from "./usage-management-overview/metric-detail/metric-detail.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CdsModule } from "@cds/angular";
import { InsightsService } from "./usage-management-insights/insights.service";
import { UsageService } from "./usage-management-overview/usage.service";
import { UsageManagementBudgetsService } from "./usage-management-budgets/usage-management-budgets.service";
import { DateRangePickerComponent } from "./usage-management-overview/metric-detail/date-range-picker/date-range-picker.component";
import { CceUsageTooltipComponent } from "./usage-management-overview/usage-tooltip/usage-tooltip.component";
import { CceDownloadCsvButtonComponent } from "./usage-management-overview/metric-detail/download-csv-button/download-csv-button.component";
import { CceStatusFilter } from "./usage-management-overview/cce-status-filter/cce-status-filter.component";
import { ServiceFilterComponent } from "./usage-management-overview/service-filter/service-filter.component";

import { RequestRoleErrorComponent } from "./errors/request-role-error/request-role-error.component";
import { PageNotFoundComponent } from "./errors/page-not-found/page-not-found.component";
import { DecimalPipe } from "@angular/common";
import { CceDownloadCurrentUsagesCsvButtonComponent } from "./usage-management-overview/download-current-usages-csv-button/download-current-usages-csv-button.component";
import { CceHorizontalBarChartModule } from "./usage-management-overview/cce-horizontal-bar-chart/cce-horizontal-bar-chart.module";
import { CceConsumptionBarComponent } from "./usage-management-overview/cce-consumption-bar/cce-consumption-bar.component";
import { UsageManagementHomeComponent } from "./usage-management-home/usage-management-home.component";
import { UsageManagementBudgetsComponent } from "./usage-management-budgets/usage-management-budgets.component";
import { UsageManagementBudgetsCreateComponent } from "./usage-management-budgets/usage-management-budgets-create/usage-management-budgets-create.component";
import { UsageManagementBudgetsEditComponent } from "./usage-management-budgets/usage-management-budgets-edit/usage-management-budgets-edit.component";
import { UsageManagementInsightsComponent } from "./usage-management-insights/usage-management-insights.component";
import { CceInsightCardComponent } from "./usage-management-insights/cce-insight-card/cce-insight-card.component";

@NgModule({
    declarations: [
        AppComponent,
        UsageManagementOverviewComponent,
        CceUsageTooltipComponent,
        MetricDetailComponent,
        DateRangePickerComponent,
        RequestRoleErrorComponent,
        PageNotFoundComponent,
        CceDownloadCsvButtonComponent,
        CceDownloadCurrentUsagesCsvButtonComponent,
        CceStatusFilter,
        ServiceFilterComponent,
        CceConsumptionBarComponent,
        UsageManagementHomeComponent,
        UsageManagementBudgetsComponent,
        UsageManagementBudgetsCreateComponent,
        UsageManagementBudgetsEditComponent,
        UsageManagementInsightsComponent,
        CceInsightCardComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ClarityModule,
        VmwComponentsModule.forRoot(),
        VmwThemeToolsModule.forRoot(),
        CspHeaderModule,
        VmwContextualHelpModule.forRoot(),
        VmwTextClipperDirectiveModule,
        CspComponentsModule.forRoot(),
        CspServiceSdkModule.forRoot(),
        HttpClientModule,
        OAuth2Module,
        FormsModule,
        CdsModule,
        ReactiveFormsModule,
        VmwDateRangePickerComponentModule,
        VmwSafeHtmlPipeModule,
        VmwBarChartModule,
        VmwClrDatagridPersistenceModule,
        VIPModule.forRoot(),
        CspFeatureFlagClientModule.forRoot(),
        CspRecommendationsModuleLib,
        CspCrossOrgSwitcherModule,
        VmwLegendModule,
        CceHorizontalBarChartModule,
    ],
    providers: [
        EARLY_PRELOAD_PROVIDER,
        VIP_PRELOAD_PROVIDER,
        CONTEXTUAL_HELP_PRELOAD_PROVIDER,
        {
            provide: OAuth2ModuleConfiguration,
            useFactory: oauth2ConfigFactory,
            deps: [APP_ENVIRONMENT],
        },
        UsageService,
        UsageManagementBudgetsService,
        InsightsService,
        CcePageTitleService,
        DecimalPipe,
        CceNotificationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UmAPIInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
