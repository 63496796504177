import { CceUsageMetricMetadataDto } from "../usage-management-overview/usage-management.model";

export interface CceInsight {
    displayName: string;
    serviceId: string;
    serviceName: string;
    usage: number;
    commitment: number;
    unit: string;
    type: CceInsightType;
    metricId: string;
    sellerId: string;
}

export interface CceMetricsTotal {
    totalUsages: number;
    usagesOver: number;
    usagesBelow: number;
    usagesAt: number;
    usagesDemand: number;
}

export enum CceInsightType {
    OVER_COMMITMENT = "OVER_COMMITMENT",
    BELOW_COMMITMENT = "BELOW_COMMITMENT",
}

export interface CceInsightDto {
    content: [
        {
            metadata: CceUsageMetricMetadataDto;
            insight_type: CceInsightType;
            start_date: string;
            end_date: string;
            seller_id: string;
            commit_quantity: number;
            usage_quantity: number;
        }
    ];
    total_usage_insights: number;
}
