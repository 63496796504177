import { Component } from '@angular/core';

import { CspRobot } from '@vmw/csp-ngx-components';
import { Mixin } from '@vmw/ngx-utils';
import { L10nService, LocalizedComponent } from '@vmw/ngx-vip';

import { ENGLISH } from './request-role-error.l10n';

@Component({
    templateUrl: 'request-role-error.component.html'
})
@Mixin([LocalizedComponent], {
    L10nKey: 'request-role-error',
    L10nSourceMap: ENGLISH,
})
export class RequestRoleErrorComponent implements LocalizedComponent {
    translate: (key: keyof typeof ENGLISH, ...args: any[]) => string;
    
    readonly robot: CspRobot = CspRobot.Forbidden;
    
    constructor(public l10nService: L10nService) {}

}
