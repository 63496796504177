import { Component } from "@angular/core";

import { Subject } from "rxjs";

import { Mixin } from "@vmw/ngx-utils";
import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";

import { L10nKey, TranslateFunction } from "@cce/core";

import { CceUsageMetricUsageRecordViewModel, CceUsageMetricUsageStatus } from "../usage-management.model";

import { ENGLISH } from "./cce-status-filter.component.l10n";

interface CceUsageMetricUsageStatusDisplay {
    status: CceUsageMetricUsageStatus;
    l10nKey: L10nKey<typeof ENGLISH>;
}

/**
 *
 * Note:
 *
 * The paramaters; changes, isActive() and accepts() are all required below
 * to allow this filter be used as part of <clr-dg-filter></clr-dg-filter>
 * (To utilize Claritys datagrid built in filtering capabilities)
 *
 * Example implementation:
 *  <clr-dg-filter [clrDgFilter]="cceStatusFilter">
 *      <cce-status-filter #cceStatusFilter></cce-status-filter>
 *  </clr-dg-filter>
 *
 */

@Component({
    selector: "cce-status-filter",
    templateUrl: "cce-status-filter.component.html",
})
@Mixin([LocalizedComponent], {
    L10nKey: "status-filter",
    L10nSourceMap: ENGLISH,
})
export class CceStatusFilter {
    public selectedStatuses: string[] = [];
    public translate: TranslateFunction<typeof ENGLISH>;
    public changes = new Subject<string[]>();

    public displayStatuses: CceUsageMetricUsageStatusDisplay[] = [
        { status: CceUsageMetricUsageStatus.ON_DEMAND, l10nKey: "on-demand" },
        { status: CceUsageMetricUsageStatus.AT_COMMITMENT, l10nKey: "at-commitment" },
        { status: CceUsageMetricUsageStatus.BELOW_COMMITMENT, l10nKey: "below-commitment" },
        { status: CceUsageMetricUsageStatus.OVER_COMMITMENT, l10nKey: "over-commitment" },
    ];

    constructor(public l10nService: L10nService) {}

    isActive(): boolean {
        return this.selectedStatuses.length > 0;
    }

    accepts(metric: CceUsageMetricUsageRecordViewModel) {
        return true;
    }

    toggleStatusSelection(status: string) {
        const index = this.selectedStatuses.indexOf(status);
        if (index > -1) {
            this.selectedStatuses.splice(index, 1);
        } else {
            this.selectedStatuses.push(status);
        }
        this.changes.next(this.selectedStatuses);
    }

    onClearSelection() {
        this.selectedStatuses = [];
        this.changes.next(this.selectedStatuses);
    }
}
