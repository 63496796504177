import { Component, Input } from "@angular/core";

import { ClarityIcons, exportIcon } from "@cds/core/icon";

import { Mixin } from "@vmw/ngx-utils";
import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";

import { TranslateFunction } from "@cce/core";

import {
    CceUsageMetricAggregationPeriod,
    CceUsageMetricAggregationType,
    CceUsageMetricMetricUsageHistoryDto,
} from "../../usage-management.model";

import { UsageService } from "../../usage.service";
import { ENGLISH } from "./download-csv-button.component.l10n";

import { saveAs } from "file-saver";

@Component({
    selector: "cce-download-csv-button",
    templateUrl: "./download-csv-button.component.html",
})
@Mixin([LocalizedComponent], {
    L10nKey: "download-csv-button",
    L10nSourceMap: ENGLISH,
})
export class CceDownloadCsvButtonComponent {
    @Input() orgId: string;
    @Input() metricId: string;
    @Input() serviceId: string;
    @Input() currentSeller: string;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() aggregationPeriod: CceUsageMetricAggregationPeriod;
    @Input() totalItems: number;

    public downloading: boolean = false;

    public translate: TranslateFunction<typeof ENGLISH>;

    constructor(private usageService: UsageService, public l10nService: L10nService) {
        ClarityIcons.addIcons(exportIcon);
    }

    public download() {
        this.downloading = true;
        this.usageService
            .getHistoricalUsage(
                this.orgId,
                this.metricId,
                this.serviceId,
                this.currentSeller,
                0,
                this.totalItems,
                this.startDate,
                this.endDate,
                this.aggregationPeriod,
                CceUsageMetricAggregationType.MAX
            )
            .subscribe({
                next: (dailyDto: CceUsageMetricMetricUsageHistoryDto) => {
                    const usages = this.usageService.transformMetricHistoryUsages(dailyDto);
                    const formatCSVUsages = this.formatCSVDailyUsages(usages);
                    const blob = new Blob([formatCSVUsages], { type: "text/csv;charset=utf-8" });
                    saveAs(blob, this.translate("usage") + ".csv");
                },
                error: (err) => {
                    this.downloading = false;
                    console.error(err);
                },
                complete: () => {
                    this.downloading = false;
                },
            });
    }

    public formatCSVDailyUsages(usages) {
        // map csv column to object key returned from backend
        const headerToKeyMap = {
            [this.translate("date")]: "time",
            [this.translate("commitment")]: "commitment",
            [this.translate("usage-within-commitment")]: "usageWithinCommitment",
            [this.translate("overage")]: "overage",
            [this.translate("usage")]: "usage",
            [this.translate("unit")]: "unit",
            [this.translate("metric-id")]: "metricId",
            [this.translate("service-id")]: "serviceId",
        };
        const headers = Object.keys(headerToKeyMap);
        // separate columns by a comma and rows by a line break
        return `${headers.join(",")}\n${usages
            .map((rowData) => headers.map((headKey) => rowData[headerToKeyMap[headKey]]).join(","))
            .join("\n")}`;
    }
}
