<div class="cce-horizontal-bar-chart-bar-wrapper">
  <div class="label-section">
    <ng-content select="cce-horizontal-bar-chart-bar-label"></ng-content>
  </div>

  <div class="bar-section">
    <div class="bar-container">

      <div class="bar-at-or-below-container" #thresholdContainer [style.width.%]="atOrBelowPct" [style.border-right]="atOrBelowContainerBorder">

        <div class="bar-at-or-below-segments" [style.width.%]="100">

          <div class="segment-below" [style.width.%]="valuePct">
            <div class="bar-bg" [style.backgroundColor]="getBelowThresholdColor">&nbsp;</div>
          </div>

          <div class="segment-between" [style.width.%]="betweenPct">
            <div class="bar-bg" [style.backgroundColor]="getBetweenThresholdColor">&nbsp;</div>
          </div>

        </div>

        <span class="bar-label" *ngIf="label && isThresholdEnabled && barLabelonLeft">{{label}}</span>
      </div>

      <div class="bar-above-container" [style.width.%]="100 - atOrBelowPct">

        <div class="segment-above" [style.width.%]="abovePct">
          <div class="bar-bg" [style.backgroundColor]="getAboveThresholdColor"> &nbsp;</div>
        </div>

        <span class="bar-label" id="above-label" *ngIf="label && isThresholdEnabled && !barLabelonLeft">{{label}}</span>
      </div>

    </div>

    <span class="bar-label" *ngIf="label && !isThresholdEnabled">{{label}}</span>
  </div>

</div>

<div #tooltipContainer
    style="position: fixed;" 
    [style.left.px]="tooltipX"
    [style.top.px]="tooltipY">
<vmw-tooltip [tooltipWidth]="400">
  <vmw-tooltip-content *vmwIfTooltipOpen>
    <ng-content select="cce-horizontal-bar-chart-bar-tooltip"></ng-content>
  </vmw-tooltip-content>
</vmw-tooltip>
</div>