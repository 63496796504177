import { Injectable } from "@angular/core";
import { CspServiceSdkToolkit } from "@vmw/csp-service-sdk";
import { VmwToastType } from "@vmw/ngx-components";

export interface CceToast {
    title: string;
    description: string;
    type: VmwToastType;
}

export interface CceSnack {
    title: string;
}

@Injectable({
    providedIn: "root",
})
export class CceNotificationService {
    constructor(private cspServiceSdk: CspServiceSdkToolkit) {}

    public toasts: CceToast[] = [];
    public snacks: CceSnack[] = [];

    public showSuccessMessage(title: string): void {
        if (this.cspServiceSdk.isEmbedded) {
            // description is required below but not accessed
            this.cspServiceSdk.sendSuccessNotification(title, "");
        } else {
            this.snacks.push({ title: title });
        }
    }

    public showFailureMessage(title: string, description: string) {
        if (this.cspServiceSdk.isEmbedded) {
            this.cspServiceSdk.sendFailureNotification(title, description);
        } else {
            this.toasts.push({
                title: title,
                description: description,
                type: VmwToastType.FAILURE,
            });
        }
    }
}
