import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Mixin, LocalizedComponent, L10nService } from "@vmw/ngx-vip";
import { ENGLISH } from "./usage-management-budgets.component.l10n";
import { CceNotificationService, TranslateFunction } from "@cce/core";
import { CceBudgetSourcePage, CceUsageManagementBudgetView, CceUsageManagementBudgetsView } from "./usage-management-budgets.model";
import { ClrDatagridStateInterface } from "@clr/angular";
import { UsageManagementBudgetsService } from "./usage-management-budgets.service";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { filter } from "rxjs";
import { Router } from "@angular/router";
import { VmwNgxButtonType, VmwNgxModalService } from "@vmw/ngx-modal-service";
import { CspServiceSdkToolkit } from "@vmw/csp-ngx-service-sdk";
import { VmwToastType } from "@vmw/ngx-components";

@Mixin([LocalizedComponent], {
    L10nKey: "usage-management-budgets",
    L10nSourceMap: ENGLISH,
})
@Component({
    selector: "cce-usage-management-budgets",
    templateUrl: "./usage-management-budgets.component.html",
    styleUrls: ["./usage-management-budgets.component.scss"],
})
export class UsageManagementBudgetsComponent implements OnInit {
    public translate: TranslateFunction<typeof ENGLISH>;

    public budgets: CceUsageManagementBudgetView[] = [];
    public selectedBudgets: CceUsageManagementBudgetView[] = [];
    public budgetsToDelete: CceUsageManagementBudgetView[] = [];

    public gridLoading: boolean = true;
    public orgId: string = "";
    public totalBudgets: number = 0;

    get editDisabled(): boolean {
        return !(this.selectedBudgets.length === 1);
    }
    get deleteDisabled(): boolean {
        return !this.selectedBudgets.length;
    }
    get isMultiRowSelectionOn(): boolean {
        return !!this.selectedBudgets.length;
    }

    @ViewChild("deleteBudgetsModalContent", { read: TemplateRef }) deleteBudgetsModalContent: TemplateRef<any>;

    constructor(
        public l10nService: L10nService,
        private budgetsService: UsageManagementBudgetsService,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        private router: Router,
        private modalService: VmwNgxModalService,
        private notificationService: CceNotificationService
    ) {}

    ngOnInit(): void {
        this.crossOrgSwitcherService.orgDetails$.pipe(filter(Boolean)).subscribe({
            next: (org) => {
                this.orgId = org.id;
                if (this.router.lastSuccessfulNavigation?.extras?.state?.sourcePage === CceBudgetSourcePage.CREATE) {
                    this.notificationService.showSuccessMessage(this.translate("successfully-created-budget"));
                } else if (this.router.lastSuccessfulNavigation?.extras?.state?.sourcePage === CceBudgetSourcePage.EDIT) {
                    this.notificationService.showSuccessMessage(this.translate("successfully-edited-budget"));
                }
                this.loadBudgets();
            },
        });
    }

    public loadBudgets(state?: ClrDatagridStateInterface) {
        this.gridLoading = true;
        this.selectedBudgets = [];
        this.budgetsService.getBudgets(this.orgId).subscribe({
            next: (budgetsView: CceUsageManagementBudgetsView) => {
                this.budgets = budgetsView.budgets;
                this.totalBudgets = budgetsView.totalItems;
                this.gridLoading = false;
            },
            error: (err) => {
                console.error(err);
                this.budgets = [];
                this.totalBudgets = 0;
                this.gridLoading = false;
            },
        });
    }

    public onEdit(budget: CceUsageManagementBudgetView) {
        this.router.navigate(["usage-management", "budgets", "edit", budget.id]);
    }

    public onDelete(budgets: CceUsageManagementBudgetView[]) {
        this.budgetsToDelete = budgets;

        this.modalService.openOkCancel(this.deleteBudgetsModalContent, {
            title: budgets.length > 1 ? this.translate("delete-budgets") : this.translate("delete-budget"),
            buttons: {
                submitButton: {
                    label: this.translate("delete"),
                    type: VmwNgxButtonType.Danger,
                    handler: (ref) => {
                        if (budgets.length) {
                            this.budgetsService.deleteBudgets(this.orgId, budgets).subscribe({
                                next: (b: any) => {
                                    this.budgetsToDelete = [];
                                    const snackTitle =
                                        budgets.length > 1
                                            ? this.translate("successfully-deleted-budgets")
                                            : this.translate("successfully-deleted-budget");
                                    this.notificationService.showSuccessMessage(snackTitle);
                                    this.modalService.closeAll();
                                    this.loadBudgets();
                                },
                                error: (err) => {
                                    this.budgetsToDelete = [];
                                    this.selectedBudgets = [];
                                    this.notificationService.showFailureMessage(
                                        this.translate("error-deleting-budgets-title"),
                                        this.translate("error-deleting-budgets-message")
                                    );
                                    this.modalService.closeAll();
                                    console.error(err);
                                },
                            });
                        }
                    },
                },
                cancelButton: {
                    label: this.translate("cancel"),
                },
            },
        });
    }
}
