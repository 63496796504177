import { HostBinding, Component, OnInit, ViewChildren, ViewChild, QueryList } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Subscription, Subject, Observable, forkJoin, of } from "rxjs";
import { take, skip, filter, map } from "rxjs/operators";
import { storeIcon, banIcon, angleIcon, ellipsisHorizontalIcon, ClarityIcons, infoStandardIcon } from "@cds/core/icon";
import { pdfFileIcon } from "@cds/core/icon/shapes/pdf-file";

import { Mixin, VmwNgxMatchMediaService } from "@vmw/ngx-utils";
import { I18nService, L10nService, LocalizedComponent } from "@vmw/ngx-vip";
import { CspApiService, CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { CspServiceSdkToolkit } from "@vmw/csp-service-sdk";
import { Service, CspSeller } from "@vmw/csp-common";
import { VmwSvgIconColorFormat } from "@vmw/ngx-components";
import { VmwContextualHelpService } from "@vmw/ngx-contextual-help";
import { CspFeatureFlagClient } from "@vmw/csp-ngx-ff-sdk";
import { VmwDateRangePickerDateRange } from "@vmw/ngx-components/date-range-picker";

import { CcePageTitleService, TranslateFunction } from "@cce/core";
import { ClrDatagridPagination, ClrDatagridStateInterface, ClrDatagridSortOrder } from "@clr/angular";

import {
    CceUsageMetricMetricUsageHistoryRecordViewModel,
    CceUsageMetricMetricUsageOverviewViewModel,
    CceUsageMetricMetricUsageInfoViewModel,
    CceUsageMetricChartDataViewModel,
    CceUsageMetricMetricUsageHistoryDto,
    CceUsageMetricAggregationPeriod,
    CceUsageMetricAggregationType,
    CceUsageMetricSort,
    CceUsageSortDirection,
    CceInvoiceStatementReduced,
} from "../usage-management.model";
import { DateRangePickerComponent } from "./date-range-picker/date-range-picker.component";
import { UsageService } from "../usage.service";
import { ENGLISH } from "./metric-detail.component.l10n";
import { CostAndUsageTrackMetrics } from "../../track-metrics.enum";
import { CostAndUsageFeatureFlags } from "../../feature-flags.enum";
import {
    MAX_BAR_THICKNESS,
    MONTHLY_SERVICE_IDS,
    SELLER_AWS,
    SELLER_MULTIPLE,
    SELLER_PARAM_KEY,
    SELLER_VMWARE,
} from "../usage-management.const";
import {
    VmwBarChartComponent,
    VmwBarChartZoomEvent,
    VmwBarChartBarClickEvent,
    VmwBarChartData,
    VmwBarChartOptions,
    getFirstDatasetWithVisibleData,
    VmwBarChartBarHoverEvent,
} from "@vmw/ngx-charts/bar-chart";
import { VmwNgxModalService } from "@vmw/ngx-modal-service";

import { saveAs } from "file-saver";
import { CceUsageChartDataType } from "../usage-chart.transformer";

const MAX_CHART_ITEMS = 365;
const DEFAULT_GRID_PAGE_SIZE = 10;
const MAX_DAYS_HOURLY_DATA = 30;
const MAX_DAYS_DAILY_DATA = 730;
const MAX_DAYS_MONTHY_DATA = 730;
const DEFAULT_ZOOM_LEVEL = 1.0;
const VSPHERE_PROD_SERVICE_ID = "ee1b1892-b97c-4268-a610-b05cde019213";
const VSPHERE_STG_SERVICE_ID = "da17a299-1c87-47dd-8a89-de9af20a89a4";

const DATE_FORMAT_ACTIVITY_STATEMENT = "mediumDate";

@Component({
    selector: "usage-detail",
    templateUrl: "./metric-detail.component.html",
    styleUrls: ["./metric-detail.component.scss"],
})
@Mixin([LocalizedComponent], {
    L10nKey: "metric-detail",
    L10nSourceMap: ENGLISH,
})
export class MetricDetailComponent implements OnInit {
    @ViewChildren(DateRangePickerComponent) private dateRangePickerQuery: QueryList<DateRangePickerComponent>;
    @ViewChildren(ClrDatagridPagination) private paginationQuery: QueryList<ClrDatagridPagination>;
    @ViewChild("topChart") public topChart: VmwBarChartComponent<CceUsageMetricChartDataViewModel>;
    @ViewChild("bottomChart") public bottomChart: VmwBarChartComponent<CceUsageMetricChartDataViewModel>;

    public translate: TranslateFunction<typeof ENGLISH>;

    public readonly CceUsageMetricAggregationPeriod = CceUsageMetricAggregationPeriod;
    public readonly VmwSvgIconColorFormat = VmwSvgIconColorFormat;
    public readonly ClrDatagridSortOrder = ClrDatagridSortOrder;
    public readonly DATE_FORMAT_ACTIVITY_STATEMENT = DATE_FORMAT_ACTIVITY_STATEMENT;
    public readonly SELLER_MULTIPLE = SELLER_MULTIPLE;

    public aggregationPeriod: CceUsageMetricAggregationPeriod = CceUsageMetricAggregationPeriod.DAILY;
    public metric: CceUsageMetricMetricUsageOverviewViewModel;
    public currentMetricUsage: CceUsageMetricMetricUsageInfoViewModel;
    public usagesCurrentPage: CceUsageMetricMetricUsageHistoryRecordViewModel[] = [];
    public hourlyUsages: CceUsageMetricMetricUsageHistoryRecordViewModel[] = [];
    public loadingError: boolean;
    public loadingGrid: boolean;
    public loadingHourly: boolean;
    public loadingTopChart: boolean;
    public loadingBottomChart: boolean;
    public loadingOverview: boolean = true;
    public selectedUsage: CceUsageMetricMetricUsageHistoryRecordViewModel = null;
    public service: Service;
    public hoveredItem: { usage: number; commitment: number; unit: string; projection?: boolean };
    public hoveredItemTime: string;
    public cspSeller: CspSeller;

    public topChartOptionsDaily: VmwBarChartOptions<CceUsageMetricChartDataViewModel> = {
        xAxisKey: "time",
        yAxisKey: "usage",
        xAxisType: "time",
        xAxisStepSize: 1,
        xAxisStepUnit: "day",
        xLabelCallback: (value: any, index: number, ticks: any[]) => {
            return this.i18n.formatDate(new Date(value), this.translate("full-date-abbreviated-pattern"));
        },
        maxBarThickness: MAX_BAR_THICKNESS,
        enableDragSelection: true,
    };
    public topChartOptionsMonthly: VmwBarChartOptions<CceUsageMetricChartDataViewModel> = {
        xAxisKey: "time",
        yAxisKey: "usage",
        xAxisType: "time",
        xAxisStepSize: 1,
        xAxisStepUnit: "month",
        xLabelCallback: (value: any, index: number, ticks: any[]) => {
            return this.i18n.formatDate(new Date(value), this.translate("full-month-year-pattern"));
        },
        maxBarThickness: MAX_BAR_THICKNESS,
        enableDragSelection: true,
    };

    public topChartData: VmwBarChartData<CceUsageMetricChartDataViewModel>;

    public bottomChartOptionsHourly: VmwBarChartOptions<CceUsageMetricChartDataViewModel> = {
        xAxisKey: "time",
        xAxisType: "time",
        yAxisKey: "usage",
        xAxisStepSize: 1,
        xAxisStepUnit: "hour",
        xLabelCallback: (value: any, index: number, ticks: any[]) => {
            const hours = getFirstDatasetWithVisibleData(this.bottomChartData.datasets).data.length;
            if (hours > 24) {
                return this.i18n.formatDate(new Date(value), this.translate("medium-datetime-minus-seconds-pattern"));
            } else {
                return this.i18n.formatDate(new Date(value), "shortTime");
            }
        },
        maxBarThickness: MAX_BAR_THICKNESS,
        enableDragSelection: true,
    };

    public bottomChartOptionsDaily: VmwBarChartOptions<CceUsageMetricChartDataViewModel> = {
        xAxisKey: "time",
        xAxisType: "time",
        yAxisKey: "usage",
        xAxisStepSize: 1,
        xAxisStepUnit: "day",
        xLabelCallback: (value: any, index: number, ticks: any[]) => {
            return this.i18n.formatDate(new Date(value), this.translate("day-month-short-pattern"));
        },
        maxBarThickness: MAX_BAR_THICKNESS,
        enableDragSelection: true,
    };

    public bottomChartData: VmwBarChartData<CceUsageMetricChartDataViewModel>;
    public currentDate: Date;
    public sellers: string[];
    public sellerSelectionForm: FormGroup;
    public sellerParam: string;

    public projectionForm = new FormGroup({
        projection: new FormControl(0),
    });

    public trendForm = new FormGroup({
        showTrend: new FormControl(true),
    });

    @HostBinding("attr.data-metric-id")
    public metricId: string;

    public get showDelayedDataAlert(): boolean {
        return MONTHLY_SERVICE_IDS.includes(this.serviceId);
    }

    public serviceId: string;
    public orgId: string;
    public minDate: Date;
    public maxDate: Date;
    public zoomDetails: VmwBarChartZoomEvent;

    private sellerMap: { [key: string]: Observable<string> } = {};
    private subs = new Subscription();
    private dateRangePicker: DateRangePickerComponent;

    private billingAccountId: string;
    private billingAccountStatementsReduced: CceInvoiceStatementReduced[] = [];
    public trendlineEnabled: boolean = false;
    public showTrendToggle: boolean = false;
    private weightedTrendlineEnabled: boolean = false;
    private projectionEnabled: boolean = false;
    private skipNextProjectionChange: boolean = false;

    // Index of the datagrid row for which activity statement request is pending (to show spinner)
    public activityStatementDownloadingIndex: number;

    public enableConsumptionBar: boolean = false;
    public enableCurrentConsumptionBarFeatureFlag$ = this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableCurrentConsumptionBar);
    public wantToCheckScreenSize: boolean = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private usageService: UsageService,
        private cspApiService: CspApiService,
        private cspServiceSdk: CspServiceSdkToolkit,
        private helpService: VmwContextualHelpService,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        public l10nService: L10nService,
        private fb: FormBuilder,
        private ffClient: CspFeatureFlagClient,
        private cceTitleService: CcePageTitleService,
        private router: Router,
        private modalService: VmwNgxModalService,
        private i18n: I18nService,
        private matchMediaService: VmwNgxMatchMediaService
    ) {
        ClarityIcons.addIcons(banIcon, ellipsisHorizontalIcon, storeIcon, pdfFileIcon);

        this.matchMediaService.match("(min-width: 1800px)").subscribe((queryResult) => {
            if (this.wantToCheckScreenSize) {
                if (queryResult.matches) {
                    this.enableConsumptionBar = true;
                } else {
                    this.enableConsumptionBar = false;
                }
            }
        });
    }

    ngOnInit(): void {
        this.metricId = this.activatedRoute.snapshot.params["metricId"];
        this.serviceId = this.activatedRoute.snapshot.params["serviceId"];
        this.sellerParam = this.activatedRoute.snapshot.queryParams["seller"];

        this.helpService.setContext("usage", "usage management");

        this.subs.add(
            this.crossOrgSwitcherService.orgDetails$.pipe(filter(Boolean)).subscribe({
                next: (org) => {
                    this.orgId = org.id;
                    this.initWithOrg();
                },
            })
        );

        /**
         * In a scenario where the CCE UI is embedded and an organisation
         * switch occurs, navigate back to Usage Management.
         * We skip the first emmission as this is the orgId of the current org
         * needed to initalise Metric Detail.
         */
        this.subs.add(
            this.crossOrgSwitcherService.orgDetails$.pipe(filter(Boolean), skip(1)).subscribe(() => {
                this.router.navigate(["/"]);
            })
        );

        this.crossOrgSwitcherService.disableCrossOrg();
    }

    private initWithOrg() {
        this.loadingError = false;
        this.loadingOverview = true;

        // Initial requests metric overview and org services.
        // Once we have the metric overview, we have the list of sellers
        // Historical data calls wait for sellers and date range picker ...
        forkJoin({
            overview: this.usageService.getMetricUsageOverview(this.orgId, this.serviceId, this.metricId),
            billingAccounts: this.cspApiService.getOrganizationBillingAccounts(this.orgId),
            services: this.cspApiService.getOrgServices(this.orgId),
            trendEnabled: this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableTrendLine).pipe(take(1)),
            weightedTrendEnabled: this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableWeightedTrendLine).pipe(take(1)),
            projectionEnabled: this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableProjection).pipe(take(1)),
        }).subscribe({
            next: ({ overview, billingAccounts, services, trendEnabled, weightedTrendEnabled, projectionEnabled }) => {
                this.trendlineEnabled = trendEnabled;
                this.weightedTrendlineEnabled = weightedTrendEnabled;
                this.projectionEnabled = projectionEnabled;

                this.metric = overview;

                this.service = services.find((s: Service) => s.id === this.serviceId);

                this.sellers = this.metric.sellers;

                if (this.sellerParam) {
                    this.sellerSelectionForm = this.fb.group({
                        seller: [this.sellerParam, Validators.required],
                    });
                } else {
                    this.sellerSelectionForm = this.fb.group({
                        seller: [this.sellers[0], Validators.required],
                    });
                }

                // Update the current metric usage based on the seller
                this.updateCurrentMetricUsage();

                // sets min and max date for date range picker
                this.setDateRangePickerExtremes(this.aggregationPeriod);

                // Now we wait for the date range picker to appear so we know the dates to
                // request historical data for
                this.loadingOverview = false;

                this.cceTitleService.setPageTitle(this.metric.usageType + ` | ` + this.service?.displayName);

                this.cspServiceSdk.trackEvent({
                    title: CostAndUsageTrackMetrics.MetricDetailLoaded,
                    properties: {
                        serviceId: this.serviceId,
                        metricId: this.metricId,
                        seller: this.currentSeller,
                    },
                });
                this.billingAccountId = billingAccounts.find((ba) => ba.seller == this.currentSeller)?.billingAccountId;
                this.getBillingAccountStatements();
            },
            error: (e) => {
                console.error(e);
                this.loadingError = true;
                this.loadingOverview = false;
            },
        });

        ClarityIcons.addIcons(infoStandardIcon);
        ClarityIcons.addIcons(angleIcon);
    }

    public ngAfterViewInit() {
        // This subscription waits for the date range picker to appear on the view, so we can
        // read the initial daterange and future daterange changes.
        this.subs.add(
            this.dateRangePickerQuery.changes.subscribe((changes) => {
                if (changes.length) {
                    this.dateRangePicker = changes.first;
                    this.loadWithUIValues();
                }
            })
        );

        this.projectionForm.get("projection").valueChanges.subscribe(() => {
            if (this.skipNextProjectionChange) {
                this.skipNextProjectionChange = false;
                return;
            }

            this.skipNextProjectionChange = false;

            this.loadHistoricalData();
        });

        this.trendForm.get("showTrend").valueChanges.subscribe(() => {
            this.loadHistoricalData();
        });
    }

    public get vSphere(): boolean {
        return [VSPHERE_PROD_SERVICE_ID, VSPHERE_STG_SERVICE_ID].includes(this.serviceId);
    }

    public get showProjection(): boolean {
        return (
            this.projectionEnabled &&
            (this.aggregationPeriod === CceUsageMetricAggregationPeriod.DAILY ||
                this.aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY)
        );
    }

    public get availableProjections(): Array<number> {
        if (this.aggregationPeriod === CceUsageMetricAggregationPeriod.DAILY) {
            return [7, 15, 30, 60];
        } else if (this.aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY) {
            return [1, 2, 3, 6];
        }
    }

    public get pagination() {
        return this.paginationQuery.get(0);
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private updateCurrentMetricUsage() {
        this.currentMetricUsage = this.metric.usages.find((u) => u.seller === this.currentSeller);
        this.maxDate = new Date();
        this.loadSellerDefinition();
    }

    private loadWithUIValues() {
        // Listen to the date rangepicker for date range changes.
        this.subs.add(
            this.dateRangePicker.form.get("dateRange").valueChanges.subscribe((dateRange: VmwDateRangePickerDateRange) => {
                const newAggregationPeriod = this.dateRangePicker.form.get("aggregationPeriod").value;

                if (this.aggregationPeriod !== newAggregationPeriod) {
                    this.skipNextProjectionChange = true;
                    this.projectionForm.get("projection").setValue(0);
                }

                this.aggregationPeriod = newAggregationPeriod;

                this.setCurrentDate();

                this.loadHistoricalData();

                this.topChart?.resetZoom();
                this.bottomChart?.resetZoom();
            })
        );

        this.subs.add(
            this.dateRangePicker.form.get("aggregationPeriod").valueChanges.subscribe({
                next: (aggregationPeriod) => {
                    // N.B. we specifically do not update this.aggregationPeriod here because it
                    // would cause the template to reevaluate and re-render.
                    this.setDateRangePickerExtremes(aggregationPeriod);
                },
            })
        );

        // Listen for seller selection changes
        this.subs.add(
            this.sellerSelectionForm.get("seller").valueChanges.subscribe(() => {
                this.setCurrentDate();
                this.updateCurrentMetricUsage();
                this.setDateRangePickerExtremes(this.aggregationPeriod);
                this.loadHistoricalData();
                this.updateURLSellerParam();
            })
        );
    }

    private updateURLSellerParam() {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { [SELLER_PARAM_KEY]: this.currentSeller },
            queryParamsHandling: "merge",
        });
    }

    private setCurrentDate() {
        // The first time the page loads, the current date should be the most recently reported usage date.
        // We do not want the current date to be greater than the most recently reported date,
        // except for when a range with no data is selcted (for display purposes.)
        if (this.currentDate && (this.endDate <= this.lastUpdated || this.startDate >= this.lastUpdated)) {
            this.currentDate = this.endDate;
        } else {
            this.currentDate = new Date(this.currentMetricUsage.last_updated);
        }
    }

    /**
     * Load the first set of historical data
     */
    private loadHistoricalData() {
        if (this.showTopChart && !this.tooMuchData) {
            this.loadUsagesForTopChart();
        }

        this.loadUsagesForBottomChart();
        this.loadUsagesForDatagrid();
    }

    private get dateRange() {
        return this.dateRangePicker?.form?.get("dateRange").value;
    }

    public get endDate() {
        if (!this.dateRange) {
            return;
        }
        return this.dateRange.end;
    }

    public get startDate() {
        if (!this.dateRange) {
            return;
        }
        return this.dateRange.start;
    }

    private get lastUpdated() {
        if (!this.currentMetricUsage) {
            return;
        }
        return new Date(this.currentMetricUsage.last_updated);
    }

    private setDateRangePickerExtremes(aggregationPeriod: CceUsageMetricAggregationPeriod) {
        if (!this.maxDate) {
            console.error("No max date!");
            return;
        }

        const d = new Date(this.maxDate);

        switch (aggregationPeriod) {
            case CceUsageMetricAggregationPeriod.DAILY:
                d.setDate(this.maxDate.getDate() - MAX_DAYS_DAILY_DATA);
                break;

            case CceUsageMetricAggregationPeriod.HOURLY:
                d.setDate(this.maxDate.getDate() - MAX_DAYS_HOURLY_DATA);
                break;

            case CceUsageMetricAggregationPeriod.MONTHLY:
                d.setDate(this.maxDate.getDate() - MAX_DAYS_MONTHY_DATA);
                break;
        }

        this.minDate = d;
    }

    public get tooMuchData(): boolean {
        if (!this.startDate || !this.endDate) {
            return false;
        }

        let items: number;

        switch (this.aggregationPeriod) {
            case CceUsageMetricAggregationPeriod.DAILY:
                items = this.daysBetween(this.startDate, this.endDate);
                break;
            case CceUsageMetricAggregationPeriod.MONTHLY:
                items = this.monthsBetween(this.startDate, this.endDate);
                break;
            case CceUsageMetricAggregationPeriod.HOURLY:
                const days = this.daysBetween(this.startDate, this.endDate);
                items = days * 24;
                break;
        }

        return items > MAX_CHART_ITEMS;
    }

    public get currentSeller(): string {
        return this.sellerSelectionForm.get("seller").value;
    }

    public get showTopChart(): boolean {
        return (
            this.aggregationPeriod === CceUsageMetricAggregationPeriod.DAILY ||
            this.aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY
        );
    }

    public hideDateRangePicker(): boolean {
        // Note, for some reason when there is no data, the zoom level reported by the chart is > 1.
        // If we have no data, then make sure we show the real date range picker.
        return (
            (!this.noTopData && this.topChart?.zoomLevel > DEFAULT_ZOOM_LEVEL) ||
            (!this.noBottomData && !this.showTopChart && this.bottomChart?.zoomLevel > DEFAULT_ZOOM_LEVEL)
        );
    }

    public showFakeDateRangePicker(): boolean {
        return this.hideDateRangePicker();
    }

    /**
     * Load data for the datagrid.
     *
     * N.B. Clarity calls this immediately when the datagrid is on the DOM, and we don't have
     * a date range at that point, so ignore the request. We will load the first page explicitly.
     */
    public loadUsagesForDatagrid(state?: ClrDatagridStateInterface): Observable<void> {
        const obs = new Subject<void>();

        if (!this.dateRange) {
            return;
        }

        if (!state) {
            state = {
                page: {
                    current: 1,
                    size: this.gridPageSize,
                },
            };
        }

        const page = state.page.current - 1;
        const size = state.page.size;

        this.loadingGrid = true;

        this.subs.add(
            this.usageService
                .getHistoricalUsage(
                    this.orgId,
                    this.metricId,
                    this.serviceId,
                    this.currentSeller,
                    page,
                    size,
                    this.startDate,
                    this.endDate,
                    this.aggregationPeriod,
                    CceUsageMetricAggregationType.MAX,
                    state?.sort?.by as CceUsageMetricSort,
                    state?.sort?.reverse ? CceUsageSortDirection.Ascending : CceUsageSortDirection.Descending
                )
                .subscribe({
                    next: (dto: CceUsageMetricMetricUsageHistoryDto) => {
                        this.usagesCurrentPage = this.usageService.transformMetricHistoryUsages(dto);

                        this.augmentCurrentPageWithStatements();

                        this.loadingGrid = false;

                        this.pagination.totalItems = dto.total_elements;

                        obs.next();
                        obs.complete();
                    },
                    error: (e) => {
                        console.error(e);
                    },
                })
        );

        return obs.asObservable();
    }

    public getNameForSeller(sellerId: string): Observable<string> {
        switch (sellerId) {
            case SELLER_MULTIPLE:
                return of(this.translate("multiple"));
            case SELLER_VMWARE:
                return of(this.translate("vmware"));
            case SELLER_AWS:
                // we should not hit this
                return of(this.translate("aws"));
            default:
                if (this.sellerMap[sellerId]) {
                    return this.sellerMap[sellerId];
                }

                this.sellerMap[sellerId] = this.cspApiService.getSeller(sellerId).pipe(map((cspseller) => cspseller.name));

                return this.sellerMap[sellerId];
        }
    }

    private loadSellerDefinition() {
        this.cspSeller = undefined;

        // the below service throws an error for seller MULTIPLE, so avoid calling it
        if (this.currentSeller === SELLER_MULTIPLE) {
            return;
        }

        this.cspApiService.getSeller(this.currentSeller).subscribe((seller) => {
            this.cspSeller = seller;
        });
    }

    private daysBetween(d1: Date, d2: Date) {
        return Math.round((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
    }

    private monthsBetween(d1: Date, d2: Date): number {
        let months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    private chartHasData(datasets) {
        return datasets.some((ds) => ds.data?.length > 0);
    }

    public get noTopData() {
        return !this.loadingTopChart && this.topChartData?.datasets && !this.chartHasData(this.topChartData?.datasets);
    }

    public get noBottomData() {
        return !this.loadingBottomChart && this.bottomChartData?.datasets && !this.chartHasData(this.bottomChartData?.datasets);
    }

    private loadUsagesForTopChart() {
        this.loadingTopChart = true;

        this.subs.add(
            // For the chart, we will always request a single page with the exact number of
            // items we will show on the chart.
            this.usageService
                .getHistoricalUsage(
                    this.orgId,
                    this.metricId,
                    this.serviceId,
                    this.currentSeller,
                    0, // page
                    MAX_CHART_ITEMS, // maximum number of items
                    this.startDate,
                    this.endDate,
                    this.aggregationPeriod,
                    CceUsageMetricAggregationType.MAX
                )
                .subscribe({
                    next: (data: CceUsageMetricMetricUsageHistoryDto) => {
                        this.topChartData = this.usageService.transformMetricUsageHistoryToChartData(
                            data,
                            this.trendlineEnabled && this.trendForm.get("showTrend").value,
                            this.weightedTrendlineEnabled && this.trendForm.get("showTrend").value,
                            this.projectionEnabled,
                            this.projectionForm.get("projection").value,
                            this.aggregationPeriod
                        );

                        const topChartDataItems = getFirstDatasetWithVisibleData(this.topChartData.datasets).data.length;

                        this.skipNextProjectionChange = true;

                        if (topChartDataItems > 1) {
                            this.projectionForm.enable();
                        } else {
                            this.projectionForm.disable();
                        }

                        this.loadingTopChart = false;
                    },
                    error: (e) => {
                        this.loadingTopChart = false;
                        console.error(e);
                    },
                })
        );
    }

    public get gridPageSize(): number {
        return +this.pagination.pageSize || DEFAULT_GRID_PAGE_SIZE;
    }

    private getFirstDayOfTheMonth(d: Date) {
        return new Date(d.setDate(1));
    }
    private getLastDayOfTheMonth(d: Date) {
        return new Date(d.getFullYear(), d.getMonth() + 1, 0);
    }

    // This function is accessible when we are in a view with aggregationPeriod == DAILY || aggregationPeriod == MONTHLY
    public loadUsagesForDetailedDatagrid(state?: ClrDatagridStateInterface) {
        this.loadingHourly = true;

        const start = new Date(this.selectedUsage.time);
        let end: Date;
        let items: number;

        // If the aggregation period is DAILY, then  the bottom chart will always show a day's worth
        // of data and 24 bars.
        switch (this.aggregationPeriod) {
            case CceUsageMetricAggregationPeriod.DAILY:
                end = start;
                items = 24;
                break;
            case CceUsageMetricAggregationPeriod.MONTHLY:
                end = this.getLastDayOfTheMonth(start);
                items = this.daysBetween(start, end) + 1;
                break;
        }

        // This call will always load 1 day's worth of hourly aggregated data
        this.subs.add(
            this.usageService
                .getHistoricalUsage(
                    this.orgId,
                    this.metricId,
                    this.serviceId,
                    this.currentSeller,
                    0,
                    items,
                    start,
                    end,
                    this.aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY
                        ? CceUsageMetricAggregationPeriod.DAILY
                        : CceUsageMetricAggregationPeriod.HOURLY,
                    CceUsageMetricAggregationType.MAX,
                    state?.sort?.by as CceUsageMetricSort,
                    state?.sort?.reverse ? CceUsageSortDirection.Descending : CceUsageSortDirection.Ascending
                )
                .subscribe({
                    next: (data: CceUsageMetricMetricUsageHistoryDto) => {
                        this.hourlyUsages = this.usageService.transformMetricHistoryUsages(data);
                        this.loadingHourly = false;
                    },
                    error: (e) => {
                        this.loadingHourly = false;
                        console.error(e);
                    },
                })
        );
    }

    private loadUsagesForBottomChart() {
        this.loadingBottomChart = true;

        // N.B. items should never be greater than MAX_CHART_ITEMS
        let items: number;
        let startDate = this.startDate;
        let endDate = this.endDate;
        let days = this.daysBetween(this.startDate, this.currentDate);

        // If the requested end date is beyond the last reported date,
        // then display the data from the last reported date. Only do this when there is data.
        // If the start date is beyond the last reported date, there will be no data.
        if (this.startDate < this.lastUpdated && this.endDate > this.lastUpdated) {
            endDate = this.lastUpdated;
        }

        switch (this.aggregationPeriod) {
            case CceUsageMetricAggregationPeriod.DAILY:
                items = 24;
                // Now that backend is inclusive, when we want one day, the start and end date should
                // be the same.
                startDate = this.currentDate;
                endDate = this.currentDate;
                break;

            case CceUsageMetricAggregationPeriod.HOURLY:
                items = 24 * days;
                break;

            // If aggregation period is set to monthly, the bottom
            // chart will show daily data
            case CceUsageMetricAggregationPeriod.MONTHLY:
                startDate = this.getFirstDayOfTheMonth(this.currentDate);
                endDate = this.getLastDayOfTheMonth(this.currentDate);
                items = this.daysBetween(startDate, endDate) + 1;
                break;
        }
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        let daysFromNow = this.daysBetween(startDate, d);

        if (this.aggregationPeriod === CceUsageMetricAggregationPeriod.DAILY && daysFromNow > MAX_DAYS_HOURLY_DATA) {
            this.bottomChartData = {
                datasets: [
                    {
                        data: [],
                    },
                ],
            };
            this.loadingBottomChart = false;
            return;
        }

        this.subs.add(
            this.usageService
                .getHistoricalUsage(
                    this.orgId,
                    this.metricId,
                    this.serviceId,
                    this.currentSeller,
                    0,
                    items,
                    startDate,
                    endDate,
                    this.aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY
                        ? CceUsageMetricAggregationPeriod.DAILY
                        : CceUsageMetricAggregationPeriod.HOURLY,
                    CceUsageMetricAggregationType.MAX
                )
                .subscribe({
                    next: (data: CceUsageMetricMetricUsageHistoryDto) => {
                        this.bottomChartData = this.usageService.transformMetricUsageHistoryToChartData(data);
                        this.loadingBottomChart = false;
                    },
                    error: (e) => {
                        this.loadingBottomChart = false;
                        console.error(e);
                    },
                })
        );
    }

    /*
     * When user clicks a bar in the top chart, load data into the bottom chart
     */
    public handleTopBarClick(clickedBarItem: VmwBarChartBarClickEvent<CceUsageMetricChartDataViewModel>) {
        const dataItemIndex = clickedBarItem.dataItemIndex;
        const clickedDataItem = clickedBarItem.dataItems[dataItemIndex].dataItem;
        const clickedDataItemTime = clickedDataItem.time;

        // Is the clicked bar in the current page of data shown in the datagrid
        let selectedUsage = this.usagesCurrentPage.find((dataItem) => dataItem.time === clickedDataItemTime);

        // Close the current detail pane in the datagrid to avoid focus dragging the page down
        this.selectedUsage = null;

        // If the selected item is not in the current page, update the datagrid to the page where it
        // should be found - don't open the detail pane yet though to avoid focus/scroll issues.
        if (!selectedUsage) {
            getFirstDatasetWithVisibleData(this.topChartData.datasets).data.find((dataItem: any, itemIndex: number) => {
                if (dataItem.time === clickedDataItemTime) {
                    const page = Math.trunc(itemIndex / this.gridPageSize) + 1;

                    this.loadUsagesForDatagrid({
                        page: {
                            current: page,
                            size: this.gridPageSize,
                        },
                    }).subscribe(() => {
                        // We _should_ have it now ...
                        selectedUsage = this.usagesCurrentPage.find((dataItem) => dataItem.time === clickedDataItemTime);
                        this.currentDate = new Date(selectedUsage.time);
                        // Now that we have the current date, load the data for the chart
                        this.loadUsagesForBottomChart();
                    });
                }
            });
        } else {
            this.currentDate = new Date(selectedUsage.time);
            this.loadUsagesForBottomChart();
        }
    }

    /**
     * When user opens a row from the datagrid
     */
    public onGridUsageSelected(selectedUsage: CceUsageMetricMetricUsageHistoryRecordViewModel) {
        this.selectedUsage = selectedUsage;
        if (selectedUsage) {
            this.wantToCheckScreenSize = false; // if the data grid detail pane is open stop checking screen size
            this.currentDate = new Date(this.selectedUsage.time);
            this.loadUsagesForBottomChart();
            this.loadUsagesForDetailedDatagrid();
        } else {
            this.wantToCheckScreenSize = true;
        }
    }

    public onZoom(e: VmwBarChartZoomEvent) {
        this.zoomDetails = e;
    }

    public setHoveredItem(metric: VmwBarChartBarHoverEvent<CceUsageMetricChartDataViewModel>) {
        const usage = metric.dataItems.reduce<number>((sum, dataItem) => {
            if (
                undefined === dataItem.dataItem?.usage ||
                dataItem.datasetId === CceUsageChartDataType.UsageAvailable ||
                dataItem.datasetId === CceUsageChartDataType.Trend ||
                dataItem.datasetId === CceUsageChartDataType.WeightedTrend ||
                dataItem.datasetId === CceUsageChartDataType.Commitment
            ) {
                return sum;
            }
            return sum + dataItem.dataItem.usage;
        }, 0);

        const commitment = metric.dataItems[metric.dataItemIndex]?.dataItem?.commitment || 0;

        const unit = metric.dataItems[metric.dataItemIndex]?.dataItem?.unit;

        const projection = metric.dataItems.reduce<boolean>((boolean, ds) => {
            if (ds.datasetId === CceUsageChartDataType.Projection) {
                boolean = true;
            }
            return boolean;
        }, false);

        const newMetric = {
            usage: usage,
            commitment: commitment,
            unit: unit,
            projection: projection,
        };

        this.hoveredItem = newMetric;
        this.hoveredItemTime = metric?.dataItems[metric.dataItemIndex]?.dataItem.time || undefined;
    }

    public tooltipTitle(dateFormat: string): string {
        if (this.hoveredItemTime) {
            return this.i18n.formatDate(this.hoveredItemTime, dateFormat);
        } else {
            return this.translate("unknown");
        }
    }

    private getBillingAccountStatements() {
        // will not have a billing account id for seller MULTIPLE, so exit
        if (!this.billingAccountId) {
            return;
        }

        const MAX_STATEMENTS = 15;
        this.cspApiService
            .getOrganizationBillingAccountStatements(this.orgId, this.billingAccountId, MAX_STATEMENTS, this.cspApiService.currentLocale)
            .subscribe((billingAccountStatements) => {
                this.billingAccountStatementsReduced = billingAccountStatements?.statements?.map((statement) => {
                    return {
                        fileToken: statement.fileToken,
                        statementCycle: statement.statementCycle,
                        statementPeriodStartDate: statement.statementPeriodStartDate,
                    };
                });

                this.augmentCurrentPageWithStatements();
            });
    }

    public downloadActivityStatement(statement: CceInvoiceStatementReduced, rowIndex: number) {
        this.activityStatementDownloadingIndex = rowIndex;
        this.cspApiService
            .getBillingAccountInvoiceAsFile(this.orgId, this.billingAccountId, statement.fileToken, this.cspApiService.currentLocale)
            .subscribe({
                next: (blob) => {
                    const filename = this.translate(
                        "activity-statement-file-name",
                        this.i18n.formatDate(statement.statementPeriodStartDate, this.DATE_FORMAT_ACTIVITY_STATEMENT)
                    );
                    saveAs(blob, `${filename}.pdf`);
                    this.activityStatementDownloadingIndex = undefined;
                },
                error: (e) => {
                    console.error(e);
                    this.modalService.openAlert(
                        this.translate(
                            "pdf-download-error-message",
                            this.i18n.formatDate(statement.statementPeriodStartDate, this.DATE_FORMAT_ACTIVITY_STATEMENT)
                        ),
                        {
                            title: this.translate("pdf-download-error"),
                        }
                    );
                    this.activityStatementDownloadingIndex = undefined;
                },
            });
    }

    /**
     * Add statement object to usageCurrentPage based off of date match
     * between usageCurrentPage and billingAccountStatements start date
     */
    private augmentCurrentPageWithStatements() {
        if (!this.usagesCurrentPage) {
            return;
        }

        this.usagesCurrentPage.forEach((usage) => {
            usage.statement = this.billingAccountStatementsReduced?.find((s) => {
                return s.statementPeriodStartDate.split("T")[0] === usage.time.split("T")[0];
            });
        });
    }

    public openHelp(topic?: string) {
        if (topic) {
            this.helpService.setTopic(topic);
        }

        this.helpService.toggleSupport();
    }
}
