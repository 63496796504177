<p>{{translate('budgets-overview-paragraph')}}</p>

<div class="action-bar">
    <button class="btn btn-link" routerLink="create">{{translate('add-budget')}}</button>
    <button class="btn btn-link" [disabled]="editDisabled" (click)="onEdit(selectedBudgets[0])">{{translate('edit')}}</button>
    <button class="btn btn-link" [disabled]="deleteDisabled" (click)="onDelete(selectedBudgets)">{{translate('delete')}}</button>
</div>

<clr-datagrid [(clrDgSelected)]="selectedBudgets" [clrDgLoading]="gridLoading" (clrDgRefresh)="loadBudgets($event)">
    <clr-dg-column>{{translate('usage-type')}}</clr-dg-column>
    <clr-dg-column>{{translate('service')}}</clr-dg-column>
    <clr-dg-column>{{translate('budget-type')}}</clr-dg-column>
    <clr-dg-column>{{translate('amount')}}</clr-dg-column>
    <clr-dg-column>{{translate('last-updated-at')}}</clr-dg-column>
    <clr-dg-column>{{translate('last-updated-by')}}</clr-dg-column>

    <clr-dg-placeholder>{{translate('no-budgets-placeholder-text')}}</clr-dg-placeholder>

    <clr-dg-row *ngFor="let budget of budgets" [clrDgItem]="budget">

        <clr-dg-action-overflow>
            <button class="action-item" (click)="onEdit(budget)" [disabled]="isMultiRowSelectionOn">{{translate('edit')}}</button>
            <button class="action-item" (click)="onDelete([budget])" [disabled]="isMultiRowSelectionOn">{{translate('delete')}}</button>
        </clr-dg-action-overflow>

        <clr-dg-cell>{{budget.usageType}}</clr-dg-cell>
        <clr-dg-cell>{{budget.service}}</clr-dg-cell>
        <clr-dg-cell>{{budget.budgetType}}</clr-dg-cell>
        <clr-dg-cell>{{budget.amount}}</clr-dg-cell>
        <clr-dg-cell>{{budget.lastUpdatedAt}}</clr-dg-cell>
        <clr-dg-cell>{{budget.lastUpdatedBy}}</clr-dg-cell>

    </clr-dg-row>
        
    <clr-dg-footer>{{translate('budgets-footer', totalBudgets)}}</clr-dg-footer>
        
</clr-datagrid>

<ng-template #deleteBudgetsModalContent>
    <p class="delete-modal-confirmation-message">{{budgetsToDelete.length > 1 ? translate('delete-budgets-confirmation') : translate('delete-budget-confirmation')}}</p>
    <br>
    <ul>
        <li *ngFor="let b of budgetsToDelete">{{translate("delete-budget-list-item", b.usageType, b.service)}}</li>
    </ul>
</ng-template>
