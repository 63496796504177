export const ENGLISH = {
    "download-csv": "Export",
    date: "Date",
    commitment: "Commitment",
    "usage-within-commitment": "Usage within Commitment",
    overage: "Overage",
    usage: "Usage",
    unit: "Unit",
    "metric-id": "Metric ID",
    "service-id": "Service ID",
};
