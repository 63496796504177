import { Injectable, NgModule } from "@angular/core";
import { RouterModule, Routes, Router } from "@angular/router";

import { Observable, of, from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { OAuth2TokenSubject } from "@vmw/ngx-csp-oauth2";

import { errorsRoutes } from "./errors/errors.routes";

import {
    ORG_ADMIN_ROLE_NAME,
    ORG_OWNER_ROLE_NAME,
    BILLING_READ_ONLY_ROLE_NAME,
    CSP_READ_ONLY_OPERATOR_ROLE_NAME,
    CSP_CONSENT_READ_ONLY_OPERATOR_ROLE_NAME,
    CSP_PLATFORM_OPERATOR_ROLE_NAME,
    CSP_USAGE_ADMINISTRATOR,
} from "./app.const";

import { UsageManagementOverviewComponent } from "./usage-management-overview/usage-management-overview.component";
import { MetricDetailComponent } from "./usage-management-overview/metric-detail/metric-detail.component";
import { UsageManagementHomeComponent } from "./usage-management-home/usage-management-home.component";
import { UsageManagementBudgetsComponent } from "./usage-management-budgets/usage-management-budgets.component";
import { UsageManagementBudgetsCreateComponent } from "./usage-management-budgets/usage-management-budgets-create/usage-management-budgets-create.component";
import { UsageManagementInsightsComponent } from "./usage-management-insights/usage-management-insights.component";
import { UsageManagementBudgetsEditComponent } from "./usage-management-budgets/usage-management-budgets-edit/usage-management-budgets-edit.component";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";

@Injectable({
    providedIn: "root",
})
export class CceAuthGuard {
    constructor(private oauth2Token$: OAuth2TokenSubject, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.oauth2Token$.pipe(
            switchMap((t) => {
                const userPermissions = t.decoded_access_token.claims.perms;

                if (
                    !(
                        userPermissions.includes(BILLING_READ_ONLY_ROLE_NAME) ||
                        userPermissions.includes(ORG_OWNER_ROLE_NAME) ||
                        userPermissions.includes(ORG_ADMIN_ROLE_NAME) ||
                        userPermissions.includes(CSP_READ_ONLY_OPERATOR_ROLE_NAME) ||
                        userPermissions.includes(CSP_CONSENT_READ_ONLY_OPERATOR_ROLE_NAME) ||
                        userPermissions.includes(CSP_PLATFORM_OPERATOR_ROLE_NAME) ||
                        userPermissions.includes(CSP_USAGE_ADMINISTRATOR)
                    )
                ) {
                    return from(this.router.navigate(["./errors", "request-role-error"]));
                }

                return of(true);
            })
        );
    }
}

@Injectable({
    providedIn: "root",
})
class CcePartnerOrgGuard {
    constructor(private router: Router, private crossOrgSwitcherService: CspCrossOrgSwitcherService) {}

    canActivate(): Observable<boolean> {
        return this.crossOrgSwitcherService.orgDetails$.pipe(
            filter(Boolean),
            map((org) => (org.type as any) === "PARTNER"),
            switchMap((isPartnerOrg) => (isPartnerOrg ? from(this.router.navigate(["/usage-management/reports"])) : of(true)))
        );
    }
}
const routes: Routes = [
    {
        path: "",
        redirectTo: "usage-management",
        pathMatch: "full",
    },
    {
        path: "usage-management",
        component: UsageManagementHomeComponent,
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "overview",
            },
            {
                path: "overview",
                component: UsageManagementOverviewComponent,
                canActivate: [CcePartnerOrgGuard],
            },
            {
                path: "budgets",
                component: UsageManagementBudgetsComponent,
            },
            {
                path: "budgets/create",
                component: UsageManagementBudgetsCreateComponent,
            },
            {
                path: "budgets/edit/:budgetId",
                component: UsageManagementBudgetsEditComponent,
            },
            {
                path: "insights",
                component: UsageManagementInsightsComponent,
                canActivate: [CcePartnerOrgGuard],
            },
            {
                path: "reports",
                loadChildren: () =>
                    import("./usage-management-reports/usage-management-reports.module").then((m) => m.UsageManagementReportsModule),
            },
            {
                path: "usage-meters",
                loadChildren: () =>
                    import("./usage-management-usage-meters/usage-management-usage-meters.module").then(
                        (m) => m.UsageManagementUsageMetersModule
                    ),
            },
        ],
        canActivate: [CceAuthGuard],
    },
    {
        path: "usage-management/services/:serviceId/metrics/:metricId",
        component: MetricDetailComponent,
        canActivate: [CceAuthGuard],
    },
    // this must always be last
    ...errorsRoutes,
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: "disabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
