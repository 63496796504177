import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { ClrDatagridPagination, ClrDatagridStateInterface } from "@clr/angular";
import { forkJoin, EMPTY, Observable, of } from "rxjs";
import { switchMap, take, tap, catchError, filter, map } from "rxjs/operators";
import { ClrDatagridSortOrder } from "@clr/angular";
import { ClarityIcons, barChartIcon } from "@cds/core/icon";

import { CspServiceSdkToolkit } from "@vmw/csp-ngx-service-sdk";
import { CspSeller, CspApiService, Service, CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { OAuth2ScopesSubject } from "@vmw/ngx-csp-oauth2";
import { VmwContextualHelpService } from "@vmw/ngx-contextual-help";
import { L10nService, I18nService, LocalizedComponent } from "@vmw/ngx-vip";
import { Mixin, VmwNgxMatchMediaService } from "@vmw/ngx-utils";
import {
    VmwBarChartXLabelClickEvent,
    VmwBarChartBarClickEvent,
    VmwBarChartBarHoverEvent,
    VmwBarChartData,
    VmwBarChartOptions,
    getFirstDatasetWithVisibleData,
} from "@vmw/ngx-charts/bar-chart";

import { CcePageTitleService, TranslateFunction } from "@cce/core";

import {
    SERVICES_NO_USAGE,
    SERVICE_ROUTE_SEGMENT,
    METRIC_ROUTE_SEGMENT,
    MAX_BAR_THICKNESS,
    SELLER_PARAM_KEY,
} from "./usage-management.const";
import { UsageService } from "./usage.service";
import {
    CceUsageMetricUsagePaginatedViewModel,
    CceUsageMetricChartDataViewModel,
    CceUsageMetricUsageRecordViewModel,
    CceUsageMetricSort,
    CceUsageSortDirection,
} from "./usage-management.model";
import { ORG_OWNER_ROLE_NAME } from "../app.const";
import { ENGLISH } from "./usage-management-overview.component.l10n";
import { CceStatusFilter } from "./cce-status-filter/cce-status-filter.component";
import { ServiceFilterComponent } from "./service-filter/service-filter.component";
import { CostAndUsageFeatureFlags } from "../feature-flags.enum";
import { CspFeatureFlagClient } from "@vmw/csp-ngx-ff-sdk";
import { CostAndUsageTrackMetrics } from "../track-metrics.enum";
import { CspRecommendationsInfo } from "@vmw/csp-ngx-components/recommendations/recommendations.component";
import { CceHorizontalBarChartColors } from "./cce-horizontal-bar-chart/cce-horizontal-bar-chart-bar/cce-horizontal-bar-chart-bar.component";
import { CceUsageChartDataType } from "./usage-chart.transformer";

/**
 * The default page size for the metrics grid. User can override this.
 */
const DEFAULT_METRIC_GRID_PAGE_SIZE = 50;

/**
 * The number of metrics/usage types to retrieve for the Top Usages with Commitment chart
 */
const TOP_USAGES_COUNT = 5;

const AWS_ALERT_LOCAL_STORAGE_ITEM = "aws-usage-alert-closed";

const SELLER_AWS = "AWS";

@Component({
    selector: "usage-management",
    templateUrl: "usage-management-overview.component.html",
    styleUrls: ["usage-management-overview.component.scss"],
})
@Mixin([LocalizedComponent], {
    L10nKey: "usage-management-overview",
    L10nSourceMap: ENGLISH,
})
export class UsageManagementOverviewComponent implements OnInit, LocalizedComponent {
    @ViewChild(ClrDatagridPagination) gridPagination: ClrDatagridPagination;

    public translate: TranslateFunction<typeof ENGLISH>;

    public readonly ClrDatagridSortOrder = ClrDatagridSortOrder;

    /**
     * Show a spinner over the whole page during initial loading
     */
    public initialLoading: boolean;

    /**
     * If we fail to load any data
     */
    public error: boolean;

    /**
     * Current page of metrics displayed in the datagrid
     */
    public gridMetrics: CceUsageMetricUsageRecordViewModel[] = [];

    /**
     * Show a spinner on top of the datagrid while loading data
     */
    public gridMetricsLoading: boolean = true;

    /**
     * Total items available for the datagrid for the current org
     */
    public gridTotalItems: number = 0;

    public activeMetric: { usage: number; commitment: number; unit: string; display_name: string };
    public activeMetricServiceName: string;
    public activeMetricServiceId: string;
    public hasAwsSeller: boolean = false;
    public SAFE_HTML_CONFIG = {
        addTags: ["cds-icon"],
    };

    /**
     * Settings for the top usage chart
     */
    public top5ChartOptions: VmwBarChartOptions<CceUsageMetricChartDataViewModel> = {
        horizontalLine: {
            yPosition: 100,
            color: "#cccccc",
            width: 3,
        },
        xAxisKey: "usageType",
        xAxisType: "category",
        yAxisKey: "usagePercentOfCommitment",
        maxBarThickness: MAX_BAR_THICKNESS,
    };

    /**
     * Data for the top usage chart
     */
    public top5ChartData: VmwBarChartData<CceUsageMetricChartDataViewModel>;

    /**
     * Show a spinner while the data for the top usage chart is loading
     */
    public top5Loading = true;

    /**
     * List of metrics and their service ID for the top usage chart
     *
     * Note, this is only used to project an icon for each bar's x label.
     */
    public top5Metrics: Array<{
        serviceId: string;
        metricId: string;
        sellerId: string;
    }> = [];

    public top5MetricsNew: Array<{
        metricId: string;
        serviceId: string;
        seller: string;
        usage: number;
        commitment: number;
        display_name: string;
        unit: string;
        serviceName?: string;
    }> = [];

    public emptyMessage: string;
    public awsConsoleLink: string;

    public orgId: string;
    public orgServices: Service[];

    @ViewChild(CceStatusFilter)
    public cceStatusFilter: CceStatusFilter;

    @ViewChild(ServiceFilterComponent)
    public serviceFilter: ServiceFilterComponent;

    public enableCurrentUsageDownloadCSV$ = this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableCurrentUsageDownloadCSV);
    public enableHorizontalCharts$ = this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableHorizontalCharts);
    public page: number;
    public size: number;
    public sort: CceUsageMetricSort;
    public direction: CceUsageSortDirection;
    public filters: Object;

    public readonly CceHorizontalBarChartColors = CceHorizontalBarChartColors;

    private sellerNames: { [key: string]: Observable<string> } = {};

    public enableCurrentConsumptionBarFeatureFlag$ = this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableCurrentConsumptionBar);
    public enableConsumptionBar: boolean = false;

    constructor(
        private cspServiceSdk: CspServiceSdkToolkit,
        private cspApiService: CspApiService,
        private helpService: VmwContextualHelpService,
        private router: Router,
        private route: ActivatedRoute,
        private usageService: UsageService,
        public l10nService: L10nService,
        private i18n: I18nService,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        private cceTitleService: CcePageTitleService,
        private ffClient: CspFeatureFlagClient,
        private scopesSubject: OAuth2ScopesSubject,
        private matchMediaService: VmwNgxMatchMediaService
    ) {
        this.emptyMessage = this.translate("no-usage-info-available");

        ClarityIcons.addIcons(barChartIcon);

        // Add labels to each section of each bar on the top 5 usage chart
        this.top5ChartOptions.dataLabelCallback = this.dataLabelCallback.bind(this);

        this.top5ChartOptions.yLabelCallback = (value: number, index: number, ticks: any[]) => {
            return `${value}%`;
        };

        this.top5ChartOptions.xLabelCallback = this.top5UsageXLabelCallback.bind(this);

        this.cceTitleService.setPageTitle(this.translate("usage-management"));

        this.matchMediaService.match("(min-width: 1800px)").subscribe((queryResult) => {
            if (queryResult.matches) {
                this.enableConsumptionBar = true;
            } else {
                this.enableConsumptionBar = false;
            }
        });
    }

    public ngOnInit(): void {
        this.initialLoading = true;

        this.crossOrgSwitcherService.enableCrossOrg();

        this.helpService.setContext("usage", "usage management");

        this.crossOrgSwitcherService.orgDetails$
            .pipe(
                filter(Boolean),
                switchMap((org) => {
                    this.orgId = org.id;
                    this.top5Loading = true;

                    return this.loadUsageData();
                })
            )
            .subscribe({
                next: () => {
                    this.initialLoading = false;
                },
            });
    }

    /**
     * Do the initial load of usage data for the selected org
     */
    public loadUsageData() {
        return forkJoin({
            usageDataCharts: this.usageService.getTopUsagesForChart(this.orgId, TOP_USAGES_COUNT),
            topUsage: this.usageService.getTopUsages(this.orgId, 5),
            usageGridFirstPage: this.getMetricsPage(0, this.gridPageSize, CceUsageMetricSort.STATUS, CceUsageSortDirection.Descending),
            billingAccounts: this.cspApiService.getOrganizationBillingAccounts(this.orgId),
            orgServices: this.cspApiService.getOrgServices(this.orgId),
        }).pipe(
            tap(({ usageDataCharts, topUsage, usageGridFirstPage, billingAccounts, orgServices }) => {
                this.orgServices = orgServices;

                this.top5ChartData = usageDataCharts;

                this.top5MetricsNew = topUsage.map((usageData) => {
                    return {
                        display_name: usageData.metadata.display_name,
                        usage: usageData.usage.usage,
                        commitment: usageData.usage.commitment,
                        metricId: usageData.metadata.metric_id,
                        serviceId: usageData.metadata.service_definition_id,
                        seller: usageData.usage.seller,
                        unit: usageData.metadata.unit_of_measure_display_name,
                        serviceName: this.getServiceNameForId(usageData.metadata.service_definition_id),
                    };
                });

                this.top5Loading = false;

                this.top5Metrics = getFirstDatasetWithVisibleData(this.top5ChartData.datasets).data.map((d: any) => {
                    return {
                        serviceId: d.serviceId,
                        metricId: d.metricId,
                        sellerId: d.sellerId,
                    };
                });

                const awsBillingAccount = billingAccounts.find((ba) => ba.seller === SELLER_AWS);
                if (awsBillingAccount) {
                    this.cspApiService.getSeller(awsBillingAccount.seller).subscribe((awsSeller: CspSeller) => {
                        this.hasAwsSeller = true;
                        this.awsConsoleLink = awsSeller.billing.billingUrl;
                    });
                }

                if (!this.haveData) {
                    this.cspServiceSdk.trackEvent({
                        title: CostAndUsageTrackMetrics.UsageManagementLoadedOrgNoData,
                    });
                } else {
                    this.cspServiceSdk.trackEvent({
                        title: CostAndUsageTrackMetrics.UsageManagementLoadedWithData,
                    });
                    this.setEmptyMessage();
                }
            }),
            catchError((e) => {
                console.error("failed to load data", e);
                this.cspServiceSdk.trackEvent({
                    title: CostAndUsageTrackMetrics.UsageManagementFailedToLoad,
                });
                this.initialLoading = false;
                this.top5Loading = false;
                this.gridMetricsLoading = false;
                this.error = true;
                return EMPTY;
            })
        );
    }

    /**
     * Load metrics for the datagrid.
     *
     * Note: this method is called both explicitly from ngOnInit pathway and also automatically by
     * Clarity datagrid both on load and when user pages to the next or previous page.
     *
     * The API calls are cached in the lower layers for 60 seconds to avoid any duplicate calls.
     */
    public loadGridMetrics(state: ClrDatagridStateInterface) {
        const page = state.page.current - 1;
        const size = state.page.size;

        this.gridMetricsLoading = true;

        let filters: { [prop: string]: string } = {};
        if (state.filters) {
            for (let filter of state.filters) {
                if (filter?.selectedStatuses) {
                    filters["status"] = filter.selectedStatuses;
                }
                if (filter?.selectedServices) {
                    filters["service_definition_id"] = filter.selectedServices;
                }
            }
        }

        this.getMetricsPage(
            page,
            size,
            state.sort?.by as CceUsageMetricSort,
            state.sort?.reverse ? CceUsageSortDirection.Descending : CceUsageSortDirection.Ascending,
            filters
        ).subscribe();
    }

    public closeAwsAlert() {
        window.localStorage.setItem(AWS_ALERT_LOCAL_STORAGE_ITEM, "true");
    }

    public get awsAlertClosed() {
        return window.localStorage.getItem(AWS_ALERT_LOCAL_STORAGE_ITEM) === "true";
    }

    public get showAwsAlert() {
        return this.haveData && this.hasAwsSeller && !this.awsAlertClosed;
    }

    private setEmptyMessage() {
        if (
            this.orgServices.some((s) => {
                return SERVICES_NO_USAGE.includes(s.id);
            })
        ) {
            this.emptyMessage = this.translate("usage-may-be-available-in-service-console");
        }

        if (this.hasAwsSeller) {
            this.emptyMessage += ` ${this.translate("aws-data-in-aws-console", this.awsConsoleLink)}`;
        }
    }

    private getMetricsPage(page: number, size: number, sort: CceUsageMetricSort, direction: CceUsageSortDirection, filters?: Object) {
        this.page = page;
        this.size = size;
        this.sort = sort;
        this.direction = direction;
        this.filters = filters;

        return this.usageService
            .getOrganizationMetricUsageRecords(this.orgId, page, size, sort, direction, filters)
            .pipe(tap(this.setGridPage.bind(this)));
    }

    private setGridPage(metricsData: CceUsageMetricUsagePaginatedViewModel) {
        this.gridTotalItems = metricsData.totalItems;
        this.gridMetrics = metricsData.metrics;
        // Avoid ExpressionChangedAfterChecked
        setTimeout(() => {
            this.gridMetricsLoading = false;
        });
    }

    /**
     * Return the current grid size from the clarity paginator or the default
     */
    public get gridPageSize(): number {
        return +this.gridPagination?.pageSize || DEFAULT_METRIC_GRID_PAGE_SIZE;
    }

    /**
     * Do we have either top usage data or metrics in the datagrid?
     *
     * If we don't have any data, we want to show a single empty state placeholder
     */
    public get haveData() {
        return this.haveTop5Data || this.gridMetrics?.length > 0;
    }

    /**
     * Do we have any top usage data?
     *
     * If we don't have top 5 data we will show a placeholder instead
     */
    public get haveTop5Data() {
        if (this.top5ChartData?.datasets) {
            return getFirstDatasetWithVisibleData(this.top5ChartData?.datasets).data.length > 0;
        }
        return false;
    }

    public get filtersActive(): boolean {
        return this.cceStatusFilter?.isActive() || this.serviceFilter?.isActive();
    }

    /**
     * When user hovers over a bar in the top 5 usages chart we will update our projected tooltip
     * with the active metrics data
     */
    public setActiveMetric(metric: VmwBarChartBarHoverEvent<CceUsageMetricChartDataViewModel>) {
        const usage = metric.dataItems.reduce<number>((sum, dataItem) => {
            if (
                undefined === dataItem.dataItem?.usage ||
                dataItem.datasetId === CceUsageChartDataType.UsageAvailable ||
                dataItem.datasetId === CceUsageChartDataType.Trend ||
                dataItem.datasetId === CceUsageChartDataType.WeightedTrend ||
                dataItem.datasetId === CceUsageChartDataType.Commitment
            ) {
                return sum;
            }
            return sum + dataItem.dataItem.usage;
        }, 0);

        const commitment = metric.dataItems[metric.dataItemIndex]?.dataItem?.commitment || 0;

        const unit = metric.dataItems[metric.dataItemIndex]?.dataItem?.unit;

        const display_name = metric.dataItems[metric.dataItemIndex]?.dataItem?.usageType;

        const newMetric = {
            usage: usage,
            commitment: commitment,
            unit: unit,
            display_name: display_name,
        };

        this.activeMetric = newMetric;
        this.activeMetricServiceId = metric.dataItems[metric.dataItemIndex].dataItem.serviceId;
        this.activeMetricServiceName = this.getServiceNameForId(this.activeMetricServiceId);
    }

    public navigateToMetricDetail(serviceId: string, metricId: string, sellerId: string) {
        const queryParams: Params = { [SELLER_PARAM_KEY]: sellerId };

        this.router.navigate(["/usage-management", SERVICE_ROUTE_SEGMENT, serviceId, METRIC_ROUTE_SEGMENT, metricId], {
            relativeTo: this.route,
            queryParams,
        });
    }

    /**
     * Route the user to the metric detail page when they click on an x label on the top 5 usage chart
     */
    public top5UsageLabelClick(e: VmwBarChartXLabelClickEvent<CceUsageMetricChartDataViewModel>) {
        let dataItem = e.dataItems[0].dataItem;
        const serviceId = dataItem.serviceId;
        const metricId = dataItem.metricId;
        const sellerId = dataItem.sellerId;
        this.navigateToMetricDetail(serviceId, metricId, sellerId);
    }

    /**
     * Route the user to the metric detail page when they click on a bar on the top 5 usage chart
     */
    public top5UsageBarClick(e: VmwBarChartBarClickEvent<CceUsageMetricChartDataViewModel>) {
        let dataItem = e.dataItems[e.dataItemIndex].dataItem;
        const serviceId = dataItem.serviceId;
        const metricId = dataItem.metricId;
        const sellerId = dataItem.sellerId;
        this.navigateToMetricDetail(serviceId, metricId, sellerId);
    }

    public openHelp(topic?: string) {
        if (topic) {
            this.helpService.setTopic(topic);
        }

        this.helpService.toggleSupport();
    }

    /**
     * Callback to generate the top 5 usage chart bar labels
     */
    private dataLabelCallback(data: CceUsageMetricChartDataViewModel) {
        const FORMAT_OPTIONS = {
            maxFractionDigits: 2,
        };
        return data.usage === 0
            ? null
            : `${this.i18n.formatNumber(data.usage, this.cspApiService.currentLocale, FORMAT_OPTIONS)} ${data.unit ?? ""}`;
    }

    /**
     * Return a display name for a given service ID, or fall back to just the service ID
     */
    private getServiceNameForId(serviceId: string) {
        return this.orgServices?.find((s: Service) => s.id === serviceId)?.displayName || serviceId;
    }

    private top5UsageXLabelCallback(value: any, index: number, ticks: any[]) {
        return (getFirstDatasetWithVisibleData(this.top5ChartData.datasets).data[index] as unknown as CceUsageMetricChartDataViewModel)
            .usageType;
    }

    public getSellerFromId(id: string): Observable<string> {
        // check if observable has already been created
        // we do this to 1. stop multiple calls to the api
        // and 2. ensure we are not creating a new observable
        if (this.sellerNames[id]) {
            return this.sellerNames[id];
        }

        if (id === "MULTIPLE") {
            return of(this.translate("multiple"));
        }

        const seller$ = this.cspApiService.getSeller(id).pipe(map((cspSeller) => cspSeller.name));

        this.sellerNames[id] = seller$;

        return seller$;
    }
}
