import { Injectable, Inject, APP_INITIALIZER } from "@angular/core";

import { firstValueFrom, Subject } from "rxjs";

import { VmwContextualHelpService } from "@vmw/ngx-contextual-help";

import { EarlyPreloadResult, EarlyPreloadService } from "./preload-early.service";

import { APP_ENVIRONMENT, CceUiEnvironment } from "./environment.model";

export const SUPPORTED_LANGUAGES: { [key: string]: string } = {
    en: "en_US",
    ja: "ja_JP",
    ko: "ko_KR",
    fr: "fr_FR",
    de: "de_DE",
    es: "es_ES",
    zh: "zh_TW",
    zh_TW: "zh_TW",
    zh_CN: "zh_CN",
    tw: "zh_TW",
    cn: "zh_CN",
    jp: "ja_JP",
    it: "it_IT",
};

export function contextualHelpPreloadFactory(contextualHelpPreloadService: ContextualHelpPreloadService) {
    return () => {
        return contextualHelpPreloadService
            .initialize()
            .then()
            .catch((e) => {
                console.error(e);
                return new Promise(() => {});
            });
    };
}

export interface ContextualHelpPreloadResult {
    result: boolean;
}

@Injectable({
    providedIn: "root",
})
export class ContextualHelpPreloadService extends Subject<ContextualHelpPreloadResult> {
    constructor(
        @Inject(APP_ENVIRONMENT) private environment: CceUiEnvironment,
        private earlyPreloadService: EarlyPreloadService,
        private helpService: VmwContextualHelpService
    ) {
        super();
    }

    async initialize() {
        const earlyPreloadResult = await firstValueFrom(this.earlyPreloadService);

        const locale = this.getSupportedLocale(earlyPreloadResult.cspInitializationResult.locale);

        const getIndexUrl = (lang: string): [string, string] => {
            return [`assets/docs/${lang}.json`, `assets/docs/${lang}-contexts.json`];
        };

        this.helpService.addIndexByUrl("default", ...getIndexUrl(locale));

        this.next({
            result: true,
        });
    }

    private getSupportedLocale(language: string): string {
        let helpLanguage = language.substring(0, 2);

        if (helpLanguage === "zh") {
            helpLanguage = language;
        }

        if (!SUPPORTED_LANGUAGES[helpLanguage]) {
            helpLanguage = "en";
        }

        return helpLanguage;
    }
}

export const CONTEXTUAL_HELP_PRELOAD_PROVIDER = {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ContextualHelpPreloadService, APP_ENVIRONMENT, EarlyPreloadService],
    useFactory: contextualHelpPreloadFactory,
};
