<clr-main-container class="main-container">
    <csp-header *ngIf="!cspServiceSdk.isEmbedded"
                [authToken]="authToken"
                [serviceRefLink]="serviceId"
                [options]="headerOptions"
                [openDrawer]="openDrawer"
                (switchOrg)="switchOrg($event)">
        <div class="csp-header-developer-panel">
            <div class="csp-header-docker-image-tag clr-row csp-dev-item">
                <label class="clr-col-5">Commit</label>
                <div class="clr-col-7">{{ environment.commit || "unknown" }}</div>
            </div>
        </div>
    </csp-header>

    <div class="content-container">
        <div class="content-area">
            <csp-cross-org-switcher *ngIf="showOrgSelector | async"></csp-cross-org-switcher>
            <div class="dev-controls">
                <vmw-theme-switch-button *ngIf="!cspServiceSdk.isEmbedded"></vmw-theme-switch-button>
                <clr-toggle-container *ngIf="isLocalhost" class="mock-toggle">
                    <clr-toggle-wrapper>
                        <input type="checkbox" [(ngModel)]="localMock" clrToggle [value]="true" name="localMock" />
                        <label>Enable Local Mock</label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>



<vmw-snackbar *ngIf="notificationService.snacks.length">
    <vmw-snack *ngFor="let s of notificationService.snacks">
        <span class="message">
            <span [innerHTML]="s.title"></span>
        </span>
    </vmw-snack>
</vmw-snackbar>

<vmw-toast-container>
    <vmw-toast *ngFor="let t of notificationService.toasts"
            [type]="t.type">
        <div class="toast-title">{{t.title}}</div>
        <p class="toast-description">{{t.description}}</p>
    </vmw-toast>
</vmw-toast-container>

