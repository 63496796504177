import { Component, OnInit } from "@angular/core";
import { Mixin, LocalizedComponent, L10nService } from "@vmw/ngx-vip";
import { ENGLISH } from "./usage-management-home.component.l10n";
import { TranslateFunction } from "@cce/core";
import { CostAndUsageFeatureFlags } from "../feature-flags.enum";
import { CspFeatureFlagClient } from "@vmw/csp-ngx-ff-sdk";
import { combineLatest, filter, map } from "rxjs";
import { OAuth2TokenSubject } from "@vmw/ngx-csp-oauth2";
import {
    CSP_CONSENT_READ_ONLY_OPERATOR_ROLE_NAME,
    CSP_PLATFORM_OPERATOR_ROLE_NAME,
    CSP_READ_ONLY_OPERATOR_ROLE_NAME,
    CSP_USAGE_ADMINISTRATOR,
    ORG_OWNER_ROLE_NAME,
} from "../app.const";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";

const PERMITTED_ROLES = [
    CSP_USAGE_ADMINISTRATOR,
    CSP_READ_ONLY_OPERATOR_ROLE_NAME,
    CSP_CONSENT_READ_ONLY_OPERATOR_ROLE_NAME,
    CSP_PLATFORM_OPERATOR_ROLE_NAME,
    // ORG_OWNER_ROLE_NAME // not supported for now
];
@Mixin([LocalizedComponent], {
    L10nKey: "usage-management-home",
    L10nSourceMap: ENGLISH,
})
@Component({
    selector: "cce-usage-management-home",
    templateUrl: "./usage-management-home.component.html",
    styleUrls: ["./usage-management-home.component.scss"],
})
export class UsageManagementHomeComponent implements OnInit {
    public enableUsageManagementBudgets$ = this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableUsageManagementBudgets);

    public isPartnerOrg$ = this.crossOrgSwitcherService.orgDetails$.pipe(
        filter(Boolean),
        map((org) => (org.type as any) === "PARTNER") //TODO: need to add "PARTNER" type to OrganizationType in @vmw/csp-common
    );

    public enableInsights$ = combineLatest([this.isPartnerOrg$, this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableInsights)]).pipe(
        map(([isPartnerOrg, enableInsights]) => !isPartnerOrg && enableInsights)
    );

    public hasUmRole$ = this.oauth2Token$.pipe(
        map((t) => PERMITTED_ROLES.some((role) => t.decoded_access_token.claims.perms.includes(role)))
    );
    public enableUmOverview$ = this.isPartnerOrg$.pipe(map((isPartner) => !isPartner));
    // enable UM if only the org type is PARTNER and the use has org_owner or usage_administrator role
    public enableUm$ = combineLatest([this.isPartnerOrg$, this.hasUmRole$]).pipe(map((results) => results.every(Boolean)));

    public enableTabs$ = combineLatest([this.enableUsageManagementBudgets$, this.enableInsights$, this.enableUm$]).pipe(
        map((flags) => flags.includes(true))
    );
    public enableUmReports$ = combineLatest([this.enableUm$, this.ffClient.evaluate$(CostAndUsageFeatureFlags.EnableUmReports)]).pipe(
        map((flags) => flags.every(Boolean))
    );

    public translate: TranslateFunction<typeof ENGLISH>;

    constructor(
        public l10nService: L10nService,
        private ffClient: CspFeatureFlagClient,
        private oauth2Token$: OAuth2TokenSubject,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService
    ) {}

    ngOnInit(): void {}
}
