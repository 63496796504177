import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class CceHorizontalBarChartService {
    public max: number;
    public isThresholdEnabled: boolean;

    public registrationComplete$ = new Subject<void>();
    public activeBar$ = new BehaviorSubject(false);

    public set bars(bars: any[]) {
        if (bars.length === 0) {
            this.max = 0;
            return;
        }

        bars.forEach((bar) => {
            var pct = (bar.value / bar.threshold) * 100;
            if (pct > this.max) {
                this.max = pct;
            }
        });
        this.registrationComplete$.next();
    }

    public reset() {
        this.bars = [];
    }
}
