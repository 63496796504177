import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VmwComponentsModule } from "@vmw/ngx-components";
import { CceHorizontalBarChartComponent, CceHorizontalBarChartThresholdLabelComponent } from "./cce-horizontal-bar-chart.component";
import { CceHorizontalBarChartBarComponent } from "./cce-horizontal-bar-chart-bar/cce-horizontal-bar-chart-bar.component";
import { CceHorizontalBarChartBarLabelComponent } from "./cce-horizontal-bar-chart-bar/cce-horizontal-bar-chart-bar-label/cce-horizontal-bar-chart-bar-label.component";
import { CceHorizontalBarChartBarTooltipComponent } from "./cce-horizontal-bar-chart-bar/cce-horizontal-bar-chart-bar-tooltip/cce-horizontal-bar-chart-bar-tooltip.component";
import { CceHorizontalBarChartBarsDirective } from "./cce-horizontal-bar-chart.directive";
import { CceHorizontalBarChartService } from "./cce-horizontal-bar-chart.service";

@NgModule({
    declarations: [
        CceHorizontalBarChartComponent,
        CceHorizontalBarChartBarComponent,
        CceHorizontalBarChartBarLabelComponent,
        CceHorizontalBarChartThresholdLabelComponent,
        CceHorizontalBarChartBarTooltipComponent,
        CceHorizontalBarChartBarsDirective,
    ],
    imports: [CommonModule, VmwComponentsModule],
    providers: [CceHorizontalBarChartService],
    exports: [
        CceHorizontalBarChartComponent,
        CceHorizontalBarChartBarComponent,
        CceHorizontalBarChartBarLabelComponent,
        CceHorizontalBarChartThresholdLabelComponent,
        CceHorizontalBarChartBarTooltipComponent,
        CceHorizontalBarChartBarsDirective,
    ],
})
export class CceHorizontalBarChartModule {}
