import { CceUsageManagementBudgetType } from "../usage-management-budgets.model";

export const ENGLISH = {
    back: "Back",
    "edit-budget": "Edit Budget",
    "editing-budget-overview-paragraph": "You are editing the budget of usage type {0}, of service {1}",
    service: "Service",
    "usage-type": "Usage type",
    "select-budget-type": "Select budget type",
    "select-a-budget-type": "Select a budget type",
    "commitment-percentage": "Commitment percentage",
    "enter-commitment-percentage": "Enter commitment percentage",
    "actual-usage": "Actual usage",
    "enter-usage": "Enter usage",
    "%": "%",
    save: "Save",
    cancel: "Cancel",
    [`budget-type.${CceUsageManagementBudgetType.PERCENTAGE}`]: "Commitment Percentage",
    [`budget-type.${CceUsageManagementBudgetType.ACTUAL}`]: "Actual Usage",
    "editing-budget-failed-message": "Could not edit this budget, please try again. If the issue persists please report it.",
    "editing-budget-failed-title": "Failed to edit this budget",
};
