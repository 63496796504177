<div class="tooltip-title">
    <csp-service-icon
        *ngIf="serviceId"
        [colorFormat]="VmwSvgIconColorFormat.NEVER"
        [serviceID]="serviceId">
    </csp-service-icon>

    <div class="title-container">
        <div class="title">
            {{title}}
        </div>
        <div *ngIf="subtitle" class="subtitle">
            {{subtitle}}
        </div>
    </div>
</div>

<div class="tooltip-items">
    <div class="tooltip-item" *ngFor="let item of items">
        <ng-container *ngIf="item.value > 0">
            <div class="tooltip-item-color" [style.backgroundColor]="item.color" ></div>
            <div class="tooltip-item-label">{{item.label}}: {{item.value | numberFormat: {maxFractionDigits: 2} }} {{unit ?? ""}}</div>
        </ng-container>
    </div>
</div>

<div class="tooltip-footer">
    {{footerText}}
</div>
