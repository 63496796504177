export const ENGLISH = {
    "budgets-overview-paragraph":
        "Only opt-in services are available. By default, you will be notified when your term commitment usage reached service-set threshold percentage. You can customize your preferences by editing or creating new notification thresholds. (short descriptive text)",
    "usage-type": "Usage Type",
    service: "Service",
    "budget-type": "Budget Type",
    amount: "Amount",
    "last-updated-at": "Last Updated At",
    "last-updated-by": "Last Updated By",
    "add-budget": "Add Budget",
    edit: "Edit",
    delete: "Delete",
    "no-budgets-placeholder-text": "We couldn't find any budgets!",
    "budgets-footer": "{0} budgets",
    "delete-budget": "Delete Budget",
    "delete-budgets": "Delete Budgets",
    cancel: "Cancel",
    "delete-budget-list-item": "{0} - {1}",
    "delete-budget-confirmation": "Are you sure you want to delete the following budget?",
    "delete-budgets-confirmation": "Are you sure you want to delete the following budgets?",
    "successfully-deleted-budget": "Successfully deleted budget",
    "successfully-deleted-budgets": "Successfully deleted budgets",
    "successfully-created-budget": "Successfully created budget",
    "successfully-edited-budget": "Successfully edited budget",
    "error-deleting-budgets-title": "Could not delete budgets",
    "error-deleting-budgets-message":
        "An issue occurred when trying to delete budgets, please try again. If the issue persists, please report it.",
};
