import { Injectable } from "@angular/core";

import { Observable, map, forkJoin, switchMap, of } from "rxjs";
import { VmwHttpClient } from "@vmw/ngx-utils/caching-http-client";

import { CceUsageMetricUsagePaginatedDto, CceUsageMetricUsageStatus } from "../usage-management-overview/usage-management.model";

import { DATE_RANGE_PARAM, INSIGHTS_URL, SIZE_PARAM } from "./usage-management-insights.const";

import { CceInsight, CceInsightDto, CceInsightType, CceMetricsTotal } from "./usage-manangement-insights.model";

import { METRIC_SEARCH_URL } from "../usage-management-overview/usage-management.const";
import { HttpParams } from "@angular/common/http";
import { CspApiService } from "@vmw/csp-ngx-components";

@Injectable()
export class InsightsService {
    constructor(private http: VmwHttpClient, private cspApiService: CspApiService) {}

    public getInsights(orgId: string): Observable<CceInsight[]> {
        const params = new HttpParams({
            fromObject: {
                [DATE_RANGE_PARAM]: 30,
            },
        });

        const obs = this.http.get<CceInsightDto>(INSIGHTS_URL.replace("{orgId}", orgId), { params, cached: true });

        return forkJoin({
            insightsDto: obs,
            services: this.cspApiService.getOrgServices(orgId),
        }).pipe(
            map(({ insightsDto, services }) => {
                return this.transfromInsights(insightsDto, services);
            })
        );
    }

    public getTotalInsights(orgId: string): Observable<CceMetricsTotal> {
        const params = new HttpParams({
            fromObject: {
                [SIZE_PARAM]: 100,
            },
        });

        let usagesOver = 0;
        let usagesBelow = 0;
        let usagesAt = 0;
        let usagesDemand = 0;

        return this.http
            .post<CceUsageMetricUsagePaginatedDto>(METRIC_SEARCH_URL.replace("{orgId}", orgId), {}, { params, cached: true })
            .pipe(
                switchMap((metrics) => {
                    metrics.content.forEach((metric) => {
                        if (this.isMetricRecent(metric.usage.last_updated)) {
                            switch (metric.usage.status) {
                                case CceUsageMetricUsageStatus.OVER_COMMITMENT:
                                    usagesOver++;
                                    break;
                                case CceUsageMetricUsageStatus.BELOW_COMMITMENT:
                                    usagesBelow++;
                                    break;
                                case CceUsageMetricUsageStatus.AT_COMMITMENT:
                                    usagesAt++;
                                    break;
                                case CceUsageMetricUsageStatus.ON_DEMAND:
                                    usagesDemand++;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    return of({
                        totalUsages: usagesOver + usagesBelow + usagesAt + usagesDemand,
                        usagesOver: usagesOver,
                        usagesBelow: usagesBelow,
                        usagesAt: usagesAt,
                        usagesDemand: usagesDemand,
                    });
                })
            );
    }

    private transfromInsights(insightsDto: CceInsightDto, services): CceInsight[] {
        return insightsDto.content
            .filter((insight) => [CceInsightType.OVER_COMMITMENT, CceInsightType.BELOW_COMMITMENT].includes(insight.insight_type))
            .map((insight) => {
                return {
                    displayName: insight.metadata.display_name,
                    serviceId: insight.metadata.service_definition_id,
                    serviceName:
                        services.find((service) => service.id === insight?.metadata.service_definition_id)?.displayName ||
                        insight?.metadata.service_definition_id,
                    commitment: insight.commit_quantity,
                    usage: insight.usage_quantity,
                    unit: insight.metadata.unit_of_measure_display_name,
                    type: insight.insight_type,
                    metricId: insight.metadata.metric_id,
                    sellerId: insight.seller_id,
                } as CceInsight;
            });
    }

    private isMetricRecent(date: string): boolean {
        const lastUpdated = new Date(date);
        const limit = 86400000 * 30;
        const today = new Date();

        return today.getTime() - lastUpdated.getTime() < limit;
    }
}
