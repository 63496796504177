import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";

import { forkJoin, Observable } from "rxjs";
import { map, delay } from "rxjs/operators";

import { CspApiService } from "@vmw/csp-ngx-components";
import { VmwHttpClient } from "@vmw/ngx-utils/caching-http-client";

import {
    CceUsageMetricUsageDto,
    CceUsageMetricUsagePaginatedDto,
    CceUsageMetricMetadataDto,
    CceUsageMetricUsageStatus,
    CceUsageMetricUsageRecordViewModel,
    CceUsageMetricUsagePaginatedViewModel,
    CceUsageMetricChartDataViewModel,
    CceMetricUsageOverviewDto,
    CceUsageMetricMetricUsageOverviewViewModel,
    CceUsageMetricMetricUsageHistoryDto,
    CceUsageMetricMetricUsageHistoryRecordViewModel,
    CceUsageMetricAggregationPeriod,
    CceUsageMetricMetricUsageHistoryRecordDto,
    CceUsageMetricAggregationType,
    CceUsageSortDirection,
    CceUsageMetricSort,
} from "./usage-management.model";
import {
    COUNT_PARAM,
    LOCALE_PARAM,
    METRIC_ID_PARAM,
    METRIC_SEARCH_URL,
    PAGE_PARAM,
    PAGE_SIZE_PARAM,
    SERVICE_DEFINITION_ID_PARAM,
    SORT_PARAM,
    TOP_USAGE_URL,
    METRIC_USAGE_OVERVIEW_URL,
    USAGE_HISTORY_URL,
    END_DATE_PARAM,
    START_DATE_PARAM,
    SELLER_PARAM,
    AGGREGATION_PERIOD_PARAM,
    AGGREGATION_TYPE_PARAM,
    DAY_IN_MILLISECONDS,
} from "./usage-management.const";
import { CceUsageChartDataTransformer } from "./usage-chart.transformer";
import { VmwBarChartData } from "@vmw/ngx-charts/bar-chart/bar-chart.model";
import { Service } from "@vmw/csp-common";

import { getWeights, weightedLinearRegression } from "./weighted-least-squares.util";

@Injectable()
export class UsageService {
    constructor(
        private http: VmwHttpClient,
        private usageChartDataTransformer: CceUsageChartDataTransformer,
        private cspApiService: CspApiService
    ) {}

    private get localMock(): boolean {
        return window.localStorage.getItem("usage-mock-local") === "true";
    }

    /**
     * Search metrics
     *
     * @param orgId
     */
    getOrganizationMetricUsageRecords(
        orgId: string,
        page: number,
        pageSize: number,
        sort = CceUsageMetricSort.STATUS,
        sortDirection = CceUsageSortDirection.Ascending,
        filters?: Object
    ): Observable<CceUsageMetricUsagePaginatedViewModel> {
        const metricSearchUrl = this.localMock ? "assets/searched-metrics-usages.mock.json" : METRIC_SEARCH_URL.replace("{orgId}", orgId);

        let fromObject = {
            [PAGE_PARAM]: page,
            [PAGE_SIZE_PARAM]: pageSize,
            [SORT_PARAM]: `${sort},${sortDirection}`,
            [LOCALE_PARAM]: this.cspApiService.currentLocale,
        };

        let params = new HttpParams({
            fromObject: fromObject,
        });

        if (filters && Object.keys(filters).length) {
            for (let key of Object.keys(filters)) {
                filters[key].forEach((selectedFilter) => {
                    params = params.append("filter", key + ",eq:" + selectedFilter);
                });
            }
        }

        let obs = this.http.post<CceUsageMetricUsagePaginatedDto>(
            metricSearchUrl,
            {},
            {
                params,
                // N.B. this caching and it's relatively short timeout is a small optimization to avoid a
                // duplicate call from the usage management page.
                cached: true,
                useBodyForCacheKey: true,
                cacheTimeoutMS: 60000,
            }
        );

        if (this.localMock) {
            obs = this.http.get<CceUsageMetricUsagePaginatedDto>(metricSearchUrl, { cached: true }).pipe(
                delay(2000),
                map((d) => {
                    const start = page * pageSize;
                    const end = start + pageSize;
                    return Object.assign({}, d, {
                        content: d.content.slice(start, end),
                    });
                })
            );
        }

        return forkJoin({
            searchedResults: obs,
            services: this.cspApiService.getOrgServices(orgId),
        }).pipe(
            map(({ searchedResults, services }) => {
                return {
                    metrics: searchedResults.content.map((metric) => this.transformMetricToViewModel(orgId, metric, services)),
                    totalItems: searchedResults.total_elements,
                };
            })
        );
    }

    protected transformMetricToViewModel(
        orgId: string,
        dto: CceUsageMetricUsageDto,
        services: Service[]
    ): CceUsageMetricUsageRecordViewModel {
        return {
            orgId: orgId,
            usageType: dto?.metadata.display_name,
            unit: this.formatUnitDisplayValue(dto?.metadata.unit_of_measure_display_name),
            usage: dto?.usage.usage,
            commitment: dto?.usage.commitment,
            status: dto?.usage.status as CceUsageMetricUsageStatus,
            overage: dto?.usage.overage,
            seller: dto?.usage.seller,
            lastUpdated: new Date(dto?.usage.last_updated).toISOString(),
            metricId: dto?.metadata.metric_id,
            serviceId: dto?.metadata.service_definition_id,
            serviceDisplayName:
                services.find((service) => service.id === dto?.metadata.service_definition_id)?.displayName ||
                dto?.metadata.service_definition_id,
            sellerId: dto?.usage.seller_id,
            partnerId: dto?.usage.partner_id,
            billingEngineSubscriptionIds: dto?.usage?.subscriptions?.map((s) => s.billing_engine_subscription_id),
        };
    }

    /**
     * Get the top metrics/usages for an organization
     */
    public getTopUsagesForChart(orgId: string, count: number): Observable<VmwBarChartData<CceUsageMetricChartDataViewModel>> {
        const topUsagesUrl = this.localMock ? "assets/top-5-usage.mock.json" : TOP_USAGE_URL.replace("{orgId}", orgId);

        const params = new HttpParams({
            fromObject: {
                [COUNT_PARAM]: count,
                [LOCALE_PARAM]: this.cspApiService.currentLocale,
            },
        });

        return this.http
            .get<CceUsageMetricUsageDto[]>(topUsagesUrl, { params, cached: true })
            .pipe(map(this.transformTopUsageDataForChart.bind(this)));
    }

    public getTopUsages(orgId: string, count: number): Observable<CceUsageMetricUsageDto[]> {
        const topUsagesUrl = this.localMock ? "assets/top-5-usage.mock.json" : TOP_USAGE_URL.replace("{orgId}", orgId);

        const params = new HttpParams({
            fromObject: {
                [COUNT_PARAM]: count,
                [LOCALE_PARAM]: this.cspApiService.currentLocale,
            },
        });

        return this.http.get<CceUsageMetricUsageDto[]>(topUsagesUrl, { params, cached: true });
    }

    /**
     * Transformer to calculate percentage of commitment for the top 5 usage chart
     */
    private transformTopUsageDataForChart(data: CceUsageMetricUsageDto[]): VmwBarChartData<CceUsageMetricChartDataViewModel> {
        const usageWithinCommitmentsData = [];
        const usageCommitmentAvailableData = [];
        const overageData = [];

        for (let dataItem of data) {
            const usage = dataItem.usage.usage;
            const commitment = dataItem.usage.commitment;

            const within = Math.min(commitment, usage);
            const overage = Math.max(usage - commitment, 0);
            const available = Math.max(commitment - usage, 0);

            const usagePercentRaw = Math.round((usage / commitment) * 100);
            const usagePercent = Math.min(100, usagePercentRaw);
            const overagePercent = Math.max(usagePercentRaw - 100, 0);
            const availablePercent = 100 - usagePercent;

            const baseDataItem: CceUsageMetricChartDataViewModel = {
                usageType: dataItem.metadata.display_name,
                usage: undefined,
                commitment: commitment,
                usagePercentOfCommitment: undefined,
                unit: this.formatUnitDisplayValue(dataItem.metadata.unit_of_measure_display_name),
                metricId: dataItem.metadata.metric_id,
                serviceId: dataItem.metadata.service_definition_id,
                sellerId: dataItem.usage.seller_id,
            };

            usageWithinCommitmentsData.push(
                Object.assign({}, baseDataItem, {
                    usage: Math.round(within),
                    usagePercentOfCommitment: Math.round(usagePercent),
                })
            );

            usageCommitmentAvailableData.push(
                Object.assign({}, baseDataItem, {
                    usage: Math.round(available),
                    usagePercentOfCommitment: Math.round(availablePercent),
                })
            );

            overageData.push(
                Object.assign({}, baseDataItem, {
                    usage: Math.round(overage),
                    usagePercentOfCommitment: Math.round(overagePercent),
                })
            );
        }

        return this.usageChartDataTransformer.getChartData(
            usageWithinCommitmentsData,
            overageData,
            usageCommitmentAvailableData,
            false,
            false,
            false
        );
    }

    /**
     * For metric-detail page: metric current usage overview
     * Get selected metric usage overview
     *
     * @param orgId
     * @param serviceId
     * @param metricId
     * @param beforeTime
     * @param locale
     */
    getMetricUsageOverview(
        orgId: string,
        serviceId: string,
        metricId: string,
        locale?: string
    ): Observable<CceUsageMetricMetricUsageOverviewViewModel> {
        const url = METRIC_USAGE_OVERVIEW_URL.replace("{orgId}", orgId);
        const params = new HttpParams({
            fromObject: {
                [SERVICE_DEFINITION_ID_PARAM]: serviceId,
                [METRIC_ID_PARAM]: metricId,
                [LOCALE_PARAM]: this.cspApiService.currentLocale,
            },
        });

        let obs = this.http.get<CceMetricUsageOverviewDto>(url, { params, cached: true });

        if (this.localMock) {
            obs = this.http.get<CceMetricUsageOverviewDto>("assets/metric-overview.json", { cached: true }).pipe(delay(2000));
        }

        return obs.pipe(map(this.transformMetricUsageOverview.bind(this)));
    }

    protected transformMetricUsageOverview(dto: CceMetricUsageOverviewDto): CceUsageMetricMetricUsageOverviewViewModel {
        // as per angular documentation dates should be dates should be string | number | Date
        // as we are using strings we need to ensure they are in ISO format
        // currently backend api does not return dates string in ISO format
        dto.usages.forEach((usage) => {
            usage.last_updated = new Date(usage.last_updated).toISOString();
        });
        return {
            usageType: dto.metadata.display_name,
            unit: this.formatUnitDisplayValue(dto.metadata.unit_of_measure_display_name),
            sellers: dto.usages.map((u) => u.seller),
            metricId: dto.metadata.metric_id,
            serviceId: dto.metadata.service_definition_id,
            usages: dto.usages,
        };
    }

    /**
     * get metric historical usages
     */
    getHistoricalUsage(
        orgId: string,
        metricId: string,
        serviceId: string,
        sellerId: string,
        page: number,
        pageSize: number,
        startDate: Date,
        endDate: Date,
        aggregationPeriod: CceUsageMetricAggregationPeriod,
        aggregationType: CceUsageMetricAggregationType,
        sort: CceUsageMetricSort = CceUsageMetricSort.USAGE_DATE_TIME,
        sortDirection: CceUsageSortDirection = CceUsageSortDirection.Descending
    ): Observable<CceUsageMetricMetricUsageHistoryDto> {
        const getAPIDateFormat = (d: Date) => {
            // we use Sweden becasue Swedish dates use the ISO 8601 format
            return d.toLocaleString("sv").split(" ")[0];
        };
        const url = USAGE_HISTORY_URL.replace("{orgId}", orgId);
        const params = new HttpParams({
            fromObject: {
                [SERVICE_DEFINITION_ID_PARAM]: serviceId,
                [METRIC_ID_PARAM]: metricId,
                [SELLER_PARAM]: sellerId,
                [LOCALE_PARAM]: this.cspApiService.currentLocale,
                [PAGE_PARAM]: page,
                [PAGE_SIZE_PARAM]: pageSize,
                [START_DATE_PARAM]: getAPIDateFormat(startDate),
                [END_DATE_PARAM]: getAPIDateFormat(endDate),
                [AGGREGATION_PERIOD_PARAM]: aggregationPeriod,
                [AGGREGATION_TYPE_PARAM]: aggregationType,
                [SORT_PARAM]: `${sort},${sortDirection}`,
            },
        });

        let obs = this.http.get<CceUsageMetricMetricUsageHistoryDto>(url, { params, cached: true });

        if (this.localMock) {
            obs = this.http.get<CceUsageMetricMetricUsageHistoryDto>("assets/metric-90-daily-usages.json", { cached: false }).pipe(
                delay(2000),
                /**
                 * Construct a response that has the correct number of items according to the start
                 * and end dates provided.
                 */
                map((result) => {
                    let items: number;
                    switch (aggregationPeriod) {
                        case CceUsageMetricAggregationPeriod.MONTHLY:
                            let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
                            months -= startDate.getMonth();
                            months += endDate.getMonth();
                            items = months <= 0 ? 0 : months;
                            break;
                        case CceUsageMetricAggregationPeriod.DAILY:
                            items = Math.floor((endDate.getTime() - startDate.getTime()) / DAY_IN_MILLISECONDS);
                            break;
                        case CceUsageMetricAggregationPeriod.HOURLY:
                            items = Math.floor((endDate.getTime() - startDate.getTime()) / DAY_IN_MILLISECONDS) * 24;
                            break;
                    }

                    const startIndex = page * pageSize;
                    const endIndex = startIndex + pageSize;

                    return Object.assign({}, result, {
                        total_elements: items,
                        content: result.content.slice(startIndex, endIndex),
                    });
                })
            );
        }

        return obs;
    }

    /** Transform hourly usage for metric hourly usage chart:
     *
     * @param metricHistoryUsagesDto
     */
    transformMetricHistoryUsages(
        metricHistoryUsagesDto: CceUsageMetricMetricUsageHistoryDto
    ): CceUsageMetricMetricUsageHistoryRecordViewModel[] {
        const metaData: CceUsageMetricMetadataDto = metricHistoryUsagesDto.metadata;

        const unit = this.formatUnitDisplayValue(metaData.unit_of_measure_display_name);
        const usagesDto = metricHistoryUsagesDto.content;
        const serviceId = metaData.service_definition_id;
        const metricId = metaData.metric_id;

        // as per angular documentation dates should be dates should be string | number | Date
        // as we are using strings we need to ensure they are in ISO format
        // currently backend api does not return dates string in ISO format
        usagesDto.forEach((usage) => {
            usage.usage_datetime = new Date(usage.usage_datetime).toISOString();
        });

        return usagesDto.map((usageDto) => {
            const usageWithinCommitment = this.getUsageWithinCommitment(usageDto);

            return {
                time: usageDto.usage_datetime,
                commitment: usageDto.commitment,
                usageWithinCommitment: usageWithinCommitment,
                overage: usageDto.overage,
                usage: usageDto.usage,
                unit: unit,
                metricId: metricId,
                serviceId: serviceId,
            };
        });
    }

    private getGeneralUsageForChart(
        usageRecord: CceUsageMetricMetricUsageHistoryRecordDto,
        unit: string,
        serviceId: string,
        metricId: string,
        usageType: string
    ): CceUsageMetricChartDataViewModel {
        const commitment = usageRecord.commitment;
        const time = usageRecord.usage_datetime;
        const usage = {
            serviceId,
            usageType,
            time,
            unit,
            commitment,
            metricId,
            usagePercentOfCommitment: null,
            usage: null,
        };

        return usage;
    }

    private getUsageWithinCommitment(usageRecord: CceUsageMetricMetricUsageHistoryRecordDto): number {
        const commitment = usageRecord.commitment;
        const usage = usageRecord.usage;

        if (commitment === 0) {
            return 0;
        } else if (commitment === null || commitment > usage) {
            return usage;
        } else {
            return commitment;
        }
    }

    private getAvailableUsage(usageRecord: CceUsageMetricMetricUsageHistoryRecordDto): number {
        const commitment = usageRecord.commitment;
        const usage = usageRecord.usage;

        if (commitment > usage) {
            return commitment - usage;
        } else {
            return 0;
        }
    }

    private getUsagePercentageOfCommitment(usage: number, commitment: any): number {
        if (commitment > 0) {
            return Math.round((usage / commitment) * 100);
        } else {
            return 0;
        }
    }

    private getUsagesWithinCommitmentForChart(
        usagesDto: CceUsageMetricMetricUsageHistoryRecordDto[],
        unit: string,
        serviceId: string,
        metricId: string,
        usageType: string
    ): CceUsageMetricChartDataViewModel[] {
        const usagesWithinCommitment = usagesDto.map((usageDto: CceUsageMetricMetricUsageHistoryRecordDto) => {
            const usageWithinCommitment = this.getUsageWithinCommitment(usageDto);
            const usagePercentOfCommitment = this.getUsagePercentageOfCommitment(usageDto.usage, usageDto.commitment);
            let usage = this.getGeneralUsageForChart(usageDto, unit, serviceId, metricId, usageType);
            usage.usagePercentOfCommitment = usagePercentOfCommitment;
            usage.usage = usageWithinCommitment;

            return usage;
        });

        return usagesWithinCommitment;
    }

    private getAvailableUsagesForChart(
        usages: CceUsageMetricMetricUsageHistoryRecordDto[],
        unit: string,
        serviceId: string,
        metricId: string,
        usageType: string
    ): CceUsageMetricChartDataViewModel[] {
        const availableUsages = usages.map((usageDto) => {
            const usageAvailable = this.getAvailableUsage(usageDto);
            const usagePercentOfCommitment = this.getUsagePercentageOfCommitment(usageDto.usage, usageDto.commitment);
            let usage = this.getGeneralUsageForChart(usageDto, unit, serviceId, metricId, usageType);
            usage.usagePercentOfCommitment = usagePercentOfCommitment;
            usage.usage = usageAvailable;

            return usage;
        });
        return availableUsages;
    }

    private getOverageUsagesForChart(
        usages: CceUsageMetricMetricUsageHistoryRecordDto[],
        unit: string,
        serviceId: string,
        metricId: string,
        usageType: string
    ): CceUsageMetricChartDataViewModel[] {
        const overageUsages = usages.map((usageDto) => {
            const overage = usageDto.overage;

            const usagePercentOfCommitment = this.getUsagePercentageOfCommitment(usageDto.usage, usageDto.commitment);

            let usage = this.getGeneralUsageForChart(usageDto, unit, serviceId, metricId, usageType);

            usage.usagePercentOfCommitment = usagePercentOfCommitment;
            usage.usage = overage;

            return usage;
        });

        return overageUsages;
    }

    private getTimePeriodForProjection(projectionPeriod: CceUsageMetricAggregationPeriod) {
        switch (projectionPeriod) {
            case CceUsageMetricAggregationPeriod.DAILY:
                return 86400 * 1000;
            case CceUsageMetricAggregationPeriod.MONTHLY:
                return 86400 * 1000 * 31;
        }
    }

    /*
     * Calculate a dataset with a projection into the future.
     *
     * Use the provided set of usages to perform a linear regression, and use the
     * gradient to calculate some future values, starting the the most recent value.
     */
    private getUsageProjectionForChart(
        usages: CceUsageMetricMetricUsageHistoryRecordDto[],
        unit: string,
        serviceId: string,
        metricId: string,
        usageType: string,
        projectionDistance: number,
        projectionPeriod: CceUsageMetricAggregationPeriod
    ): CceUsageMetricChartDataViewModel[] {
        // Copy the array to avoid mutating it
        usages = Array.from(usages);

        if (usages.length === 0) {
            return [];
        }

        /*
         * Filter the incoming usage values to remove any where there is no data,
         * convert to simple tuples and sort them based on date.
         */
        const actualUsages: number[][] = usages
            .filter((usageDto) => usageDto.usage !== undefined)
            .map((usageDto) => {
                return [new Date(usageDto.usage_datetime).getTime(), usageDto.usage];
            })
            .sort((a: [number, number], b: [number, number]) => {
                return b[0] - a[0];
            });

        // Perform the linear regression
        const regression = weightedLinearRegression(actualUsages);

        const mostRecent = actualUsages[0];
        const usage = mostRecent[1];

        const projectedUsages = [];
        const timePeriod = this.getTimePeriodForProjection(projectionPeriod);

        // Calculate the projected values
        for (let i = 1; i <= projectionDistance; i++) {
            const time = new Date(mostRecent[0] + i * timePeriod);
            const projectedUsage = usage + regression.equation[0] * i * timePeriod;

            if (projectedUsage < 0) {
                continue;
            }

            projectedUsages.push({
                serviceId,
                usageType,
                metricId,
                unit,
                time: new Date(time.toUTCString()).toISOString(),
                usage: projectedUsage,
            });
        }

        return projectedUsages;
    }

    /*
     * Return a dataset that presents a trend given the provided set of actual usages.
     *
     * The projection distance and projection period determine what type of projection
     * to calculate, if any. Currently only daily and monthly projections are possible.
     */
    private getUsageTrendForChart(
        usages: CceUsageMetricMetricUsageHistoryRecordDto[],
        unit: string,
        serviceId: string,
        metricId: string,
        usageType: string,
        weight: boolean,
        projectionDistance: number,
        projectionPeriod: CceUsageMetricAggregationPeriod
    ): CceUsageMetricChartDataViewModel[] {
        // Copy the array to avoid mutating it
        usages = Array.from(usages);

        if (usages.length === 0) {
            return [];
        }

        /*
         * Filter the incoming usage values to remove any where there is no data,
         * convert to simple tuples and sort them based on date.
         */
        const trendUsages: number[][] = usages
            .filter((usageDto) => usageDto.usage !== undefined)
            .map((usageDto) => {
                return [new Date(usageDto.usage_datetime).getTime(), usageDto.usage];
            })
            .sort((a: [number, number], b: [number, number]) => {
                return b[0] - a[0];
            });

        const mostRecent = trendUsages[0];
        const timePeriod = this.getTimePeriodForProjection(projectionPeriod);

        // If weighting is requested, set up a static array of weights from 0 -> 200%
        const weights = weight ? getWeights(0, 2, usages.length, true) : undefined;

        // Perform the least squares linear regression
        const regression = weightedLinearRegression(trendUsages, weights);

        // Calculate the difference between the most recent data point and the trend at that
        // time so we can have the trend line start at the top of the most recent bar.
        // N.B. this may be positive or negative depending on the regression gradient, and
        // in both cases, we need to add/subtract the difference.
        const usageDiffFromLatest = regression.points[0][1] - mostRecent[1];

        // Create an array of the resulting calculated trend values that maps to the existing
        // set of data points.
        const finalUsages = usages
            .filter((usageDto) => usageDto.usage !== undefined)
            .filter((usageDto, index) => {
                if (regression.points[index] && regression.points[index][1] < 0) {
                    return false;
                }
                return true;
            })
            .map((usageDto: CceUsageMetricMetricUsageHistoryRecordDto, index: number) => {
                const usage = this.getGeneralUsageForChart(usageDto, unit, serviceId, metricId, usageType);
                if (regression.points[index]) {
                    usage.usage = regression.points[index][1] - usageDiffFromLatest;
                }
                return usage;
            })
            // Remove any trend values that are negative
            .filter((usageDto) => usageDto.usage > 0);

        // If we have a projection, extend the trend line to the projected point in the future.
        // This part of the trend will be presented as a dashed line instead of a solid line.
        for (let i = 1; i <= projectionDistance; i++) {
            const time = new Date(mostRecent[0] + i * timePeriod);
            const projectedUsage = mostRecent[1] + regression.equation[0] * i * timePeriod;

            if (projectedUsage < 0) {
                continue;
            }

            finalUsages.splice(
                0,
                0,
                Object.assign({}, finalUsages[0], {
                    time: time.toISOString(),
                    usage: projectedUsage,
                })
            );
        }

        return finalUsages;
    }

    public transformMetricUsageHistoryToChartData(
        usagesHistoryDto: CceUsageMetricMetricUsageHistoryDto,
        trendEnabled: boolean = false,
        weightedTrendEnabled: boolean = false,
        projectionEnabled: boolean = false,
        projectionDistance: number = 0,
        projectionPeriod: CceUsageMetricAggregationPeriod = CceUsageMetricAggregationPeriod.DAILY
    ): VmwBarChartData<CceUsageMetricChartDataViewModel> {
        const usages = usagesHistoryDto.content.filter((dto) => dto.usage !== undefined);

        const metaData = usagesHistoryDto.metadata;
        const unit = this.formatUnitDisplayValue(metaData.unit_of_measure_display_name);
        const metricId = metaData.metric_id;
        const serviceId = metaData.service_definition_id;
        const usageType = metaData.display_name;

        // as per angular documentation dates should be dates should be string | number | Date
        // as we are using strings we need to ensure they are in ISO format
        // currently backend api does not return dates string in ISO format
        usages.forEach((usage) => {
            usage.usage_datetime = new Date(usage.usage_datetime).toISOString();
        });

        const usagesWithinCommitment = this.getUsagesWithinCommitmentForChart(usages, unit, serviceId, metricId, usageType);
        const usagesAvailable = this.getAvailableUsagesForChart(usages, unit, serviceId, metricId, usageType);
        const overageUsages = this.getOverageUsagesForChart(usages, unit, serviceId, metricId, usageType);

        let trendUsages, weightedTrendUsages, projectedUsages;

        if (trendEnabled) {
            trendUsages = this.getUsageTrendForChart(
                usages,
                unit,
                serviceId,
                metricId,
                usageType,
                false,
                projectionDistance,
                projectionPeriod
            );
        }

        if (weightedTrendEnabled) {
            weightedTrendUsages = this.getUsageTrendForChart(
                usages,
                unit,
                serviceId,
                metricId,
                usageType,
                true,
                projectionDistance,
                projectionPeriod
            );
        }

        if (projectionEnabled) {
            projectedUsages = this.getUsageProjectionForChart(
                usages,
                unit,
                serviceId,
                metricId,
                usageType,
                projectionDistance,
                projectionPeriod
            );
        }

        return this.usageChartDataTransformer.getChartData(
            usagesWithinCommitment,
            overageUsages,
            usagesAvailable,
            trendUsages,
            weightedTrendUsages,
            true,
            projectedUsages
        );
    }

    private formatUnitDisplayValue(unit: string | null) {
        return unit === null ? "" : unit;
    }
}
