<div class="card">
    <div class="card-block">
        <div class="card-title">
            <csp-service-icon
                *ngIf="insight.serviceId"
                [colorFormat]="VmwSvgIconColorFormat.NEVER"
                [serviceID]="insight.serviceId">
            </csp-service-icon>
            <div class="title-wrapper">
                <div class="title">
                    {{insight.displayName}}
                </div>
                <div class="subtitle">
                    {{insight.serviceName}}
                </div>
            </div>
        </div>
        

        <div class="card-label">
            <span class="label label-info">{{this.translate(insight.type)}}</span>
        </div>
        
        <div class="card-text" *ngIf="insight.type === CceInsightType.BELOW_COMMITMENT"
            [innerHTML]="this.translate('below-commitment-msg', insight.usage | numberFormat: {maxFractionDigits: 2}, insight.unit, insight.serviceName, insight.commitment | numberFormat: {maxFractionDigits: 2}) | safeHtml">
        </div>

        <div class="card-text" *ngIf="insight.type === CceInsightType.OVER_COMMITMENT"
            [innerHTML]="this.translate('over-commitment-msg', insight.usage | numberFormat: {maxFractionDigits: 2}, insight.unit, insight.serviceName, insight.commitment | numberFormat: {maxFractionDigits: 2}) | safeHtml">
        </div>
    </div>
    <div class="card-footer">
        <button class="btn btn-sm btn-link card-btn"
        [routerLink]="['/usage-management', 'services', insight.serviceId, 'metrics', insight.metricId]"
        [queryParams]="{seller: insight.sellerId}">{{this.translate("view-usage")}}</button>
      </div>

</div>
