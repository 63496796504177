/*
 * Perform a weighted-least-squares regression on a provided set of points
 * and optional weights.
 */

export interface WeightLeastSquaresResult {
    /*
     * The resulting regression equation, [gradient, intercept]
     */
    equation: [number, number];

    /*
     * Array of points to plot the line
     */
    points: Array<[number, number]>;

    /*
     * The resulting equation string
     */
    string: string;
}

/**
 *
 * @param data [[x0,y0],[x2,y1],...]
 * @param weights [w0,w1,...]
 * @return {{equation: number[], points: Array, string: string}}
 */
export function weightedLinearRegression(data: number[][], weights?: number[]): WeightLeastSquaresResult {
    const sums = {
        w: 0,
        wx: 0,
        wx2: 0,
        wy: 0,
        wxy: 0,
    };

    // compute the weighted averages
    for (let i = 0; i < data.length; i++) {
        const weight = weights ? weights[i] : 1;
        sums.w += weight;
        sums.wx += data[i][0] * weight;
        sums.wx2 += data[i][0] * data[i][0] * weight;
        sums.wy += data[i][1] * weight;
        sums.wxy += data[i][0] * data[i][1] * weight;
    }

    const denominator = sums.w * sums.wx2 - sums.wx * sums.wx;
    const gradient = (sums.w * sums.wxy - sums.wx * sums.wy) / denominator;
    const intercept = (sums.wy * sums.wx2 - sums.wx * sums.wxy) / denominator;
    const results = [];

    //interpolate result
    for (let i = 0, len = data.length; i < len; i++) {
        const coordinate = [data[i][0], data[i][0] * gradient + intercept];
        results.push(coordinate);
    }

    return {
        equation: [gradient, intercept],
        points: results,
        string: "y = " + (gradient * 100) / 100 + "x + " + Math.round(intercept * 100) / 100,
    };
}

export function getWeights(min: number, max: number, num: number, reverse: boolean) {
    const result = [];
    const slope = max / num;
    for (let i = 0; i < num; i++) {
        result[i] = min + i * slope;
    }
    return reverse ? result.reverse() : result;
}
