import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { VmwTooltipComponent } from "@vmw/ngx-components";
import { ReplaySubject } from "rxjs";
import { CceHorizontalBarChartService } from "../cce-horizontal-bar-chart.service";

export enum CceHorizontalBarChartColors {
    BELOW_THRESHOLD = "#BCC6CC",
    BETWEEN_THRESHOLD = "#EBF1F4",
    ABOVE_THRESHOLD = "#57C8EA",
}

@Component({
    selector: "cce-horizontal-bar-chart-bar",
    styleUrls: ["./cce-horizontal-bar-chart-bar.component.scss"],
    templateUrl: "./cce-horizontal-bar-chart-bar.component.html",
})
export class CceHorizontalBarChartBarComponent {
    @Input() label: string;
    @Input() value: number; // usage
    @Input() threshold: number; // commmitment
    @Input() belowThresholdColor: string = CceHorizontalBarChartColors.BELOW_THRESHOLD;
    @Input() betweenThresholdColor: string = CceHorizontalBarChartColors.BETWEEN_THRESHOLD;
    @Input() aboveThresholdColor: string = CceHorizontalBarChartColors.ABOVE_THRESHOLD;

    @Output() onClick = new EventEmitter<void>();

    @ViewChild("tooltipContainer") tooltipContainer: HTMLDivElement;
    @ViewChild(VmwTooltipComponent) tooltip: VmwTooltipComponent;
    @ViewChild("thresholdContainer", { read: ElementRef }) thresholdContainer: ElementRef;

    public atOrBelowPct: number = 0;
    public valuePct: number = 0;
    public betweenPct: number = 0;
    public abovePct: number = 0;
    public loading = true;
    public tooltipX: number;
    public tooltipY: number;

    private belowThresholdColorTransparent = this.belowThresholdColor + "50";
    private betweenThresholdColorTransparent = this.betweenThresholdColor + "50";
    private aboveThresholdColorTransparent = this.aboveThresholdColor + "50";

    private belowThresholdColorOpaque = this.belowThresholdColor;
    private betweenThresholdColorOpaque = this.betweenThresholdColor;
    private aboveThresholdColorOpaque = this.aboveThresholdColor;

    private isActive = false;
    public isThresholdEnabled: boolean;

    private _ready = new ReplaySubject<void>(1);
    public ready$ = this._ready.asObservable();

    constructor(private service: CceHorizontalBarChartService, private cd: ChangeDetectorRef, public el: ElementRef) {}

    ngAfterViewInit() {
        this.service.registrationComplete$.subscribe(() => {
            this.isThresholdEnabled = this.service.isThresholdEnabled;
            this.calculateBarSegments();
        });

        this.service.activeBar$.subscribe((active) => {
            switch (active) {
                case true:
                    if (!this.isActive) {
                        this.removeOpacity();
                    }
                    break;

                default:
                    this.addOpacity();
                    break;
            }
        });
    }

    private calculateBarSegments() {
        this.atOrBelowPct = this.calculateAtOrBelowPct();
        this.valuePct = this.calculateValuePct();
        this.betweenPct = this.calculateBetweenPct();
        this.abovePct = this.calculateAbovePct();
        this.cd.detectChanges();
        this._ready.next();
    }

    private calculateAtOrBelowPct(): number {
        let atOrBelowPct = 100;
        if (this.isThresholdEnabled) {
            if (this.service.max) {
                atOrBelowPct = (atOrBelowPct / this.service.max) * 100;
            }
            return atOrBelowPct < 1 ? 1 : atOrBelowPct;
        }

        // when there is overage
        if (this.value > this.threshold) {
            atOrBelowPct = 100 / (this.value / this.threshold);
            return atOrBelowPct < 1 ? 1 : atOrBelowPct;
        }

        return atOrBelowPct;
    }

    // commitment
    private calculateValuePct(): number {
        return Math.min((this.value / this.threshold) * 100, 100);
    }

    // available
    private calculateBetweenPct(): number {
        return 100 - this.valuePct;
    }

    // overage
    private calculateAbovePct(): number {
        if (this.value <= this.threshold) {
            return 0;
        }

        if (this.isThresholdEnabled) {
            const overage = (this.value / this.threshold) * 100;
            const maxOverage = this.service.max;

            return overage === maxOverage ? 100 : (overage / maxOverage) * 100;
        }

        return 100;
    }

    public getThresholdX(): number {
        const el = this.thresholdContainer?.nativeElement;
        return el.offsetLeft + el.offsetWidth;
    }

    @HostListener("mouseover", ["$event"])
    public onMouseOver(event: MouseEvent) {
        this.isActive = true;
        this.service.activeBar$.next(this.isActive);
        this.openToolTip(event);
    }

    @HostListener("mouseout")
    public onMouseOut() {
        this.isActive = false;
        this.service.activeBar$.next(this.isActive);
        this.tooltip.showTooltip = false;
    }

    @HostListener("click", ["$event"])
    public onMouseClick(event: MouseEvent) {
        if ((event.target as HTMLElement).className === "bar-bg") {
            this.onClick.emit();
        }
    }

    private openToolTip(event?: MouseEvent) {
        const box = (event.currentTarget as HTMLElement).getBoundingClientRect();
        this.tooltip.showTooltip = true;

        this.tooltipX = event.clientX;
        this.tooltipY = box.y;
    }

    private addOpacity() {
        this.belowThresholdColor = this.belowThresholdColorOpaque;
        this.betweenThresholdColor = this.betweenThresholdColorOpaque;
        this.aboveThresholdColor = this.aboveThresholdColorOpaque;
    }

    private removeOpacity() {
        this.belowThresholdColor = this.belowThresholdColorTransparent;
        this.betweenThresholdColor = this.betweenThresholdColorTransparent;
        this.aboveThresholdColor = this.aboveThresholdColorTransparent;
    }

    public get getBelowThresholdColor() {
        return this.belowThresholdColor ? this.belowThresholdColor : CceHorizontalBarChartColors.BELOW_THRESHOLD;
    }

    public get getBetweenThresholdColor() {
        return this.betweenThresholdColor ? this.betweenThresholdColor : CceHorizontalBarChartColors.BETWEEN_THRESHOLD;
    }

    public get getAboveThresholdColor() {
        return this.aboveThresholdColor ? this.aboveThresholdColor : CceHorizontalBarChartColors.ABOVE_THRESHOLD;
    }

    public get atOrBelowContainerBorder() {
        return this.isThresholdEnabled ? "2px dashed #000000" : "";
    }

    public get barLabelonLeft() {
        return this.atOrBelowPct >= 20 ? true : false;
    }
}
