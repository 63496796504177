import { Component, Input } from "@angular/core";
import { CspApiService } from "@vmw/csp-ngx-components";
import { I18nService } from "@vmw/ngx-vip";
import { CceUsageMetricUsageStatus } from "../usage-management.model";

export enum CceConsumptionBarColors {
    COMMITMENT_AVAILABLE_COLOR = "#D9D9D9",
    USAGE_AT_OR_BELOW_COMMITMENT_COLOR = "#32465E",
    USAGE_ON_DEMAND_OR_OVER_COMMITMENT_COLOR = "#57C8EA",
}

@Component({
    selector: "cce-consumption-bar",
    templateUrl: "cce-consumption-bar.component.html",
    styleUrls: ["cce-consumption-bar.component.scss"],
})
export class CceConsumptionBarComponent {
    @Input() usage: number;
    @Input() commitment: number;
    @Input() unit: string;
    @Input() status: CceUsageMetricUsageStatus;

    get usagePercentage(): number {
        if (this.usage >= this.commitment) {
            return 100;
        }
        return (this.usage / this.commitment) * 100;
    }
    get commitmentPercentage(): number {
        if (this.usage >= this.commitment) {
            return 0;
        }
        return ((this.commitment - this.usage) / this.commitment) * 100;
    }

    get displayValue() {
        const FORMAT_OPTIONS = {
            maxFractionDigits: 2,
        };
        return (
            this.i18n.formatNumber(this.usage, this.cspApiService.currentLocale, FORMAT_OPTIONS) +
            "/" +
            this.i18n.formatNumber(this.commitment, this.cspApiService.currentLocale, FORMAT_OPTIONS) +
            " " +
            this.unit
        );
    }

    public commitmentColor = CceConsumptionBarColors.COMMITMENT_AVAILABLE_COLOR;

    get usageColor() {
        return this.status === CceUsageMetricUsageStatus.ON_DEMAND || this.usage > this.commitment
            ? CceConsumptionBarColors.USAGE_ON_DEMAND_OR_OVER_COMMITMENT_COLOR
            : CceConsumptionBarColors.USAGE_AT_OR_BELOW_COMMITMENT_COLOR;
    }

    constructor(private i18n: I18nService, private cspApiService: CspApiService) {}
}
