<ul class="list-inline">
    <li class="list-inline-item">
        <a [routerLink]="['../../../../']">{{translate('usage-management')}}</a>
    </li>
    <li class="list-inline-item">
        <cds-icon shape="angle" direction="right"></cds-icon>
    </li>
    <li class="list-inline-item">
        {{translate('usage-detail')}}
    </li>
</ul>

<ng-container *ngIf="!loadingOverview && !loadingError; else spinnerOrError">
    <div class="metric-detail-title-container">
        <h1>
            {{metric.usageType}} |
            <csp-service-icon *ngIf="service" [size]="32" [serviceID]="service.id"></csp-service-icon>
            <span class="metric-detail-title">{{service?.displayName}}</span>
        </h1>

        <form clrForm clrLayout="horizontal" *ngIf="sellers.length > 1" [formGroup]="sellerSelectionForm" class="seller-dropdown">
            <clr-select-container>
                <label class="clr-col-md-6">{{translate('sold-by')}}</label>
                <select class="clr-col-md-6" formControlName="seller" data-test-id="sellerSelect" clrSelect>
                    <option *ngFor="let seller of sellers" [ngValue]="seller">{{getNameForSeller(seller) | async}}</option>
                </select>
            </clr-select-container>
        </form>
    </div>

    <clr-alert *ngIf="!vSphere" clrAlertType="info" [clrAlertClosable]="false">
        <clr-alert-item>
            {{translate('no-overage')}}
        </clr-alert-item>
    </clr-alert>

    <clr-alert *ngIf="false" clrAlertType="info" [clrAlertClosable]="false">
        <clr-alert-item>
            <span class="tooltip-content">{{translate('overage-will-be-charged-with-vmware')}}</span>
        </clr-alert-item>
    </clr-alert>

    <clr-alert *ngIf="showDelayedDataAlert" clrAlertType="info" [clrAlertClosable]="false">
        <clr-alert-item>
            <span class="tooltip-content">{{translate('data-for-this-service-is-delayed')}}</span>
        </clr-alert-item>
    </clr-alert>

    <div class="card metric-detail-card">
        <div class="card-header">
            <h2 class="cce-card-heading">{{translate('current-usage')}}</h2>
            <h3 class="cce-card-subheading" data-test-id="lastReported">
                {{translate('last-reported-datetime', currentMetricUsage.last_updated | dateFormat:translate('medium-minus-seconds-pattern'))}}
            </h3>
        </div>

        <div class="clr-row card-body clr-col-xl-8 metric-detail-card-text">
            <div class="clr-col-3 metric-detail-card-item">
                <div>{{translate('total-usage')}}</div>
                <div class="value" data-test-id="totalUsage">{{currentMetricUsage.usage | numberFormat: { maxFractionDigits: 2 } }}</div>
                <div>{{metric.unit}}</div>
            </div>

            <div class="clr-col-3 metric-detail-card-item">
                <div>{{translate('commitment')}}</div>
                <div class="value" data-test-id="commitment">
                    {{currentMetricUsage.commitment || currentMetricUsage.commitment === 0 ?
                        (currentMetricUsage.commitment | numberFormat: { maxFractionDigits: 2 }) : '--'}}
                </div>
                <div>{{metric.unit}}</div>
            </div>

            <div class="clr-col-3 metric-detail-card-item">
                <div>{{translate('overage')}}</div>
                <div class="value" data-test-id="overage">
                    {{currentMetricUsage.overage || currentMetricUsage.overage === 0 ?
                        (currentMetricUsage.overage | numberFormat: { maxFractionDigits: 2 }) : '--'}}
                </div>
                <div>{{metric.unit}}</div>
            </div>

            <div class="clr-col-3 metric-detail-card-item">
                <div>{{translate('seller')}}</div>
                <div class="value">
                    <vmw-svg-icon *ngIf="cspSeller; else sellerSpinner"
                        forcedColor="white"
                        fallback="store"
                        [iconSize]="48"
                        [svgIcon]="cspSeller.svgIcon">
                    </vmw-svg-icon>
                    <ng-template #sellerSpinner>
                        <ng-container *ngIf="currentSeller !== SELLER_MULTIPLE; else multiple">
                            <cds-icon size="64" shape="ellipsis-horizontal"></cds-icon>
                        </ng-container>
                        <ng-template #multiple>
                            {{translate('multiple')}}
                        </ng-template>
                    </ng-template>
                </div>
                <div *ngIf="cspSeller; else multipleSeller">{{cspSeller.name}}</div>
                <ng-template #multipleSeller>
                    <div *ngIf="currentSeller === SELLER_MULTIPLE">{{translate('multiple')}}</div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="card usage-history-card">
        <div class="card-body">
            <div class="usage-history-card-header">
                <h2 class="cce-card-heading">{{translate('usage-history')}}</h2>

                <div class="chart-controls">
                    <form class="projection-form" clrForm clrLayout="vertical"
                            *ngIf="trendlineEnabled && showTrendToggle" [formGroup]="trendForm">
                        <clr-toggle-container>
                            <label>{{translate('trend')}}</label>

                            <clr-toggle-wrapper class="trend-toggle">
                                <input type="checkbox" clrToggle [value]="true" formControlName="showTrend" />
                            </clr-toggle-wrapper>
                        </clr-toggle-container>
                    </form>

                    <form class="projection-form" clrForm clrLayout="vertical" *ngIf="showProjection" [formGroup]="projectionForm">
                        <clr-select-container>
                            <label class="form-label-with-button">
                                {{translate('projection')}}
                                <button class="help-btn"
                                        type="button"
                                        [attr.aria-label]="translate('open-help-projection')"
                                        (click)="openHelp('how-is-the-projection-calculated')">
                                    <cds-icon size="14" shape="help"></cds-icon>
                                </button>
                            </label>

                            <select formControlName="projection" data-test-id="projection-select" clrSelect>
                                <option [ngValue]="0">{{translate('no-projection')}}</option>
                                <option *ngFor="let projection of availableProjections" [ngValue]="projection">
                                    {{aggregationPeriod === CceUsageMetricAggregationPeriod.DAILY ?
                                        translate('projection-days', projection) :
                                        translate('projection-months', projection)}}
                                </option>
                            </select>

                        </clr-select-container>
                    </form>

                    <div class="clr-form-control">
                        <label class="clr-control-label">
                            {{translate('date-range')}}
                        </label>

                        <div class="clr-control-container">
                            <cce-date-range-picker
                                [hidden]="hideDateRangePicker()"
                                [maxDate]="maxDate"
                                [minDate]="minDate">
                            </cce-date-range-picker>

                            <div class="date-range-zoomed" *ngIf="showFakeDateRangePicker()">
                                <input class="clr-input" type="text" disabled
                                    value="{{zoomDetails.x.min | dateFormat:'shortDate'}} - {{zoomDetails.x.max | dateFormat:'shortDate'}}"/>
                                <cds-icon size="15" shape="calendar"></cds-icon>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="showTopChart">
                <ng-container [ngSwitch]="aggregationPeriod">
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.DAILY">
                        <ng-container *ngTemplateOutlet="topChartDaily"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.MONTHLY">
                        <ng-container *ngTemplateOutlet="topChartMonthly"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container [ngSwitch]="aggregationPeriod">
                <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.MONTHLY">
                    <ng-template *ngTemplateOutlet="bottomChartMonthly"></ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.DAILY">
                    <ng-template *ngTemplateOutlet="bottomChartDaily"></ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.HOURLY">
                    <ng-template *ngTemplateOutlet="bottomChartHourly"></ng-template>
                </ng-container>
            </ng-container>

            <div class="action-bar">
                <cce-download-csv-button
                    [orgId]="orgId"
                    [serviceId]="serviceId"
                    [metricId]="metricId"
                    [currentSeller]="currentSeller"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [aggregationPeriod]="aggregationPeriod"
                    [totalItems]="+pagination?.totalItems"
                >
                </cce-download-csv-button>
            </div>

            <ng-container *ngTemplateOutlet="mainDatagrid"></ng-container>
        </div>
    </div>
</ng-container>

<ng-template #spinnerOrError>
    <div *ngIf="loadingOverview" class="spinner-container eye-level">
        <div class="spinner" data-test-id="spinner"></div>
    </div>

    <vmw-empty-state-placeholder
        class="eye-level"
        *ngIf="loadingError"
        icon="ban"
        title="{{translate('error-title')}}"
        description="{{translate('error-description', metricId)}}">
    </vmw-empty-state-placeholder>
</ng-template>

<ng-template #topChartMonthly>
    <h3 class="cce-card-subheading usage-history-chart-title">
        {{translate('max-monthly-usage')}}
    </h3>

    <vmw-bar-chart
            #topChart
            (onBarClick)="handleTopBarClick($event)"
            (onBarHover)="setHoveredItem($event)"
            (onZoom)="onZoom($event)"
            [focusedBar]="selectedUsage?.time"
            [loading]="loadingTopChart"
            [blurred]="tooMuchData || noTopData"
            [options]="topChartOptionsMonthly"
            [data]="topChartData">
        <!-- what does too much data look like for monthly? -->
        <vmw-empty-state-placeholder
            *ngIf="tooMuchData"
            icon="ban"
            title="{{translate('date-range-exceeds-maximum-title')}}"
            description="{{translate('date-range-exceeds-maximum-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-empty-state-placeholder
            *ngIf="noTopData && !tooMuchData"
            icon="ban"
            title="{{translate('no-data-found')}}"
            description="{{translate('no-data-found-monthly-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-tooltip [tooltipWidth]="375" [focusTriggerOnHide]="false">
            <vmw-tooltip-content *ngIf="hoveredItem">
                <cce-usage-tooltip 
                    [usage]="hoveredItem.usage"
                    [commitment]="hoveredItem.commitment"
                    [unit]="hoveredItem.unit"
                    [projection]="hoveredItem.projection"
                    [title]="tooltipTitle(translate('full-month-year-pattern'))">
                </cce-usage-tooltip>
            </vmw-tooltip-content>
        </vmw-tooltip>
    </vmw-bar-chart>
</ng-template>

<ng-template #topChartDaily>
    <h3 class="cce-card-subheading usage-history-chart-title">
        {{translate('max-daily-usage')}}
    </h3>
    <vmw-bar-chart
            #topChart
            (onBarClick)="handleTopBarClick($event)"
            (onBarHover)="setHoveredItem($event)"
            (onZoom)="onZoom($event)"
            [focusedBar]="selectedUsage?.time"
            [loading]="loadingTopChart"
            [blurred]="tooMuchData || noTopData"
            [options]="topChartOptionsDaily"
            [data]="topChartData">
        <vmw-empty-state-placeholder
            *ngIf="tooMuchData"
            icon="ban"
            title="{{translate('date-range-exceeds-maximum-title')}}"
            description="{{translate('date-range-exceeds-maximum-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-empty-state-placeholder
            *ngIf="noTopData && !tooMuchData"
            icon="ban"
            title="{{translate('no-data-found')}}"
            description="{{translate('no-data-found-daily-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-tooltip [tooltipWidth]="375" [focusTriggerOnHide]="false">
            <vmw-tooltip-content *ngIf="hoveredItem">
                <cce-usage-tooltip 
                    [usage]="hoveredItem.usage"
                    [commitment]="hoveredItem.commitment"
                    [unit]="hoveredItem.unit"
                    [projection]="hoveredItem.projection"
                    [title]="tooltipTitle('fullDate')">
                </cce-usage-tooltip>
            </vmw-tooltip-content>
        </vmw-tooltip>
    </vmw-bar-chart>
</ng-template>

<ng-template #bottomChartMonthly>
    <h3 class="cce-card-subheading" *ngIf="currentDate">
        {{translate("daily-usage-for", currentDate | dateFormat:translate('full-month-year-pattern'))}}
    </h3>
    <vmw-bar-chart
        #bottomChart
        [loading]="loadingBottomChart"
        (onBarHover)="setHoveredItem($event)"
        (onZoom)="onZoom($event)"
        [blurred]="noBottomData"
        [options]="bottomChartOptionsDaily"
        [data]="bottomChartData">
        <vmw-empty-state-placeholder
            *ngIf="noBottomData"
            icon="ban"
            title="{{translate('no-data-found')}}"
            description="{{translate('no-data-found-daily-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-tooltip [tooltipWidth]="350" [focusTriggerOnHide]="false">
            <vmw-tooltip-content *ngIf="hoveredItem">
                <cce-usage-tooltip
                    [usage]="hoveredItem.usage"
                    [commitment]="hoveredItem.commitment"
                    [unit]="hoveredItem.unit"
                    [projection]="hoveredItem.projection"
                    [title]="tooltipTitle('mediumDate')">
                </cce-usage-tooltip>
            </vmw-tooltip-content>
        </vmw-tooltip>
    </vmw-bar-chart>
</ng-template>

<ng-template #bottomChartDaily>
    <h3 class="cce-card-subheading" *ngIf="currentDate">
        {{translate('hourly-usage-for', currentDate | dateFormat:'longDate')}}
    </h3>
    <vmw-bar-chart
            #bottomChart
            [loading]="loadingBottomChart"
            (onBarHover)="setHoveredItem($event)"
            (onZoom)="onZoom($event)"
            [blurred]="noBottomData"
            [options]="bottomChartOptionsHourly"
            [data]="bottomChartData">
        <vmw-empty-state-placeholder
            *ngIf="noBottomData"
            icon="ban"
            title="{{translate('no-data-found')}}"
            description="{{translate('no-data-found-hourly-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-tooltip [tooltipWidth]="350" [focusTriggerOnHide]="false">
            <vmw-tooltip-content *ngIf="hoveredItem">
                <cce-usage-tooltip
                    [usage]="hoveredItem.usage"
                    [commitment]="hoveredItem.commitment"
                    [unit]="hoveredItem.unit"
                    [projection]="hoveredItem.projection"
                    [title]="tooltipTitle(translate('medium-minus-seconds-pattern'))">
                </cce-usage-tooltip>
            </vmw-tooltip-content>
        </vmw-tooltip>
    </vmw-bar-chart>
</ng-template>

<ng-template #bottomChartHourly>
    <h3 class="cce-card-subheading">
        {{translate('hourly-usage-for-range', startDate | dateFormat:'longDate', currentDate | dateFormat:'longDate')}}
    </h3>
    <vmw-bar-chart
            #bottomChart
            [loading]="loadingBottomChart"
            (onBarHover)="setHoveredItem($event)"
            (onZoom)="onZoom($event)"
            [blurred]="noBottomData"
            [options]="bottomChartOptionsHourly"
            [data]="bottomChartData">
        <vmw-empty-state-placeholder
            *ngIf="noBottomData"
            icon="ban"
            title="{{translate('no-data-found')}}"
            description="{{translate('no-data-found-hourly-description')}}">
        </vmw-empty-state-placeholder>

        <vmw-tooltip [tooltipWidth]="350" [focusTriggerOnHide]="false">
            <vmw-tooltip-content *ngIf="hoveredItem">
                <cce-usage-tooltip
                    [usage]="hoveredItem.usage"
                    [commitment]="hoveredItem.commitment"
                    [unit]="hoveredItem.unit"
                    [projection]="hoveredItem.projection"
                    [title]="tooltipTitle(translate('medium-minus-seconds-pattern'))">
                </cce-usage-tooltip>
            </vmw-tooltip-content>
        </vmw-tooltip>
    </vmw-bar-chart>
</ng-template>

<ng-template #mainDatagrid>
    <clr-datagrid
        [class.hide-detail-pane]="aggregationPeriod === CceUsageMetricAggregationPeriod.HOURLY"
        data-test-id="datagrid"
        [clrDgLoading]="loadingGrid"
        (clrDgRefresh)="loadUsagesForDatagrid($event)">
        <clr-dg-column [clrDgField]="'usage_date_time'" [clrDgSortOrder]="ClrDatagridSortOrder.ASC">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
                {{translate('date')}}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column>
            <ng-container *clrDgHideableColumn="{ hidden: false }">
                {{translate('total-usage')}}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'commit_quantity'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
                {{translate('commitment')}}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column>
            <ng-container *clrDgHideableColumn="{ hidden: false }">
                {{translate('overage-usage-max')}}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column>
            <ng-container *clrDgHideableColumn="{ hidden: false }">
                {{translate('unit')}}
            </ng-container>
        </clr-dg-column>
        <clr-dg-column *ngIf="enableCurrentConsumptionBarFeatureFlag$ | async">
            <ng-container *clrDgHideableColumn="{ hidden: !enableConsumptionBar }">
                {{translate('consumption')}}
            </ng-container>
        </clr-dg-column>

        <clr-dg-placeholder data-test-id="datagridPlaceholder">
            <vmw-empty-state-placeholder
                    data-test-id="grid-empty-state-placeholder"
                    icon="ban"
                    title="{{translate('no-data-found')}}"
                    description="{{translate('no-data-found-description')}}">
            </vmw-empty-state-placeholder>
        </clr-dg-placeholder>

        <clr-dg-row *ngFor="let usage of usagesCurrentPage; let rowIndex = index;"
                    [clrDgItem]="usage">
            <clr-dg-cell>
                <ng-container [ngSwitch]="aggregationPeriod">
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.DAILY">
                        {{usage.time | dateFormat:'fullDate'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.HOURLY">
                        {{usage.time | dateFormat:translate('medium-datetime-minus-seconds-pattern')}}
                    </ng-container>
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.MONTHLY">
                        {{usage.time | dateFormat:translate('full-month-year-pattern')}}
                    </ng-container>
                    <vmw-tooltip *ngIf="usage?.statement">
                        <button
                            class="cce-download-activity-statement-button"
                            (click)="downloadActivityStatement(usage.statement, rowIndex)"
                            [disabled]="rowIndex == activityStatementDownloadingIndex"
                            [clrLoading]="rowIndex == activityStatementDownloadingIndex"
                            [attr.aria-label]="translate('download-activity-statement-for', usage.statement.statementPeriodStartDate | dateFormat: DATE_FORMAT_ACTIVITY_STATEMENT)">
                            <cds-icon *ngIf="rowIndex != activityStatementDownloadingIndex" shape="pdf-file" size="18"></cds-icon>
                        </button>
                        <vmw-tooltip-content>
                            {{translate('download-activity-statement-for', usage.statement.statementPeriodStartDate | dateFormat: DATE_FORMAT_ACTIVITY_STATEMENT)}}
                        </vmw-tooltip-content>
                    </vmw-tooltip>
                </ng-container>
            </clr-dg-cell>
            <clr-dg-cell>{{usage.usage | numberFormat: { maxFractionDigits: 2 } }}</clr-dg-cell>
            <clr-dg-cell>{{usage.commitment || usage.commitment === 0 ? (usage.commitment | numberFormat: { maxFractionDigits: 2 }) : '--'}}</clr-dg-cell>
            <clr-dg-cell>{{usage.overage || usage.overage === 0 ? (usage.overage | numberFormat: { maxFractionDigits: 2 }) : '--'}}</clr-dg-cell>
            <clr-dg-cell>{{usage.unit}}</clr-dg-cell>
            <clr-dg-cell *ngIf="enableCurrentConsumptionBarFeatureFlag$ | async">
                <cce-consumption-bar [usage]="usage.usage" [commitment]="usage.commitment" [unit]="usage.unit" [status]="currentMetricUsage.status"></cce-consumption-bar>
            </clr-dg-cell>
        </clr-dg-row>

        <ng-template [(clrIfDetail)]="selectedUsage" (clrIfDetailChange)="onGridUsageSelected($event)" let-selectedUsage>
            <clr-dg-detail data-test-id="datagridDetail">
                <clr-dg-detail-header>
                    <ng-container [ngSwitch]="aggregationPeriod">
                        <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.MONTHLY">
                            {{translate('daily-usage-for', selectedUsage.time | dateFormat:translate('full-month-year-pattern'))}}
                        </ng-container>
                        <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.DAILY">
                            {{translate('hourly-usage-for', selectedUsage.time | dateFormat:'longDate')}}
                        </ng-container>
                    </ng-container>
                </clr-dg-detail-header>

                <clr-dg-detail-body>
                    <ng-container *ngTemplateOutlet="detailedDatagrid"></ng-container>
                </clr-dg-detail-body>
            </clr-dg-detail>
        </ng-template>

        <clr-dg-footer>
            <clr-dg-pagination #dailyPagination>
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
                    {{translate('usage-items-per-page')}}
                </clr-dg-page-size>
                {{ translate('pagination-footer', dailyPagination.firstItem + 1, dailyPagination.lastItem + 1, dailyPagination.totalItems) }}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</ng-template>

<ng-template #detailedDatagrid>
    <clr-datagrid [clrDgLoading]="loadingHourly" (clrDgRefresh)="loadUsagesForDetailedDatagrid($event)">
        <clr-dg-column [clrDgField]="'usage_date_time'">
            {{translate('time')}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'usage_quantity'">
            {{translate('total-usage')}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'commit_quantity'">
            {{translate('commitment')}}
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'overage_quantity'">
            {{translate('overage-usage')}}
        </clr-dg-column>
        <clr-dg-column>
            {{translate('unit')}}
        </clr-dg-column>

        <clr-dg-placeholder>
            <vmw-empty-state-placeholder
                    icon="ban"
                    title="{{translate('no-data-found')}}"
                    description="{{aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY ? translate('no-data-found-daily-description') : translate('no-data-found-hourly-description')}}">
            </vmw-empty-state-placeholder>
        </clr-dg-placeholder>

        <clr-dg-row
                *ngFor="let hourlyUsage of hourlyUsages;"
                [clrDgItem]="hourlyUsage">
            <clr-dg-cell>{{aggregationPeriod === CceUsageMetricAggregationPeriod.MONTHLY ? (hourlyUsage.time | dateFormat:'mediumDate') : (hourlyUsage.time | dateFormat:'shortTime') }}</clr-dg-cell>
            <clr-dg-cell>{{hourlyUsage.usage | numberFormat: { maxFractionDigits: 2 } }}</clr-dg-cell>
            <clr-dg-cell>{{hourlyUsage.commitment || hourlyUsage.commitment === 0 ? (hourlyUsage.commitment | numberFormat: { maxFractionDigits: 2 }) : '--'}}</clr-dg-cell>
            <clr-dg-cell>{{hourlyUsage.overage || hourlyUsage.overage === 0 ? (hourlyUsage.overage | numberFormat: { maxFractionDigits: 2 }) : '--'}}</clr-dg-cell>
            <clr-dg-cell>{{hourlyUsage.unit}}</clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
</ng-template>
