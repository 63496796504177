import { Injectable } from "@angular/core";

import { LocalizedComponent, L10nService } from "@vmw/ngx-vip";
import { VmwClarityThemeService, VmwClarityTheme, Mixin } from "@vmw/ngx-utils";

import { TranslateFunction } from "@cce/core";

import { CceUsageMetricChartDataViewModel } from "./usage-management.model";

import { ENGLISH } from "./usage-chart.transformer.l10n";
import { VmwBarChartData } from "@vmw/ngx-charts/bar-chart/bar-chart.model";

export enum CceUsageChartDataType {
    Commitment = "commitment",
    UsageWithinCommitment = "within-commitment",
    UsageAvailable = "available",
    Overage = "overage",
    Trend = "trend",
    WeightedTrend = "weighted-trend",
    Projection = "projection",
}

const CHART_OVERAGE_COLOR = "#57C8EA";
const CHART_WITHIN_COMMITMENT_COLOR = "#BCC6CC";
const CHART_AVAILABLE_COMMITMENT_COLOR = "#EBF1F4";
const CHART_COMMIT_COLOR = "#666666";
const CHART_TREND_COLOR = "#763AB0";
const CHART_WEIGHTED_TREND_COLOR = "#0000FF";
const CHART_PROJECTION_LIGHT_COLOR = "#e5d7f3";
const CHART_PROJECTION_DARK_COLOR = "#e5d7f3";

@Injectable({
    providedIn: "root",
})
@Mixin([LocalizedComponent], {
    L10nKey: "usage-chart-transformer",
    L10nSourceMap: ENGLISH,
})
export class CceUsageChartDataTransformer {
    public translate: TranslateFunction<typeof ENGLISH>;

    constructor(public l10nService: L10nService, private themeService: VmwClarityThemeService) {}

    /**
     * Return an object that can be passed directly to the CCE bar chart designed to show
     * stacked bars for usage within commitment, overage and available.
     */
    public getChartData(
        within,
        overage,
        available,
        trend = undefined,
        weightedTrend = undefined,
        showCommitment = true,
        projection = undefined
    ): VmwBarChartData<CceUsageMetricChartDataViewModel> {
        const result = {
            datasets: [],
        };

        const commitment = {
            type: "line",
            id: CceUsageChartDataType.Commitment,
            label: this.translate("commitment"),
            data: within.map((item) => {
                return Object.assign({}, item, {
                    time: item.time,
                    usage: item.commitment,
                });
            }),
            borderColor: "#000000",
            backgroundColor: "#000000",
            pointRadius: 0,
            borderWidth: 2,
            pointStyle: "dash",
            borderDash: [5, 5],
            stack: "commitment",
            hidden: !showCommitment,
        };

        if (showCommitment && commitment.data.find((item) => item.usage > 0)) {
            result.datasets.push(commitment);
        }

        if (projection && projection.length) {
            result.datasets.push({
                id: CceUsageChartDataType.Projection,
                label: this.translate("projection"),
                data: projection,
                backgroundColor:
                    this.themeService.theme === VmwClarityTheme.Light ? CHART_PROJECTION_LIGHT_COLOR : CHART_PROJECTION_DARK_COLOR,
                barPercentage: 0.6,
                stack: "usage",
                categoryPercentage: 1.0,
            });
        }

        result.datasets.push({
            label: this.translate("usage-within-commitment"),
            id: CceUsageChartDataType.UsageWithinCommitment,
            data: within,
            backgroundColor: CHART_WITHIN_COMMITMENT_COLOR,
            barPercentage: 0.6,
            categoryPercentage: 1.0,
            stack: "usage",
            datalabels: {
                color: "black",
            },
        });

        result.datasets.push({
            label: this.translate("overage"),
            id: CceUsageChartDataType.Overage,
            data: overage,
            backgroundColor: CHART_OVERAGE_COLOR,
            barPercentage: 0.6,
            categoryPercentage: 1.0,
            stack: "usage",
            datalabels: {
                color: "white",
                anchor: "center",
                align: "center",
            },
        });

        result.datasets.push({
            label: this.translate("available"),
            id: CceUsageChartDataType.UsageAvailable,
            data: available,
            backgroundColor: CHART_AVAILABLE_COMMITMENT_COLOR,
            barPercentage: 0.6,
            categoryPercentage: 1.0,
            stack: "usage",
            datalabels: {
                color: "black",
                anchor: "center",
                align: "center",
            },
        });

        if (weightedTrend) {
            result.datasets.splice(0, 0, {
                type: "line",
                id: CceUsageChartDataType.WeightedTrend,
                label: this.translate("weighted-trend"),
                data: weightedTrend,
                pointRadius: 0,
                backgroundColor: CHART_WEIGHTED_TREND_COLOR,
                borderColor: CHART_WEIGHTED_TREND_COLOR,
                stack: "weighted-trend",
            } as any);
        }

        if (trend && within.length > 0) {
            result.datasets.splice(0, 0, {
                type: "line",
                id: CceUsageChartDataType.Trend,
                label: this.translate("trend"),
                data: trend,
                pointRadius: 0,
                backgroundColor: CHART_TREND_COLOR,
                borderColor: CHART_TREND_COLOR,
                stack: "trend",
            } as any);
        }

        return result;
    }
}
