import { Route } from '@angular/router';

import { RequestRoleErrorComponent } from './request-role-error/request-role-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const errorsRoutes: Route[] = [
    {
        path: "errors/request-role-error",
        component: RequestRoleErrorComponent
    },
    // this must always be the last route
    {
        path: "**",
        component: PageNotFoundComponent
    }
];
