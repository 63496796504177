import { Injectable } from "@angular/core";

import { Observable, forkJoin, map } from "rxjs";
import { VmwHttpClient } from "@vmw/ngx-utils/caching-http-client";

import { BUDGETS_URL, EDIT_BUDGET_URL } from "./usage-management-budgets.const";
import {
    CceBudget,
    CceEditBudgetBody,
    CceUsageManagementBudgetDto,
    CceUsageManagementBudgetView,
    CceUsageManagementBudgetsDto,
    CceUsageManagementBudgetsView,
} from "./usage-management-budgets.model";
import { CspApiService, Service } from "@vmw/csp-ngx-components";

@Injectable()
export class UsageManagementBudgetsService {
    constructor(private http: VmwHttpClient, private cspApiService: CspApiService) {}

    private get localMock(): boolean {
        return window.localStorage.getItem("usage-mock-local") === "true";
    }

    public getBudgets(orgId: string): Observable<CceUsageManagementBudgetsView> {
        const budgetsUrl = this.localMock ? "assets/budgets-list.mock.json" : BUDGETS_URL.replace("{orgId}", orgId);

        return forkJoin({
            budgetsDto: this.http.get<CceUsageManagementBudgetsDto>(budgetsUrl, { cached: true }),
            services: this.cspApiService.getOrgServices(orgId),
        }).pipe(
            map(({ budgetsDto, services }) => {
                return {
                    budgets: budgetsDto.content.map((budget) => this.transformBudget(budget, services)),
                    totalItems: budgetsDto.total_elements,
                };
            })
        );
    }

    private transformBudget(budget: CceUsageManagementBudgetDto, services: Service[]): CceUsageManagementBudgetView {
        return {
            id: budget.id,
            usageType: budget.metric_display_name,
            service: services.find((service) => service.id === budget.service_definition_id)?.displayName || budget.service_definition_id,
            budgetType: budget.budget_type,
            amount: budget.budget_value,
            unit: budget.unit_of_measure_display_name,
            lastUpdatedAt: budget.last_updated_at,
            lastUpdatedBy: budget.last_updated_by,
        };
    }

    public getBudget(orgId: string, budgetId: string): Observable<CceUsageManagementBudgetView> {
        const budgetsUrl = this.localMock
            ? "assets/budget-edit.mock.json"
            : EDIT_BUDGET_URL.replace("{orgId}", orgId).replace("{budgetId}", budgetId);

        return forkJoin({
            budgetDto: this.http.get<CceUsageManagementBudgetDto>(budgetsUrl, { cached: true }),
            services: this.cspApiService.getOrgServices(orgId),
        }).pipe(map(({ budgetDto, services }) => this.transformBudget(budgetDto, services)));
    }

    public createBudget(orgId: string, budget: CceBudget): Observable<CceUsageManagementBudgetView> {
        let obs: Observable<CceUsageManagementBudgetDto>;

        if (this.localMock) {
            const budgetsUrl = "assets/budget-create-response.mock.json";
            obs = this.http.get<CceUsageManagementBudgetDto>(budgetsUrl, { cached: true });
        } else {
            const budgetsUrl = BUDGETS_URL.replace("{orgId}", orgId);
            obs = this.http.post<CceUsageManagementBudgetDto>(budgetsUrl, budget);
        }

        return forkJoin({
            budgetDto: obs,
            services: this.cspApiService.getOrgServices(orgId),
        }).pipe(
            map(({ budgetDto, services }) => {
                return this.transformBudget(budgetDto, services);
            })
        );
    }

    public deleteBudgets(orgId: string, budgets: CceUsageManagementBudgetView[]) {
        let obs: Observable<any>;

        if (this.localMock) {
            const budgetsUrl = "assets/budgets-delete-response.mock.json";
            obs = this.http.get<any>(budgetsUrl, { cached: true });
        } else {
            const budgetsUrl = BUDGETS_URL.replace("{orgId}", orgId);
            const budgetsToDelete = budgets.map((budget) => budget.id);
            obs = this.http.delete<any>(budgetsUrl, { body: { ids: budgetsToDelete } });
        }

        return obs;
    }

    public updateBudget(orgId: string, budgetId: string, budget: CceEditBudgetBody): Observable<CceUsageManagementBudgetView> {
        let obs: Observable<CceUsageManagementBudgetDto>;

        if (this.localMock) {
            const budgetsUrl = "assets/budget-edit.mock.json";
            obs = this.http.get<CceUsageManagementBudgetDto>(budgetsUrl, { cached: true });
        } else {
            const budgetsUrl = EDIT_BUDGET_URL.replace("{orgId}", orgId).replace("{budgetId}", budgetId);
            obs = this.http.patch<CceUsageManagementBudgetDto>(budgetsUrl, budget);
        }

        return forkJoin({
            budgetDto: obs,
            services: this.cspApiService.getOrgServices(orgId),
        }).pipe(map(({ budgetDto, services }) => this.transformBudget(budgetDto, services)));
    }
}
