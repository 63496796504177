<button class="btn btn-link" routerLink=".."><cds-icon shape="angle" direction="left"></cds-icon>Back</button>

<h3 class="create-budget-header">{{translate('create-budget')}}</h3>

<p>{{translate('creating-budget-overview-paragraph')}}</p>

<ng-container *ngIf="!loadingMetricOptions; else spinner">
    <form *ngIf="showSelectBudgetTypeView" (ngSubmit)="continueCreatingBudget()">
        <clr-radio-container class="create-budget-radio-section">
            <label>{{translate('select-a-budget-type')}}</label>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="budgetTypes" required [value]="CceUsageManagementBudgetType.PERCENTAGE" [(ngModel)]="selectedBudgetType" />
                <label>{{translate('commitment-percentage')}}</label>
            </clr-radio-wrapper>
            
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="budgetTypes" required [value]="CceUsageManagementBudgetType.ACTUAL" [(ngModel)]="selectedBudgetType" /> 
                <label>{{translate('actual-usage')}}</label>
            </clr-radio-wrapper>
            <clr-control-helper>{{translate('select-a-budget-type')}}</clr-control-helper>
        </clr-radio-container>

        <button class="btn btn-primary" [disabled]="!selectedBudgetType">{{translate('continue')}}</button>
        <button class="btn btn-secondary" routerLink="..">{{translate('cancel')}}</button>
    </form>

    <form *ngIf="showSelectMetricInformationView" clrForm [formGroup]="form" (ngSubmit)="createBudget()">
        <clr-control-container [class.clr-error]="!form.get('service').valid && form.get('service').touched">
            <label>{{translate('select-service')}}</label>
            <vmw-combobox
                    id="service"
                    [position]="'bottom-right'"
                    [showTooltip]="false"
                    [dropdownMaxWidth]="'500px'"
                    [isAddNewAllowed]="false"
                    [items]="services"
                    formControlName="service"
                    [placeholder]="translate('select-a-service')">
                <ng-template let-item>
                    <div class="cce-budgets-combobox-item">
                        <csp-service-icon [serviceID]="item.id"></csp-service-icon>
                        <div class="cce-budgets-combobox-item-name">
                            {{item.displayName}} 
                        </div>
                    </div>
                </ng-template>
            </vmw-combobox>
        </clr-control-container>

        <clr-control-container [class.clr-error]="!form.get('metric').valid && form.get('metric').touched">
            <label>{{translate('select-usage-type')}}</label>
            <vmw-combobox
                    id="metric"
                    [position]="'bottom-right'"
                    [showTooltip]="false"
                    [dropdownMaxWidth]="'500px'"
                    [isAddNewAllowed]="false"
                    [items]="metrics"
                    formControlName="metric"
                    [placeholder]="translate('select-a-usage-type')">
                <ng-template let-item>
                    <div class="cce-budgets-combobox-item">
                        <div class="cce-budgets-combobox-item-name">
                            {{item.displayName}} 
                        </div>
                    </div>
                </ng-template>
            </vmw-combobox>
        </clr-control-container>

        <ng-container [ngSwitch]="selectedBudgetType">
            <ng-container *ngSwitchCase="CceUsageManagementBudgetType.PERCENTAGE">
                 <div class="clr-form-control">
                     <label class="clr-control-label cce-budget-value-label">{{translate('commitment-percentage')}}</label>
                     <div class="clr-control-container" [ngClass]="{'clr-error' : isError}">
                         <div class="clr-input-wrapper">
                             <input type="number" min="1" max="1000" step="1" class="clr-input" formControlName="budgetTypeValue" placeholder="{{translate('enter-commitment-percentage')}}"/>
                             <span>{{translate('%')}}</span>
                         </div>
                     </div>
                 </div>
             </ng-container>
 
             <ng-container *ngSwitchCase="CceUsageManagementBudgetType.ACTUAL">
                 <div class="clr-form-control">
                     <label class="clr-control-label cce-budget-value-label">{{translate('actual-usage')}}</label>
                     <div class="clr-control-container" [ngClass]="{'clr-error' : isError}">
                         <div class="clr-input-wrapper">
                             <input type="number" min="1" step="1" class="clr-input" formControlName="budgetTypeValue" placeholder="{{translate('enter-usage')}}"/>
                             <span>{{unit}}</span>
                         </div>
                     </div>
                 </div>
             </ng-container>
        </ng-container>

        <div class="create-budget-form-btns">
            <button class="btn btn-primary" type="submit" [disabled]="!form.valid" [clrLoading]="isCreatingBudget">{{translate('save')}}</button>
            <button class="btn btn-secondary" (click)="cancelMetricInformationSelection()">{{translate('cancel')}}</button> 
        </div>
    </form>
</ng-container>

<ng-template #spinner>
    <div class="spinner-container">
        <div class="spinner"></div>
    </div>
</ng-template>