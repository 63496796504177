export const ENGLISH = {
    "usage-management": "Usage Management",
    "usage-detail": "Usage Detail",
    "current-usage": "Current Usage",
    commitment: "Commitment",
    overage: "Overage",
    unit: "Unit",
    "overage-will-be-charged-with-vmware": "Overage will be charged with VMware",
    "usage-history": "Usage History",
    "max-daily-usage": "Maximum Daily Usage",
    "max-monthly-usage": "Maximum Monthly Usage",
    "hourly-usage-for": "Hourly Usage for {0}",
    "hourly-usage-for-range": "Hourly Usage for {0} to {1}",
    "date-range-exceeds-maximum-title": "Date range exceeds maximum for visual chart. You can use the tabular data browser below.",
    "date-range-exceeds-maximum-description": "The time range and aggregation period you have chosen contains too much data to display.",
    "no-data-found": "No data found",
    "no-data-found-description": "Sorry, no data was found for the selected dates and aggregation period.",
    "no-data-found-hourly-description": "Sorry, no hourly data is available for the selected date.",
    "no-data-found-daily-description": "Sorry, no daily data is available for the selected date.",
    "no-data-found-monthly-description": "Sorry, no monthly data is available for the selected date.",
    date: "Date",
    "date-range": "Date Range",
    "total-usage": "Total Usage",
    "usage-within-commitment": "Usage within commitment",
    "overage-usage-max": "Overage Usage (max)",
    time: "Time",
    "overage-usage": "Overage Usage",
    "pagination-footer": "{0} - {1} of {2} items",
    "sold-by": "Sold by: ",
    "usage-items-per-page": "Usage items per page",
    "error-title": "Sorry, there was an error",
    "error-description": "We were unable to load data for the usage type with ID {0}",
    seller: "Seller",
    "data-for-this-service-is-delayed":
        "Usage data for this service is available on a monthly basis and may be delayed for viewing by up to three months.",
    "download-activity-statement-for": "Download Activity Statement for {0}",
    "activity-statement-file-name": "Activity Statement - {0}",
    "pdf-download-error": "Error downloading Activity Statement",
    "pdf-download-error-message":
        "An error occurred when downloading the Activity Statement - {0}. Please try again or contact VMware support if the issue persists.",
    multiple: "Multiple",
    aws: "AWS",
    vmware: "VMware",
    trend: "Show Trend",
    "daily-usage-for": "Daily Usage for {0}",
    projection: "Projection",
    "no-projection": "No projection",
    "projection-days": "{0} days",
    "projection-months": "{0} months",
    unknown: "Unknown",
    "open-help-projection": "Click to open a help article about projection.",
    "last-reported-datetime": "Last Reported: {0} UTC",
    "medium-datetime-minus-seconds-pattern": "d MMM y, h:mm a",
    "full-date-abbreviated-pattern": "EEE MMM d y",
    "full-month-year-pattern": "MMMM y",
    "day-month-year-pattern": "d MMM y",
    "day-month-short-pattern": "d MMM",
    "medium-minus-seconds-pattern": "MMM d, y, h:mm a",
    consumption: "Consumption",
    "no-overage": "Overage information for this service is not available",
};
