import { Component, OnInit } from "@angular/core";
import { UsageManagementBudgetsService } from "../usage-management-budgets.service";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    CceBudgetSourcePage,
    CceEditBudgetBody,
    CceUsageManagementBudgetType,
    CceUsageManagementBudgetView,
} from "../usage-management-budgets.model";
import { ActivatedRoute, Router } from "@angular/router";
import { L10nKey, TranslateFunction } from "@cce/core";
import { ENGLISH } from "./usage-management-budgets-edit.component.l10n";
import { L10nService, LocalizedComponent, Mixin } from "@vmw/ngx-vip";
import { filter } from "rxjs";
import { VmwNgxButtonType, VmwNgxModalService } from "@vmw/ngx-modal-service";

@Mixin([LocalizedComponent], {
    L10nKey: "usage-management-budgets-edit",
    L10nSourceMap: ENGLISH,
})
@Component({
    templateUrl: "./usage-management-budgets-edit.component.html",
    styleUrls: ["./usage-management-budgets-edit.component.scss"],
})
export class UsageManagementBudgetsEditComponent implements OnInit {
    public translate: TranslateFunction<typeof ENGLISH>;

    public loading: boolean = true;
    public form: FormGroup;
    public orgId: string;
    public budgetId: string;
    public budget: CceUsageManagementBudgetView;
    public unit: string = "";

    public readonly CceUsageManagementBudgetType = CceUsageManagementBudgetType;

    public isSavingBudget: boolean = false;
    get isSaveDisabled(): boolean {
        return !this.form.valid || this.form.untouched;
    }
    get isError(): boolean {
        if (this.form.get("budgetTypeValue").untouched) {
            return false;
        }
        return !this.form.get("budgetTypeValue").valid;
    }

    constructor(
        private budgetsService: UsageManagementBudgetsService,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        private formBuilder: FormBuilder,
        private router: Router,
        public l10nService: L10nService,
        private activatedRoute: ActivatedRoute,
        private modalService: VmwNgxModalService
    ) {}

    ngOnInit(): void {
        this.budgetId = this.activatedRoute.snapshot.params["budgetId"];

        this.crossOrgSwitcherService.orgDetails$.pipe(filter(Boolean)).subscribe({
            next: (org) => {
                this.orgId = org.id;
                this.initEditBudget();
            },
        });

        this.crossOrgSwitcherService.disableCrossOrg();
    }

    private initEditBudget() {
        this.budgetsService.getBudget(this.orgId, this.budgetId).subscribe({
            next: (budgetView: CceUsageManagementBudgetView) => {
                this.budget = budgetView;
                this.form = this.formBuilder.group({
                    budgetTypeValue: [budgetView.amount, Validators.required],
                });

                this.loading = false;
            },
            error: (err) => {
                console.error(err);
                this.loading = false;
            },
        });
    }

    public editBudget() {
        this.isSavingBudget = true;

        const b: CceEditBudgetBody = {
            budget_value: this.form.get("budgetTypeValue")?.value,
        };

        this.budgetsService.updateBudget(this.orgId, this.budgetId, b).subscribe({
            next: (budgetView: CceUsageManagementBudgetView) => {
                this.isSavingBudget = false;
                this.router.navigate(["usage-management/budgets"], { state: { sourcePage: CceBudgetSourcePage.EDIT } });
            },
            error: (err) => {
                console.error(err);
                this.modalService.openAlert(this.translate("editing-budget-failed-message"), {
                    title: this.translate("editing-budget-failed-title"),
                    buttons: {
                        closeButton: {
                            type: VmwNgxButtonType.Danger,
                        },
                    },
                });
                this.isSavingBudget = false;
            },
        });
    }

    public translateBudgetType(budgetType: string) {
        return this.translate(("budget-type." + budgetType) as L10nKey<typeof ENGLISH>);
    }
}
