<form [formGroup]="form">
    <div class="date-picker-container">
        <vmw-date-range-picker formControlName="dateRange"
                               [position]="VmwDateRangePickerPosition.Left"
                               [maxDate]="maxDate"
                               [minDate]="minDate"
                               [range]="VmwDateRangePickerRanges.LAST_30_DAYS"
                               (ngModelChange)="applyDateRangeChanges()"
                               (validityChange)="isValid = $event"
                               (rangeChange)="onRangeChange($event)"
                               (cancel)="cancelDateRangePicker();">
            <div style="display: flex; justify-content: flex-end; margin-bottom: 0.5rem;">
                <clr-select-container style="margin: 0 0.5rem;">
                    <select formControlName="aggregationPeriod" data-test-id="aggregationSelect" clrSelect>
                        <option [value]="CceUsageMetricAggregationPeriod.HOURLY">{{translate('hourly')}}</option>
                        <option [value]="CceUsageMetricAggregationPeriod.DAILY">{{translate('daily')}}</option>
                        <option *ngIf="monthlyAggregationFlag" [value]="CceUsageMetricAggregationPeriod.MONTHLY">{{translate('monthly')}}</option>
                    </select>
                    <clr-control-helper>{{translate('aggregation-period')}}</clr-control-helper>
                </clr-select-container>
            </div>
            <label *ngIf="!isValid" class="range-limit-exceeded" data-test-id="validationMessage">
                <cds-icon size="24" shape="info-circle" status="danger"></cds-icon>
                <ng-container [ngSwitch]="form.get('aggregationPeriod').value">
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.DAILY">
                        {{translate('daily-limit-exceeded')}}
                    </ng-container>
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.HOURLY">
                        {{translate('hourly-limit-exceeded')}}
                    </ng-container>
                    <ng-container *ngSwitchCase="CceUsageMetricAggregationPeriod.MONTHLY">
                        {{translate('monthly-limit-exceeded')}}
                    </ng-container>
                </ng-container>
            </label>
        </vmw-date-range-picker>
    </div>
</form>
