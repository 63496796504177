import { Injectable, Inject, APP_INITIALIZER } from "@angular/core";

import { firstValueFrom, Subject } from "rxjs";

import { LocaleService, PatternCategories, VIPService } from "@vmw/ngx-vip";

import { EarlyPreloadResult, EarlyPreloadService } from "./preload-early.service";

import { APP_ENVIRONMENT, CceUiEnvironment } from "./environment.model";

export function vipPreloadFactory(vipPreloadService: VIPPreloadService) {
    return () => {
        return vipPreloadService
            .initialize()
            .then()
            .catch((e) => {
                console.error(e);
                return new Promise(() => {});
            });
    };
}

export interface VIPPreloadResult {
    result: boolean;
}

@Injectable({
    providedIn: "root",
})
export class VIPPreloadService extends Subject<VIPPreloadResult> {
    constructor(
        @Inject(APP_ENVIRONMENT) private environment: CceUiEnvironment,
        private earlyPreloadService: EarlyPreloadService,
        private vipService: VIPService,
        private localeService: LocaleService
    ) {
        super();
    }

    async initialize() {
        if (!this.environment.vipProduct || !this.environment.vipDefaultComponent || !this.environment.vipVersion) {
            console.warn(`Tried to initialize VIP but missing product, component and/or version`);
            this.next({
                result: false,
            });

            return;
        }

        const earlyPreloadResult = await firstValueFrom(this.earlyPreloadService);

        console.time("init vip");

        const locale = earlyPreloadResult.cspInitializationResult.locale;
        const region = earlyPreloadResult.cspInitializationResult.region;

        this.localeService.init(locale, region);

        // initialize the vIP translation service with vip from backend and real translations
        await this.vipService.initData({
            productID: this.environment.vipProduct,
            version: this.environment.vipVersion,
            host: earlyPreloadResult.cspHost + "/",
            i18nScope: [PatternCategories.DATE, PatternCategories.NUMBER, PatternCategories.PLURAL, PatternCategories.CURRENCIES],
            isPseudo: this.environment.vipEnablePseudo,
            component: this.environment.vipDefaultComponent,
            timeout: 5000,
        });

        console.timeEnd("init vip");

        this.next({
            result: true,
        });
    }
}

export const VIP_PRELOAD_PROVIDER = {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [VIPPreloadService, APP_ENVIRONMENT, EarlyPreloadService, VIPService],
    useFactory: vipPreloadFactory,
};
