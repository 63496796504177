import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, first, switchMap } from "rxjs";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";

@Injectable()
export class UmAPIInterceptor implements HttpInterceptor {
    private readonly USAGE_INSIGHT_API_URL = "usage-insight/api";
    private readonly ORG_ID_HEADER = "usage-insight-organization-id";

    constructor(private cspCrossOrgSwitcherService: CspCrossOrgSwitcherService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if the request URL contains the specific API path
        if (req.url.includes(this.USAGE_INSIGHT_API_URL) && req.method === "GET") {
            // Get the organization details from the service
            return this.cspCrossOrgSwitcherService.orgDetails$.pipe(
                first(),
                switchMap((org) => {
                    // Clone the request to add the new header with the organization ID
                    const clonedRequest = req.clone({
                        headers: req.headers.set(this.ORG_ID_HEADER, org.id),
                    });

                    // Pass the cloned request to the next handler
                    return next.handle(clonedRequest);
                })
            );
        }

        // If the request URL does not match, pass the request without modification
        return next.handle(req);
    }
}
