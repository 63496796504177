<button class="btn btn-link" routerLink="../.."><cds-icon shape="angle" direction="left"></cds-icon>{{translate('back')}}</button>

<h3 class="edit-budget-header">{{translate('edit-budget')}}</h3>

<ng-container *ngIf="!loading; else spinner">
    <p>{{translate('editing-budget-overview-paragraph', budget.usageType, budget.service)}}</p>

    <form [formGroup]="form" clrForm (ngSubmit)="editBudget()">
        <clr-select-container>
            <label>{{translate('service')}}</label>
            <select clrSelect disabled>
                <option>{{budget.service}}</option>
            </select>
        </clr-select-container>
        <clr-select-container>
            <label>{{translate('usage-type')}}</label>
            <select clrSelect disabled>
                <option>{{budget.usageType}}</option>
            </select>
        </clr-select-container>
        <clr-select-container>
            <label>{{translate('select-budget-type')}}</label>
            <select clrSelect disabled>
                <option>{{translateBudgetType('budget-type.' + budget.budgetType)}}</option>
            </select>
        </clr-select-container>

        <ng-container [ngSwitch]="budget.budgetType">
           <ng-container *ngSwitchCase="CceUsageManagementBudgetType.PERCENTAGE">
                <div class="clr-form-control">
                    <label class="clr-control-label cce-budget-value-label">{{translate('commitment-percentage')}}</label>
                    <div class="clr-control-container" [ngClass]="{'clr-error' : isError}">
                        <div class="clr-input-wrapper">
                            <input type="number" min="1" max="1000" step="1" class="clr-input" formControlName="budgetTypeValue" placeholder="{{translate('enter-commitment-percentage')}}"/>
                            <span>{{translate('%')}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="CceUsageManagementBudgetType.ACTUAL">
                <div class="clr-form-control">
                    <label class="clr-control-label cce-budget-value-label">{{translate('actual-usage')}}</label>
                    <div class="clr-control-container" [ngClass]="{'clr-error' : isError}">
                        <div class="clr-input-wrapper">
                            <input type="number" min="1" step="1" class="clr-input" formControlName="budgetTypeValue" placeholder="{{translate('enter-usage')}}"/>
                            <span>{{budget.unit}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        
        <div class="edit-budget-form-btns">
            <button class="btn btn-primary" type="submit" [disabled]="isSaveDisabled" [clrLoading]="isSavingBudget">{{translate('save')}}</button>
            <button class="btn btn-secondary" routerLink="../..">{{translate('cancel')}}</button> 
        </div>
    </form>
</ng-container>

<ng-template #spinner>
    <div class="spinner-container">
        <div class="spinner"></div>
    </div>
</ng-template>
