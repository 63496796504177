
<ng-container *ngIf="!initialLoading; else spinner">
    <ng-container *ngIf="haveData; else emptyStatePlaceholder">
        <clr-alert clrAlertType="info" [clrAlertClosable]="false">
            <clr-alert-item>
                <span class="tooltip-content"
                      [innerHtml]="translate('stale-data')"></span>
            </clr-alert-item>
        </clr-alert>

        <clr-alert *ngIf="showAwsAlert" clrAlertType="info" [clrAlertClosable]="true" (clrAlertClosedChange)="closeAwsAlert()">
            <clr-alert-item>
                <span class="tooltip-content"
                    [innerHtml]="translate('aws-data-in-aws-console', awsConsoleLink) | safeHtml:SAFE_HTML_CONFIG"></span>
            </clr-alert-item>
        </clr-alert>

        <div class="card margin-bottom-36" *ngIf="haveTop5Data">
            <div class="card-block">
                <h3 class="cce-card-heading">
                    {{translate('top-usages-with-commitment')}}
                    <button class="card-help-btn help-btn" [attr.aria-label]="translate('top-usages-button-help')"
                        (click)="openHelp('what-does-top-usage-types-with-commitment-show')">
                        <cds-icon size="24" shape="help"></cds-icon>
                    </button>
                </h3>

                <h4 class="cce-card-subheading">{{translate('top-last-30-days')}}</h4>

                <ng-container *ngIf="!(enableHorizontalCharts$ | async); else horizontalCharts">
                    <vmw-bar-chart
                            (onBarClick)="top5UsageBarClick($event)"
                            (onXLabelClick)="top5UsageLabelClick($event)"
                            (onBarHover)="setActiveMetric($event)"
                            class="overview-chart"
                            [loading]="top5Loading"
                            [options]="top5ChartOptions"
                            [data]="top5ChartData">
                        <csp-service-icon
                            vmwBarChartXLabelIcon
                            [size]="16"
                            (click)="navigateToMetricDetail(metric.serviceId, metric.metricId, metric.sellerId)"
                            [serviceID]="metric.serviceId"
                            *ngFor="let metric of top5Metrics">
                        </csp-service-icon>

                        <vmw-tooltip [tooltipWidth]="400">
                            <vmw-tooltip-content *ngIf="activeMetric">
                                <cce-usage-tooltip
                                    [usage]="activeMetric.usage"
                                    [commitment]="activeMetric.commitment"
                                    [unit]="activeMetric.unit"
                                    [serviceId]="activeMetricServiceId"
                                    [title]="activeMetric.display_name"
                                    [subtitle]="activeMetricServiceName">
                                </cce-usage-tooltip>
                            </vmw-tooltip-content>
                        </vmw-tooltip>
                    </vmw-bar-chart>
                </ng-container>

                <ng-template #horizontalCharts>
                    <cce-horizontal-bar-chart [enableThreshold]="true">
                        <cce-horizontal-bar-chart-bar *cceBarChartBars="let bar of top5MetricsNew"
                            [label]="translate('commitment-label', bar.usage, bar.commitment)"
                            [value]="bar.usage"
                            [threshold]="bar.commitment"
                            (onClick)="navigateToMetricDetail(bar.serviceId, bar.metricId, bar.seller)">
                            <cce-horizontal-bar-chart-bar-label>
                                <a [routerLink]="['services', bar.serviceId, 'metrics', bar.metricId]"
                                    [queryParams]="{seller: bar.seller}">
                                    <csp-service-icon
                                        [size]="20"
                                        (click)="navigateToMetricDetail(bar.serviceId, bar.metricId, bar.seller)"
                                        [serviceID]="bar.serviceId"
                                        [style.marginRight]="'5px'">
                                    </csp-service-icon>{{bar.display_name}}
                                </a>
                            </cce-horizontal-bar-chart-bar-label>

                            <cce-horizontal-bar-chart-bar-tooltip>
                                <cce-usage-tooltip
                                    [usage]="bar.usage"
                                    [commitment]="bar.commitment"
                                    [unit]="bar.unit"
                                    [serviceId]="bar.serviceId"
                                    [title]="bar.display_name"
                                    [subtitle]="bar.serviceName">
                                </cce-usage-tooltip>
                            </cce-horizontal-bar-chart-bar-tooltip>
                        </cce-horizontal-bar-chart-bar>

                        <cce-bar-chart-threshold-label>
                            {{translate('threshold-label')}}
                        </cce-bar-chart-threshold-label>

                        <vmw-legend>
                            <vmw-legend-item [color]="CceHorizontalBarChartColors.BELOW_THRESHOLD">{{translate('within-commitments')}}</vmw-legend-item>
                            <vmw-legend-item [color]="CceHorizontalBarChartColors.BETWEEN_THRESHOLD">{{translate('available')}}</vmw-legend-item>
                            <vmw-legend-item [color]="CceHorizontalBarChartColors.ABOVE_THRESHOLD">{{translate('overage')}}</vmw-legend-item>
                        </vmw-legend>
                    </cce-horizontal-bar-chart>
                </ng-template>
            </div>
        </div>

        <h3 class="cce-header-h3">
            {{translate('current-usage')}}
        </h3>

        <h4 class="cce-card-subheading">{{translate('total-usage-types', gridTotalItems | number)}}</h4>

        <div class="action-bar" *ngIf="enableCurrentUsageDownloadCSV$ | async">
            <cce-download-current-usages-csv-button
                *ngIf="!gridMetricsLoading"
                [orgId]="orgId"
                [orgServices]="orgServices"
                [page]="page"
                [size]="size"
                [sort]="sort"
                [direction]="direction"
                [filters]="filters"
                [totalItems]="+gridPagination?.totalItems">
            </cce-download-current-usages-csv-button>
        </div>

        <clr-datagrid
                [vmwPersistClrDatagridSettings]="'current-usage'"
                (clrDgRefresh)="loadGridMetrics($event)"
                [clrDgLoading]="gridMetricsLoading">
            <clr-dg-column [style.width.px]="200" [clrDgField]="'display_name'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('usage-type')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [style.width.px]="200" [clrDgField]="'service_definition_id'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('service')}}
                </ng-container>
                <clr-dg-filter [clrDgFilter]="serviceFilter">
                    <service-filter [totalGridRows]="gridTotalItems" [currentGridData]="gridMetrics" #serviceFilter></service-filter>
                </clr-dg-filter>
            </clr-dg-column>
            <clr-dg-column [style.width.px]="100" [clrDgField]="'uom_display_name'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('unit')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [style.width.px]="100" [clrDgField]="'usage_quantity'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('usage')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [style.width.px]="100" [clrDgField]="'commit_quantity'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('commitment')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'status'" [clrDgSortOrder]="ClrDatagridSortOrder.ASC">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('status')}}
                </ng-container>
                <clr-dg-filter [clrDgFilter]="cceStatusFilter">
                    <cce-status-filter #cceStatusFilter></cce-status-filter>
                </clr-dg-filter>
            </clr-dg-column>
            <clr-dg-column [style.width.px]="100" [clrDgField]="'overage_quantity'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('overage')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column *ngIf="enableCurrentConsumptionBarFeatureFlag$ | async">
                <ng-container *clrDgHideableColumn="{ hidden: !enableConsumptionBar }"> 
                    {{translate('current-consumption')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column>
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('seller')}}
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'usage_date_time'">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{translate('last-updated')}}
                </ng-container>
            </clr-dg-column>

            <clr-dg-placeholder>
                <ng-container *ngTemplateOutlet="emptyStatePlaceholderGrid"></ng-container>
            </clr-dg-placeholder>

            <clr-dg-row *ngFor="let metric of gridMetrics; let i = index" [clrDgItem]="metric">
                <clr-dg-cell vmwTextClipper>
                    <a [routerLink]="['/usage-management', 'services', metric.serviceId, 'metrics', metric.metricId]"
                    [queryParams]="{seller: metric.sellerId}">
                        {{metric.usageType}}
                    </a>
                </clr-dg-cell>
                <clr-dg-cell vmwTextClipper>
                    <csp-service-icon [size]="24" [serviceID]="metric.serviceId"></csp-service-icon>
                    {{metric.serviceDisplayName}}
                </clr-dg-cell>
                <clr-dg-cell>{{metric.unit}}</clr-dg-cell>
                <clr-dg-cell>{{metric.usage | numberFormat: { maxFractionDigits: 2 } }}</clr-dg-cell>
                <clr-dg-cell>{{metric.commitment | numberFormat: { maxFractionDigits: 2 } }}</clr-dg-cell>
                <clr-dg-cell>
                    {{translate('usage-type-status.' + metric.status)}}
                </clr-dg-cell>
                <clr-dg-cell>{{metric.overage | numberFormat: { maxFractionDigits: 2 } }}</clr-dg-cell>
                <clr-dg-cell *ngIf="enableCurrentConsumptionBarFeatureFlag$ | async">
                    <cce-consumption-bar [usage]="metric.usage" [commitment]="metric.commitment" [unit]="metric.unit" [status]="metric.status"></cce-consumption-bar>
                </clr-dg-cell>
                <clr-dg-cell>
                    {{metric.seller}}
                    <div *ngIf="!metric.seller">
                        <ng-container *ngIf="getSellerFromId(metric.sellerId) | async as sellerName; else sellerSpinner">
                            {{sellerName}}
                        </ng-container>
                        <ng-template #sellerSpinner>
                            <div class="spinner-container">
                                <div class="spinner spinner-inline"></div>
                            </div>
                        </ng-template>
                    </div>
                </clr-dg-cell>
                <clr-dg-cell>
                    {{metric.lastUpdated | dateFormat:translate('medium-datetime-minus-seconds-pattern')}}
                </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #gridPagination
                        [clrDgTotalItems]="gridTotalItems"
                        [clrDgPageSize]="gridPageSize">
                    <clr-dg-page-size [clrPageSizeOptions]="[5,10,20,50,100]">
                        {{translate('usage-items-per-page')}}
                    </clr-dg-page-size>
                    {{translate('pagination-footer', gridPagination.firstItem + 1, gridPagination.lastItem + 1,
                    gridPagination.totalItems)}}
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </ng-container>
</ng-container>

<ng-template #emptyStatePlaceholder>
    <vmw-empty-state-placeholder
            class="eye-level"
            [title]="translate('no-usage-info')"
            [description]="emptyMessage"
            [iconSize]="128"
            [icon]="'bar-chart'">
    </vmw-empty-state-placeholder>
</ng-template>

<ng-template #emptyStatePlaceholderGrid>
    <vmw-empty-state-placeholder
            class="grid-level"
            [title]="translate('no-usage-info')"
            [description]="translate(filtersActive ? 'no-usage-info-grid-filtered' : 'no-usage-info-grid')"
            [iconSize]="64"
            [icon]="'bar-chart'">
    </vmw-empty-state-placeholder>
</ng-template>

<ng-template #spinner>
    <div class="spinner-container eye-level">
        <div class="spinner"></div>
    </div>
</ng-template>
