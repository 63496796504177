export enum CostAndUsageFeatureFlags {
    EnableMonthlyAggregation = "enableMonthlyAggregation",
    EnableCurrentUsageDownloadCSV = "enableCurrentUsageDownloadCSV",
    EnableTrendLine = "enableTrendLine",
    EnableWeightedTrendLine = "enableWeightedTrendLine",
    EnableProjection = "enableProjection",
    EnableHorizontalCharts = "enableHorizontalBarCharts",
    EnableCurrentConsumptionBar = "enableCurrentConsumptionBar",
    EnableUsageManagementBudgets = "enableUsageManagementBudgets",
    EnableInsights = "enableInsights",
    EnableUmReports = "enableUmReports",
    EnableUmLargeDownload = "enableUmLargeDownload",
    EnableUmPlaceHolder = "enableUmPlaceholder",
    EnableUmAgents = "enableUmAgents",
}
