/**
 * General model for the cost and usage application
 */

export enum CceUsageSortDirection {
    Descending = "desc",
    Ascending = "asc",
}

export enum CceUsageMetricSort {
    STATUS = "status",
    COMMIT_QUANTITY = "commit_quantity",
    OVERAGE_QUANTITY = "overage_quantity",
    USAGE_QUANTITY = "usage_quantity",
    USAGE_DATE_TIME = "usage_date_time",
    UOM_DISPLAY_NAME = "uom_display_name",
}

export enum CceUsageMetricUsageStatus {
    OVER_COMMITMENT = "OVER_COMMITMENT",
    ON_DEMAND = "ON_DEMAND",
    AT_COMMITMENT = "AT_COMMITMENT",
    BELOW_COMMITMENT = "BELOW_COMMITMENT",
    UNKNOWN = "UNKNOWN",
}

export enum CceUsageMetricAggregationPeriod {
    DAILY = "DAILY",
    MONTHLY = "MONTHLY",
    HOURLY = "HOURLY",
}

export enum CceUsageMetricAggregationType {
    AVERAGE = "AVERAGE",
    MAX = "MAX",
    MIN = "MIN",
}

export interface CceUsageMetricChartDataViewModel {
    serviceId: string;

    /**
     * Translated display name for the metric
     */
    usageType: string;

    /**
     * Raw usage number
     */
    usage: number;

    /**
     * Commitment for this metric. If undefined, it means this metric is being
     * consumed on-demand.
     */
    commitment?: number;

    /**
     * Percentage of commitment number. Defined only if commitment is defined
     */
    usagePercentOfCommitment?: number;

    /**
     * Translated unit of measure for the metric
     */
    unit: string;

    /**
     * The metric ID
     */
    metricId: string;

    /**
     * Timestamp for this metric's data. The top 5 API will not return this.
     */
    time?: string;

    sellerId?: string;
}

/**
 * Metadata object returned for each metric usage dto
 */
export interface CceUsageMetricMetadataDto {
    metric_id: string;
    display_name: string;
    service_definition_id: string;
    unit_of_measure_display_name: string;
    charge_attributes?: { [key: string]: string };
}

export interface CceUsageMetricUsageDto {
    metadata: CceUsageMetricMetadataDto;
    usage: CceUsageMetricMetricUsageInfoDto;
}

export interface CceUsageMetricMetricUsageInfoDto {
    usage: number;
    commitment: number;
    overage: number;
    status: CceUsageMetricUsageStatus;
    seller: string;
    last_updated: string; // RFC 3339 TIMESTAMP
    seller_id: string;
    partner_id: string;
    subscriptions: CceUsageMetricMetricUsageInfoSubscriptionDto[];
}

export interface CceUsageMetricMetricUsageInfoSubscriptionDto {
    subscription_id: string;
    billing_engine_subscription_id: string;
}

export interface CceUsageMetricMetricUsageInfoViewModel {
    usage: number;
    commitment: number;
    overage: number;
    status: CceUsageMetricUsageStatus;
    seller: string;
    last_updated: string; // RFC 3339 TIMESTAMP;
}

export interface CceUsageMetricPaginationSortDto {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

export interface CceUsageMetricPaginationPageableDto {
    offset: number;
    page_number: number;
    page_size: number;
    paged: boolean;
    sort: CceUsageMetricPaginationSortDto;
    unpaged: boolean;
}

export interface CceUsageMetricUsagePaginatedDto {
    content: CceUsageMetricUsageDto[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    number_of_elements: number;
    pageable: CceUsageMetricPaginationPageableDto;
    size: number;
    sort: CceUsageMetricPaginationSortDto;
    total_elements: number;
    total_pages: number;
}

/**
 * For usage-management page: current usage data grid
 * Transformed searched Metrics Usages
 */
export interface CceUsageMetricUsageRecordViewModel {
    orgId: string;
    usageType: string;
    serviceDisplayName?: string;
    unit: string;
    usage: number;
    commitment: number;
    overage: number;
    status: CceUsageMetricUsageStatus;
    seller: string;
    lastUpdated: string;
    metricId: string;
    serviceId: string;
    sellerId: string;
    partnerId: string;
    billingEngineSubscriptionIds: string[];
}

export interface CceUsageMetricUsagePaginatedViewModel {
    metrics: CceUsageMetricUsageRecordViewModel[];
    totalItems: number;
}

/**
 * For metric-detail page
 * Metric Usage Dto
 */

export interface CceUsageMetricMetricUsageDto {
    seller: string;
    usage: number;
    commitment: number;
    overage: number;
    last_updated: string;
    status: CceUsageMetricUsageStatus;
}

/**
 * For metric-detail page: metric overview
 * Metric Usage Overview DTO
 */
export interface CceMetricUsageOverviewDto {
    metadata: CceUsageMetricMetadataDto;
    usages: CceUsageMetricMetricUsageDto[];
}

/**
 * For metric-detail page
 * The usage overview of a metric
 *
 * Question: how we get the icon for each seller? Is one seller correspond to one serviceId?
 * That case, the related DTO needs to put serviceId information into the usages list
 * TODO: To-be-confirmed
 */
export interface CceUsageMetricMetricUsageOverviewViewModel {
    usageType: string;
    serviceDisplayName?: string;
    unit: string;
    sellers: string[];
    metricId: string;
    serviceId: string;
    usages: CceUsageMetricMetricUsageInfoViewModel[];
}

/**
 * For metric-detail page
 * Usage detail record for a metric returned from backend
 */
export interface CceUsageMetricMetricUsageHistoryRecordDto {
    usage_datetime: string; // format: time-time, "2021-06-20T01:02:03+00:00"
    commitment: number;
    usage: number;
    overage: number;
}

/**
 * For metric-detail page
 *
 * Daily/Hourly/Monthly (max 90 days) usages history for a metric returned from backend
 */
export interface CceUsageMetricMetricUsageHistoryDto {
    metadata: CceUsageMetricMetadataDto;
    content: CceUsageMetricMetricUsageHistoryRecordDto[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    number_of_elements: number;
    pageable: CceUsageMetricPaginationPageableDto;
    size: number;
    sort: CceUsageMetricPaginationSortDto;
    total_elements: number;
    total_pages: number;
    last_updated_timestamp: string;
}

/**
 * For metric-detail page
 *
 * Transformed metric usage history record for a metric (daily, hourly, monthly)
 */
export interface CceUsageMetricMetricUsageHistoryRecordViewModel {
    time: string;
    commitment: number;
    usageWithinCommitment: number;
    overage: number;
    unit: string;
    usage: number;
    metricId: string;
    serviceId: string;
    serviceIcon?: string;
    statement?: CceInvoiceStatementReduced;
}

export interface CceInvoiceStatementReduced {
    fileToken: string;
    statementCycle: string;
    statementPeriodStartDate: string;
}

export interface CceCurrentUsageCsvRow {
    orgId: string;
    usageType: string;
    serviceDisplayName: string;
    unit: string;
    usage: number;
    commitment: number;
    overage: number;
    status: CceUsageMetricUsageStatus;
    seller: string;
    lastUpdated: string;
    metricId: string;
    sellerId: string;
    partnerId: string;
    billingEngineSubscriptionIds: string;
}
