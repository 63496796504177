<div class="clr-form-control" *ngFor="let service of services;">
    <div class="clr-control-container">
        <div class="clr-checkbox-wrapper">
            <input type="checkbox"
                   id="{{service.id}}-filter-selection"
                   [value]="service.displayName"
                   [ngModel]="selectedServices.includes(service.id)"
                   (ngModelChange)="toggleServiceSelection(service.id)" />
            <label class="clr-control-label" for="{{service.id}}-filter-selection">
                <csp-service-icon [size]="24" [serviceID]="service.id"></csp-service-icon>
                {{service.displayName}}
            </label>
        </div>
    </div>
</div>

<button class="btn btn-link btn-sm" (click)="onClearSelection()" [disabled]="selectedServices.length === 0">
    {{translate("clear-all")}}
</button>
