<ng-container *ngIf="!loading; else spinner">
    <ng-container *ngIf="insights.length > 0; else emptyStatePlaceholder">
        <div class="card overview-card">
            <div class="card-header">
                <h3 class="cce-card-heading">{{this.translate("overview")}}</h3>
                <h4 class="cce-card-subheading">{{this.translate("last-30-days")}}</h4>
            </div>

            <div class="clr-row card-body clr-col-xl-8 overview-card-text">
                <div class="clr-col overview-card-item">
                    <div>{{this.translate("total-usages")}}</div>
                    <div class="value">{{totalUsages | numberFormat: { maxFractionDigits: 2 } }}</div>
                </div>

                <div class="clr-col overview-card-item">
                    <div>{{this.translate("below-commitment")}}</div>
                    <div class="value">{{usagesBelow | numberFormat: { maxFractionDigits: 2 } }}</div>
                </div>

                <div class="clr-col overview-card-item">
                    <div>{{this.translate("over-commitment")}}</div>
                    <div class="value">{{usagesOver | numberFormat: { maxFractionDigits: 2 } }}</div>
                </div>

                <div class="clr-col overview-card-item">
                    <div>{{this.translate("at-commitment")}}</div>
                    <div class="value">{{usagesAt | numberFormat: { maxFractionDigits: 2 } }}</div>
                </div>

                <div class="clr-col overview-card-item">
                    <div>{{this.translate("on-demand")}}</div>
                    <div class="value">{{usagesDemand | numberFormat: { maxFractionDigits: 2 } }}</div>
                </div>
            </div>
        </div>
        <h4>{{this.translate('insight-cards-heading')}}</h4>
        <h4 class="cce-card-subheading">{{this.translate("last-30-days")}}</h4>
        <vmw-card-catalog>
            <vmw-card-catalog-card *vmwCardCatalogItems="let insight of insights">
                <cce-insight-card
                    [insight]="insight">
                </cce-insight-card>
            </vmw-card-catalog-card>
        
        </vmw-card-catalog>
    </ng-container>
</ng-container>

<ng-template #spinner>
    <div class="spinner-container eye-level">
        <div class="spinner"></div>
    </div>
</ng-template>

<ng-template #emptyStatePlaceholder>
    <vmw-empty-state-placeholder
            class="eye-level"
            [title]="translate('no-insights')"
            [description]="translate('no-insights-message')"
            [iconSize]="54"
            [icon]="'info-standard'">
    </vmw-empty-state-placeholder>
</ng-template>