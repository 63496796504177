import { NgForOf, NgForOfContext } from "@angular/common";
import { Directive, Input, IterableDiffers, TemplateRef, ViewContainerRef } from "@angular/core";
import { CceHorizontalBarChartService } from "./cce-horizontal-bar-chart.service";

@Directive({
    selector: "[cceBarChartBars][cceBarChartBarsOf]",
})
export class CceHorizontalBarChartBarsDirective<T> {
    private iterableProxy: NgForOf<T>;

    constructor(
        public template: TemplateRef<NgForOfContext<T>>,
        private differs: IterableDiffers,
        private vcr: ViewContainerRef,
        private service: CceHorizontalBarChartService
    ) {
        this.iterableProxy = new NgForOf<T>(this.vcr, this.template, this.differs);
    }

    @Input("cceBarChartBarsOf")
    set rawItems(newItems: T[]) {
        this.service.reset();
        this.iterableProxy.ngForOf = newItems;
        this.iterableProxy.ngDoCheck();
    }

    /**
     * Asserts the correct type of the template context that the directive will render.
     * See https://angular.io/guide/structural-directives#typing-the-directives-context
     *
     * The presence of this method is a signal to the Ivy template type-check compiler that the
     * structural directive renders its template with a specific context type.
     */
    static ngTemplateContextGuard<T>(_dir: CceHorizontalBarChartBarsDirective<T>, _ctx: unknown): _ctx is NgForOfContext<T> {
        return true;
    }
}
