import { CceInsightType } from "../usage-manangement-insights.model";

export const ENGLISH = {
    "below-commitment-msg":
        "Your usage of {0} {1} for {2} is <strong>below your commitment</strong> of {3}. You should adjust your usage to optimize your cost.",
    "over-commitment-msg":
        "Your usage of {0} {1} for {2} is <strong>above your commitment</strong> of {3}. You should consider purchasing an additional commitment to optimize your cost.",
    [CceInsightType.OVER_COMMITMENT]: "Over Commitment",
    [CceInsightType.BELOW_COMMITMENT]: "Below Commitment",
    "view-usage": "View Usage",
};
