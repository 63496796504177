import { Component } from '@angular/core';

import { CspRobot } from '@vmw/csp-ngx-components';
import { Mixin } from '@vmw/ngx-utils';
import { L10nService, LocalizedComponent } from '@vmw/ngx-vip';

import { ENGLISH } from './page-not-found.l10n';

@Component({
    templateUrl: 'page-not-found.component.html'
})
@Mixin([LocalizedComponent], {
    L10nKey: 'page-not-found',
    L10nSourceMap: ENGLISH,
})
export class PageNotFoundComponent implements LocalizedComponent {
    translate: (key: keyof typeof ENGLISH, ...args: any[]) => string;
    
    readonly robot: CspRobot = CspRobot.Lookout;
    
    constructor(public l10nService: L10nService) {}

}
