export const ENGLISH = {
    "clarity-common-strings.expand": "Expand",

    "clarity-common-strings.collapse": "Collapse",

    "clarity-common-strings.open": "Open",

    "clarity-common-strings.close": "Close",

    "clarity-common-strings.show": "Show",

    "clarity-common-strings.hide": "Hide",

    "clarity-common-strings.more": "More",

    "clarity-common-strings.select": "Select",

    "clarity-common-strings.select-all": "Select All",

    "clarity-common-strings.previous": "Previous",

    "clarity-common-strings.next": "Next",

    "clarity-common-strings.current": "Current",

    "clarity-common-strings.info": "Info",

    "clarity-common-strings.success": "Success",

    "clarity-common-strings.warning": "Warning",

    "clarity-common-strings.danger": "Danger",

    "clarity-common-strings.row-actions": "Row Actions",

    "clarity-common-strings.pick-columns": "Pick Columns",

    "clarity-common-strings.show-columns": "Show Columns",

    "clarity-common-strings.sort-column": "Sort Column",

    "clarity-common-strings.first-page": "First Page",

    "clarity-common-strings.last-page": "Last Page",

    "clarity-common-strings.next-page": "Next Page",

    "clarity-common-strings.previous-page": "Previous Page",

    "clarity-common-strings.current-page": "Current Page",

    "clarity-common-strings.total-pages": "Total Pages",

    "clarity-common-strings.min-value": "Minimum value",

    "clarity-common-strings.max-value": "Maximum value",

    "clarity-common-strings.show-columns-menu-description": "Show/Hide columns menu",

    "clarity-common-strings.all-columns-selected": "All columns were selected",

    "clarity-common-strings.loading": "Loading",

    "clarity-common-strings.date-picker-toggle": "Toggle Date Picker",

    "clarity-common-strings.previous-month": "Previous Month",

    "clarity-common-strings.current-month": "Current Month",

    "clarity-common-strings.next-month": "Next Month",

    "clarity-common-strings.available-actions": "Available Actions",

    "clarity-common-strings.previous-decade": "Previous Decade",

    "clarity-common-strings.next-decade": "Next Decade",

    "clarity-common-strings.current-decade": "Current Decade",

    "clarity-common-strings.date-picker-select-month": "Select month, the current month is ",

    "clarity-common-strings.date-picker-select-year": "Select year, the current year is ",

    "clarity-common-strings.signpost-toggle": "Signpost Toggle",

    "clarity-common-strings.not-started": "Not Started",

    "clarity-common-strings.in-progress": "In Progress",

    "clarity-common-strings.completed": "Completed",

    "clarity-common-strings.error": "Error",

    "clarity-common-strings.vertical-nav-group-toggle": "Toggle vertical navigation group",

    "clarity-common-strings.toggle-more-row-content": "Toggle more row content",

    "clarity-common-strings.day-picker-sr-current-month-phrase": "The current month is",

    "clarity-common-strings.day-picker-sr-current-year-phrase": "The current year is",

    "clarity-common-strings.day-picker-sr-current-decade-phrase": "The current decade is",

    "clarity-common-strings.stack-view-changed": "Value changed",

    "clarity-common-strings.datagrid-expandable-beginning-of": "Beginning of",

    "clarity-common-strings.datagrid-expandable-end-of": "End of",

    "clarity-common-strings.datagrid-expandable-row-content": "Expandable row content",

    "clarity-common-strings.datagrid-expandable-row-helper-text": `
        Screen reader table commands may not work for viewing expanded content,
        please use your screen reader's browse mode to read the content exposed by this button
    `,

    "clarity-common-strings.modal-content-start": "Beginning of Modal Content",

    "clarity-common-strings.modal-content-end": "End of Modal Content",

    "clarity-common-strings.signpost-close": "Signpost Close",

    "clarity-common-strings.detail-pane-start": "Start of row details",

    "clarity-common-strings.detail-pane-end": "End of row details",

    "clarity-common-strings.vertical-nav-toggle": "Toggle vertical navigation",
};
