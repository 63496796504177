import { Component, Input } from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";
import { Mixin } from "@vmw/ngx-utils";
import { VmwSvgIconColorFormat } from "@vmw/ngx-components";

import { TranslateFunction } from "@cce/core";

import { ENGLISH } from "./usage-tooltip.component.l10n";

interface CceUsageToolTipItem {
    label: string;
    value: number;
    color: CceUsageTooltipColors;
}

enum CceUsageTooltipColors {
    USAGE = "#BCC6CC",
    AVAILABLE = "#EBF1F4",
    OVERAGE = "#57C8EA",
    PROJECTION = "#E5d7f3",
}

@Component({
    selector: "cce-usage-tooltip",
    templateUrl: "./usage-tooltip.component.html",
    styleUrls: ["./usage-tooltip.component.scss"],
})
@Mixin([LocalizedComponent], {
    L10nKey: "usage-tooltip",
    L10nSourceMap: ENGLISH,
})
export class CceUsageTooltipComponent implements LocalizedComponent {
    @Input() public usage: number;
    @Input() public commitment: number;
    @Input() public title: string;
    @Input() public subtitle: string;
    @Input() public serviceId: string;
    @Input() public unit: string;
    @Input() public projection: boolean;

    public readonly VmwSvgIconColorFormat = VmwSvgIconColorFormat;
    public translate: TranslateFunction<typeof ENGLISH>;
    public items: CceUsageToolTipItem[] = [];

    ngOnChanges() {
        this.items = this.calculateItems();
    }

    private calculateItems() {
        if (this.projection) {
            const projectionItem: CceUsageToolTipItem = {
                label: this.translate("projection"),
                value: this.usage,
                color: CceUsageTooltipColors.PROJECTION,
            };

            return [projectionItem];
        }

        if (this.usage > this.commitment) {
            const usageItem: CceUsageToolTipItem = {
                label: this.translate("within-commitments"),
                value: this.commitment,
                color: CceUsageTooltipColors.USAGE,
            };
            const overageItem: CceUsageToolTipItem = {
                label: this.translate("overage"),
                value: this.usage - this.commitment,
                color: CceUsageTooltipColors.OVERAGE,
            };

            return [usageItem, overageItem];
        }

        const usageItem: CceUsageToolTipItem = {
            label: this.translate("within-commitments"),
            value: this.usage,
            color: CceUsageTooltipColors.USAGE,
        };

        const availableItem: CceUsageToolTipItem = {
            label: this.translate("available"),
            value: this.commitment - this.usage,
            color: CceUsageTooltipColors.AVAILABLE,
        };

        return [usageItem, availableItem];
    }

    public get footerText() {
        if (this.commitment > 0) {
            return this.translate(
                "commit-and-usage-tooltip-footer",
                this.decimalPipe.transform(this.commitment, "1.0-2"),
                this.decimalPipe.transform(this.usage, "1.0-2"),
                this.unit ?? ""
            );
        }
        return this.translate("total-usage-tooltip-footer", this.decimalPipe.transform(this.usage, "1.0-2"), this.unit ?? "");
    }
    constructor(public l10nService: L10nService, private decimalPipe: DecimalPipe) {}
}
