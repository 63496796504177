import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { initEnvironment, CceUiEnvironment, RootInjector, APP_ENVIRONMENT } from "@cce/core";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

initEnvironment(environment).then((env) => {
    if (env.maintenanceUrl && env.maintenanceUrl !== "") {
        window.location.href = env.maintenanceUrl;
        return;
    }

    const platform = platformBrowserDynamic([
        {
            provide: APP_ENVIRONMENT,
            useValue: env,
        },
    ]);

    if (env.production) {
        enableProdMode();
    }

    RootInjector.setInstance(platform.injector);

    return platform.bootstrapModule(AppModule);
});
