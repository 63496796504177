import { CspEnvironment } from "@vmw/csp-common";

export const environment = {
    production: true,
    oauthClientId: "some-client-id",
    cspServiceDefinitionId: "some-service-definition",
    cspEnvironment: CspEnvironment.PRODUCTION,
    vipProduct: "CCE",
    vipDefaultComponent: "cost-and-usage-ui",
    vipVersion: "1.0.0",
    maintenanceUrl: undefined,
    cspSdkKey: "fakeSdkKey",
};
