import { CceUiEnvironment } from "./environment.model";

export async function initEnvironment(environment: CceUiEnvironment): Promise<CceUiEnvironment> {
    const envOverrides: Partial<CceUiEnvironment> = {};
    let localEnvOverrides: Partial<CceUiEnvironment> = {};

    try {
        localEnvOverrides = JSON.parse(window.localStorage.getItem("cce-env") || "");
    } catch (e) {}

    Object.assign(envOverrides, localEnvOverrides);

    // If an environment overrides file is present, apply those overrides
    // before we do anything else, in case the overrides affect subsequent
    // behavior.
    try {
        const envResponse = await fetch("assets/env.json");

        const serverEnvOverrides = await envResponse.json();

        Object.assign(envOverrides, serverEnvOverrides);
    } catch (e) {
        console.error("failed to properly handle env.json");
        console.error(e);
    }

    return Object.assign(environment, envOverrides);
}
