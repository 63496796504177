import { Inject, Component } from "@angular/core";

import { Observable, Subject } from "rxjs";

import { CspServiceSdkToolkit } from "@vmw/csp-service-sdk";
import { CspHeaderOptions, CspHeaderMode } from "@vmw/csp-ngx-components/header";
import { Org } from "@vmw/csp-common";
import { OAuth2Client } from "@vmw/csp-oauth2";
import { OAuth2ScopesSubject, OAuth2TokenSubject } from "@vmw/ngx-csp-oauth2";
import { VmwContextualHelpService } from "@vmw/ngx-contextual-help";

import { APP_ENVIRONMENT, CceUiEnvironment, CceClrCommonStringsService, CceNotificationService } from "@cce/core";
import { USAGE_MANAGEMENT_TITLE } from "./usage-management-overview/usage-management.const";
import { ClrCommonStringsService } from "@clr/angular";
import { NavigationEnd, Router } from "@angular/router";
import { CSP_PLATFORM_OPERATOR_ROLE_NAME, CSP_READ_ONLY_OPERATOR_ROLE_NAME, CSP_CONSENT_READ_ONLY_OPERATOR_ROLE_NAME } from "./app.const";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { VmwIframeSdkQueryParams } from "@vmw/iframe-sdk-common";

const crossOrgOperator = new RegExp(CSP_READ_ONLY_OPERATOR_ROLE_NAME);
const consentReadOnlyOperator = new RegExp(CSP_CONSENT_READ_ONLY_OPERATOR_ROLE_NAME);
const platformOperator = new RegExp(CSP_PLATFORM_OPERATOR_ROLE_NAME);

@Component({
    selector: "cce-cost-and-usage",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    public authToken: string;
    public headerOptions: CspHeaderOptions;
    public readonly serviceId: string;
    public openDrawer = new Subject<CspHeaderMode>();
    public showOrgSelector = new Observable<boolean>();

    public get isLocalhost(): boolean {
        return window.location.host.includes("localhost");
    }

    public set localMock(val: any) {
        localStorage.setItem("usage-mock-local", val.toString());
    }

    public get localMock(): boolean {
        return localStorage.getItem("usage-mock-local") === "true";
    }

    constructor(
        private oauthToken$: OAuth2TokenSubject,
        private oauth2Client: OAuth2Client,
        public cspServiceSdk: CspServiceSdkToolkit,
        private helpService: VmwContextualHelpService,
        @Inject(APP_ENVIRONMENT) public environment: CceUiEnvironment,
        private clrCommonStrings: ClrCommonStringsService,
        private cceClrCommonStringsService: CceClrCommonStringsService,
        private router: Router,
        private scopesSubject: OAuth2ScopesSubject,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        public notificationService: CceNotificationService
    ) {
        this.serviceId = this.environment.cspServiceDefinitionId;
        this.showOrgSelector = this.scopesSubject.contains(crossOrgOperator, consentReadOnlyOperator, platformOperator);
        this.clrCommonStrings.localize(this.cceClrCommonStringsService.getLocalizedClrCommonStrings());
    }

    ngOnInit() {
        this.headerOptions = new CspHeaderOptions();
        this.headerOptions.title = USAGE_MANAGEMENT_TITLE;
        this.headerOptions.allowNoAccessOrganizationSwitch = true;
        this.headerOptions.docsProducts = ["VMware Cloud services"];

        this.oauthToken$.subscribe((t) => {
            this.authToken = t.access_token;
        });

        this.helpService.toggler.subscribe(() => {
            this.openDrawer.next(CspHeaderMode.HELP);
        });

        /*
         * Send our route change events to the parent so it can update the address bar.
         */
        this.router.events.subscribe((re: any) => {
            if (re instanceof NavigationEnd) {
                let url = re.url;
                let queryParams: VmwIframeSdkQueryParams;

                if (url.includes("?")) {
                    let split = url.split("?");
                    url = split[0];
                    const params = new URLSearchParams(split[1]);
                    queryParams = Object.fromEntries(params.entries());
                }

                this.cspServiceSdk.sendRouteChangeToParent(url, queryParams);
            }
        });
    }

    async switchOrg(org: Org) {
        this.crossOrgSwitcherService.clearOverrideOrgId();
        await this.oauth2Client.switchOrganization(org.id);
    }
}
