export const ENGLISH = {
    "current-usages": "Current Usage - {0}",
    "download-csv": "EXPORT",
    "org-id": "Org Id",
    "seller-id": "Seller Id",
    seller: "Seller",
    "partner-id": "Partner Id",
    "billing-engine-subscription-id": "Subscription Id",
    "usage-type": "Usage Type",
    service: "Service",
    unit: "Unit",
    usage: "Usage",
    commitment: "Commitment",
    status: "Status",
    overage: "Overage",
    "last-updated": "Last Updated",
};
