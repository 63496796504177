import { Component, Input } from "@angular/core";

import {
    CceCurrentUsageCsvRow,
    CceUsageMetricSort,
    CceUsageMetricUsagePaginatedViewModel,
    CceUsageSortDirection,
} from "../usage-management.model";
import { UsageService } from "../usage.service";

import { Mixin } from "@vmw/ngx-utils";
import { L10nService, LocalizedComponent } from "@vmw/ngx-vip";
import { ENGLISH } from "./download-current-usages-csv-button.component.l10n";
import { TranslateFunction } from "@cce/core";

import { ClarityIcons, exportIcon } from "@cds/core/icon";
import { Service } from "@vmw/csp-common";

import { saveAs } from "file-saver";

@Component({
    selector: "cce-download-current-usages-csv-button",
    templateUrl: "./download-current-usages-csv-button.component.html",
})
@Mixin([LocalizedComponent], {
    L10nKey: "download-current-usages-csv-button",
    L10nSourceMap: ENGLISH,
})
export class CceDownloadCurrentUsagesCsvButtonComponent {
    @Input() totalItems: number;
    @Input() page: number;
    @Input() size: number;
    @Input() sort: CceUsageMetricSort;
    @Input() direction: CceUsageSortDirection;
    @Input() filters: Object;
    @Input() orgId: string;
    @Input() orgServices: Service[];

    public downloading: boolean = false;

    public translate: TranslateFunction<typeof ENGLISH>;

    constructor(private usageService: UsageService, public l10nService: L10nService) {
        ClarityIcons.addIcons(exportIcon);
    }

    public download() {
        this.downloading = true;

        this.usageService
            .getOrganizationMetricUsageRecords(this.orgId, this.page, this.size, this.sort, this.direction, this.filters)
            .subscribe({
                next: (usagesDto: CceUsageMetricUsagePaginatedViewModel) => {
                    const usagesVm: CceCurrentUsageCsvRow[] = usagesDto.metrics.map((metric) => {
                        const usageVm: CceCurrentUsageCsvRow = {
                            orgId: metric.orgId,
                            usageType: metric.usageType,
                            serviceDisplayName: metric.serviceDisplayName,
                            unit: metric.unit,
                            usage: metric.usage,
                            commitment: metric.commitment,
                            overage: metric.overage,
                            status: metric.status,
                            seller: metric.seller,
                            lastUpdated: metric.lastUpdated,
                            metricId: metric.metricId,
                            sellerId: metric.sellerId,
                            partnerId: metric.partnerId,
                            billingEngineSubscriptionIds: metric.billingEngineSubscriptionIds?.join("; "),
                        };

                        return usageVm;
                    });
                    const formatCSVData = this.formatCSVForCurrentUsages(usagesVm);
                    const blob = new Blob([formatCSVData], { type: "text/csv;charset=utf-8" });
                    saveAs(blob, this.translate("current-usages", this.orgId) + ".csv");
                },
                error: (err) => {
                    this.downloading = false;
                    console.error(err);
                },
                complete: () => {
                    this.downloading = false;
                },
            });
    }

    public formatCSVForCurrentUsages(usages: CceCurrentUsageCsvRow[]) {
        const headers = [
            [this.translate("org-id")],
            [this.translate("billing-engine-subscription-id")],
            [this.translate("usage-type")],
            [this.translate("service")],
            [this.translate("unit")],
            [this.translate("usage")],
            [this.translate("commitment")],
            [this.translate("status")],
            [this.translate("overage")],
            [this.translate("seller")],
            [this.translate("last-updated")],
            [this.translate("seller-id")],
            [this.translate("partner-id")],
        ];

        let rows = "";
        for (const u of usages) {
            rows +=
                [
                    u.orgId,
                    u.billingEngineSubscriptionIds,
                    this.csvSanitize(u.usageType),
                    this.csvSanitize(u.serviceDisplayName),
                    u.unit,
                    u.usage,
                    u.commitment,
                    u.status,
                    u.overage,
                    this.csvSanitize(u.seller),
                    u.lastUpdated,
                    u.sellerId,
                    u.partnerId,
                ].join(",") + "\n";
        }

        return `${headers.join(",")}\n${rows.trim()}`;
    }

    private csvSanitize(str: string): string {
        return str?.includes(",") ? `"${str}"` : str;
    }
}
