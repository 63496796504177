import { Component } from "@angular/core";

@Component({
    selector: "cce-horizontal-bar-chart-bar-label",
    template: "<ng-content></ng-content>",
    styles: [
        `
            :host {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
            }
        `,
    ],
})
export class CceHorizontalBarChartBarLabelComponent {}
