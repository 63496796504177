import { CspEnvironment, getCspHostForEnvironment } from "@vmw/csp-common";
import { OAuth2ModuleConfiguration } from "@vmw/ngx-csp-oauth2";

import { CceUiEnvironment } from "@cce/core";

// Inside a method so that we can use the environment after
// overrides have been applied from env.json in main.ts
export const getOAuthConfig = (): OAuth2ModuleConfiguration => {
    return {
        // client_id can be overridden from env.json, either by a developer or by
        // the docker container via an environment variable CLIENT_ID
        clientId: "undefined",

        redirectUri: location.origin + location.pathname,

        // A list of resource servers against which the Authorization Header will be applied.
        resourceServers: [
            {
                matcher: new RegExp("^/commerce/(cost-and-usage|usage-insight)/api"),
                optional: false,
            },
        ],

        // An additional buffer time, in seconds, at which the current token should be considered expired.
        tokenExpirationBuffer: 60,

        storage: "session",

        // Will get overwritten with a real value by oauth2ConfigFactory.
        cspHostname: "undefined",

        autoExchange: true,
    };
};

export const oauth2ConfigFactory = (environment: CceUiEnvironment) => {
    const config = getOAuthConfig();

    // environment.cspEnvironment can be a well-defined value from CspEnvironment enum or a string URL
    // pointing to a CSP environment
    config.cspHostname = getCspHostForEnvironment(environment.cspEnvironment as CspEnvironment);
    config.clientId = environment.oauthClientId;

    return config;
};
