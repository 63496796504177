import { Component, Input } from "@angular/core";
import { TranslateFunction } from "@cce/core";
import { VmwSvgIconColorFormat } from "@vmw/ngx-components";
import { L10nService, LocalizedComponent, Mixin } from "@vmw/ngx-vip";
import { CceInsight, CceInsightType } from "../usage-manangement-insights.model";
import { ENGLISH } from "./cce-insight-card.component.l10n";

@Mixin([LocalizedComponent], {
    L10nKey: "cce-insight-card",
    L10nSourceMap: ENGLISH,
})
@Component({
    selector: "cce-insight-card",
    templateUrl: "./cce-insight-card.component.html",
    styleUrls: ["./cce-insight-card.component.scss"],
})
export class CceInsightCardComponent {
    @Input() insight: CceInsight;

    public translate: TranslateFunction<typeof ENGLISH>;
    public readonly CceInsightType = CceInsightType;
    public readonly VmwSvgIconColorFormat = VmwSvgIconColorFormat;

    constructor(public l10nService: L10nService) {}
}
