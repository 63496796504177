import { Injectable } from "@angular/core";

import { CspFeatureFlagConfig, CspFeatureFlagCustomStrategyParameters } from "@vmw/csp-ff-sdk";
import { CspFeatureFlagClient } from "@vmw/csp-ngx-ff-sdk";
import { filter, Observable, of, switchMap } from "rxjs";

const OVERRIDES_LOCAL_STORAGE_KEY = "csp_ui_ff_overrides";

@Injectable({
    providedIn: "root",
    deps: [CspFeatureFlagClient],
})
export class FeatureFlagsService {
    private allFlags: any;
    private overrides: any = {};

    constructor(private ffClient: CspFeatureFlagClient) {
        try {
            const overrides = localStorage.getItem(OVERRIDES_LOCAL_STORAGE_KEY);
            if (overrides) {
                this.overrides = JSON.parse(overrides);
            }
        } catch (e) {
            // no overrides or bad JSON
        }
    }

    evaluate$(featureFlagName: string, parameters?: CspFeatureFlagCustomStrategyParameters): Observable<boolean> {
        if (typeof this.overrides[featureFlagName] !== "undefined") {
            return of(this.overrides[featureFlagName]);
        } else {
            return of(this.ffClient.isReady()).pipe(
                filter(Boolean),
                switchMap(() => this.ffClient.evaluate$(featureFlagName, parameters))
            );
        }
    }
}
