import { Component } from "@angular/core";
import { TranslateFunction } from "@cce/core";
import { CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { L10nService, LocalizedComponent, Mixin } from "@vmw/ngx-vip";
import { catchError, EMPTY, filter, forkJoin } from "rxjs";
import { ENGLISH } from "../usage-management-insights/usage-management-insights.component.l10n";
import { InsightsService } from "./insights.service";
import { CceInsight, CceInsightType } from "./usage-manangement-insights.model";
import { ClarityIcons, infoStandardIcon } from "@cds/core/icon";

@Mixin([LocalizedComponent], {
    L10nKey: "usage-management-insights",
    L10nSourceMap: ENGLISH,
})
@Component({
    selector: "app-usage-management-insights",
    templateUrl: "./usage-management-insights.component.html",
    styleUrls: ["./usage-management-insights.component.scss"],
})
export class UsageManagementInsightsComponent {
    public translate: TranslateFunction<typeof ENGLISH>;

    public readonly CceInsightType = CceInsightType;

    private orgId: string;
    public totalUsages: number;
    public usagesAt: number;
    public usagesBelow: number;
    public usagesOver: number;
    public usagesDemand: number;
    public loading = true;
    public insights: CceInsight[] = [];

    constructor(
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        private insightsService: InsightsService,
        public l10nService: L10nService
    ) {
        ClarityIcons.addIcons(infoStandardIcon);
    }

    ngOnInit() {
        this.crossOrgSwitcherService.orgDetails$.pipe(filter(Boolean)).subscribe({
            next: (org) => {
                this.orgId = org.id;
                this.loadInsights();
            },
        });
    }

    private loadInsights() {
        this.loading = true;
        forkJoin({
            insights: this.insightsService.getInsights(this.orgId),
            totals: this.insightsService.getTotalInsights(this.orgId),
        })
            .pipe(
                catchError(() => {
                    this.insights = [];
                    this.loading = false;
                    return EMPTY;
                })
            )
            .subscribe(({ insights, totals }) => {
                this.insights = insights;
                this.totalUsages = totals.totalUsages;
                this.usagesBelow = totals.usagesBelow;
                this.usagesOver = totals.usagesOver;
                this.usagesAt = totals.usagesAt;
                this.usagesDemand = totals.usagesDemand;
                this.loading = false;
            });
    }
}
