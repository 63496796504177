<h1 class="csp-group-main-header">{{translate('billing-and-subscriptions')}}</h1>

<h2 class="csp-group-sub-header">{{translate('usage-management')}}</h2>

<ul class="nav cce-usage-management-tabs" role="tablist" *ngIf="enableTabs$ | async">
    <li role="presentation" class="nav-item" *ngIf="enableUmOverview$ | async">
        <button class="btn btn-link nav-link"
            routerLink="overview" routerLinkActive="active"
            aria-selected="false" type="button">{{translate('overview')}}</button>
    </li>
    <li role="presentation" class="nav-item" *ngIf="enableUsageManagementBudgets$ | async">
        <button class="btn btn-link nav-link"
            routerLink='budgets' routerLinkActive="active"
            aria-selected="false" type="button">{{translate('budgets')}}</button>
    </li>
    <li role="presentation" class="nav-item" *ngIf="enableInsights$ | async">
        <button class="btn btn-link nav-link"
            routerLink='insights' routerLinkActive="active"
            aria-selected="false" type="button">{{translate('insights')}}</button>
    </li>
    <li role="presentation" class="nav-item" *ngIf="enableUmReports$ | async">
        <button class="btn btn-link nav-link"
                routerLink='reports' routerLinkActive="active"
                aria-selected="false" type="button">{{translate('reports')}}</button>
    </li>
    <li role="presentation" class="nav-item" *ngIf="enableUm$ | async">
        <button class="btn btn-link nav-link"
                routerLink='usage-meters' routerLinkActive="active"
                aria-selected="false" type="button">{{translate('usage-meters')}}</button>
    </li>
</ul>

<router-outlet></router-outlet>
