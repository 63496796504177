import { VmwToastType } from "@vmw/ngx-components";

export interface CceUsageManagementBudgetsSort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

export interface CceUsageManagementBudgetsPageable {
    offset: number;
    page_number: number;
    page_size: number;
    paged: boolean;
    sort: CceUsageManagementBudgetsSort;
    unpaged: boolean;
}

export enum CceUsageManagementBudgetType {
    PERCENTAGE = "PERCENTAGE",
    ACTUAL = "ACTUAL",
}

export interface CceUsageManagementBudgetDto {
    id: string;
    org_id: string;
    service_definition_id: string;
    metric_id: string;
    metric_display_name: string;
    budget_type: CceUsageManagementBudgetType;
    budget_value: string;
    unit_of_measure_display_name: string;
    last_updated_at: string;
    last_updated_by: string;
    created_by: string;
    created_at: string;
}

export interface CceUsageManagementBudgetsDto {
    content: CceUsageManagementBudgetDto[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    number_of_elements: number;
    pageable: CceUsageManagementBudgetsPageable;
    size: number;
    sort: CceUsageManagementBudgetsSort;
    total_elements: number;
    total_pages: number;
}

export interface CceUsageManagementBudgetsView {
    budgets: CceUsageManagementBudgetView[];
    totalItems: number;
}

export interface CceUsageManagementBudgetView {
    id: string;
    usageType: string;
    service: string;
    budgetType: CceUsageManagementBudgetType;
    amount: string;
    unit: string;
    lastUpdatedAt: string;
    lastUpdatedBy: string;
}

export interface CceBudget {
    service_definition_id: string;
    metric_id: string;
    budget_type: string;
    budget_value: string;
}

export interface CceEditBudgetBody {
    budget_value: string;
}

export enum CceBudgetSourcePage {
    CREATE = "CREATE",
    EDIT = "EDIT",
}
