import { Injector } from "@angular/core";

import { APP_ENVIRONMENT, mockEnvironment } from "./environment.model";

/**
 * Container exposing static access to the `PlatformRef.injector` created
 * by Angular during bootstrap. It is the root of all injectors and only
 * provides access to the providers explicitly passed to it - see main.ts.
 */
export class RootInjector {
    static #rootInjector: Injector;

    /**
     * Should be called early in the bootstrapping process
     * as soon as the `PlatformRef` instance has been created, e.g
     *
     * const platform = platformBrowserDynamic();
     * RootInjector.setInstance(platform.injector);
     * platform.bootstrapModule(AppModule);
     *
     * see main.ts for example.
     *
     * @throws if root injector has already been set.
     */
    public static setInstance(value: Injector) {
        if (RootInjector.#rootInjector) {
            throw new Error("root injector has already been set.");
        }
        RootInjector.#rootInjector = value;
    }

    /**
     * @returns the application's root injector. If not set, a new injector
     * is created first. This is useful for unit testing scenarios.
     */
    public static getInstance(): Injector {
        if (!RootInjector.#rootInjector) {
            console.warn("Creating a detached root injector, you should not see this warning outside of unit tests.");
            RootInjector.#rootInjector = Injector.create({
                // Certain static utils are used throughout unit tests to get a copy
                // of the environment. This is the most central hook from which to return
                // a mock.
                providers: [
                    {
                        provide: APP_ENVIRONMENT,
                        useValue: mockEnvironment,
                    },
                ],
                name: "detached",
            });
        }
        return RootInjector.#rootInjector;
    }
}
