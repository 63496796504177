export const ENGLISH = {
    "creating-budget-overview-paragraph":
        "You are creating a new budget. You will be notified when your term commitment usage reaches the defined threshold through the notification channels you enabled.",
    "create-budget": "Create Budget",
    "select-a-budget-type": "Select a budget type",
    "commitment-percentage": "Commitment Percentage",
    "actual-usage": "Actual Usage",
    continue: "Continue",
    cancel: "Cancel",
    "select-service": "Select service",
    "select-usage-type": "Select usage type",
    save: "Save",
    "enter-commitment-percentage": "Enter a commitment percentage",
    "enter-usage": "Enter usage",
    "select-a-service": "Select a service",
    "select-a-usage-type": "Select a usage type",
    "%": "%",
    "creating-budget-failed-message": "Could not create this budget, please try again. If the issue persists please report it.",
    "creating-budget-failed-title": "Failed to create this budget",
};
