import { Injectable, Inject, APP_INITIALIZER } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { firstValueFrom, Subject } from "rxjs";
import { filter } from "rxjs/operators";

import { autoExchangeResult$, OAuth2ModuleConfiguration } from "@vmw/ngx-csp-oauth2";
import { OAuth2Client, OAuth2Tokens } from "@vmw/csp-oauth2";
import { CspEnvironment } from "@vmw/csp-common";
import { CspInitializationResult, CspServiceSdkToolkit } from "@vmw/csp-service-sdk";
import { VmwIframeSdkMode } from "@vmw/iframe-sdk-common";
import { CspApiService, CspCrossOrgSwitcherService } from "@vmw/csp-ngx-components";
import { VmwClarityThemeService, VmwClarityTheme } from "@vmw/ngx-utils";
import { CspFeatureFlagClient } from "@vmw/csp-ngx-ff-sdk";

import { APP_ENVIRONMENT, CceUiEnvironment } from "./environment.model";

const CSP_CODE_QUERYPARAM = "code";
const CSP_STATE_QUERYPARAM = "state";
const ORG_OVERRIDE_LS_KEY = "csp-org-override";

export interface EarlyPreloadResult {
    cspInitializationResult: CspInitializationResult;
    cspHost: string;
}

export function earlyPreloadFactory(earlyPreloadService: EarlyPreloadService) {
    return () => {
        return earlyPreloadService
            .initialize()
            .then()
            .catch((e) => {
                console.error(e);
                return new Promise(() => {});
            });
    };
}

@Injectable({
    providedIn: "root",
})
export class EarlyPreloadService extends Subject<EarlyPreloadResult> {
    constructor(
        @Inject(APP_ENVIRONMENT) private environment: CceUiEnvironment,
        private cspServiceSdk: CspServiceSdkToolkit,
        private oauth2Client: OAuth2Client,
        private router: Router,
        private cspApiService: CspApiService,
        private themeService: VmwClarityThemeService,
        private ffClient: CspFeatureFlagClient,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService
    ) {
        super();
    }

    async initialize() {
        let initialRoute: string[] | undefined = undefined;

        console.time("early init");

        const orgId = new URLSearchParams(window.location.search).get("orgId");

        // If we're running in dev or stg, we will allow messages from any origin
        const allowCspLocalDev =
            this.cspServiceSdk.isEmbedded &&
            window.parent.location.origin.indexOf("localhost") !== -1 &&
            ["dev", "stg"].indexOf(this.environment.cspEnvironment) !== -1;

        const config = {
            environment: this.environment.cspEnvironment as CspEnvironment,
            serviceDefinitionId: this.environment.cspServiceDefinitionId,
            localizationEnabled: true,
        };

        // With CSP UI running locally we need to specify the environment as the URL where it is running.
        const configureResult = await this.cspServiceSdk.configure(config);

        let tokens: OAuth2Tokens;

        // Embedded pathway does not redirect for login and instead asks for tokens from host
        if (this.cspServiceSdk.isEmbedded) {
            if (allowCspLocalDev) {
                this.cspServiceSdk.setMode(VmwIframeSdkMode.DEVELOPMENT);
                this.cspServiceSdk.setMessageTarget("*");
            }

            // Listen for route changes sent from the parent app
            this.cspServiceSdk.attachRouteChangeListener((route: string) => {
                // If we don't have tokens yet, just hold the initial route for now. We will do
                // the initial navigation after we have tokens.
                if (!tokens) {
                    initialRoute = route.split("/");
                    return;
                }

                this.router.navigateByUrl(route);
            });

            this.cspServiceSdk.childLoaded();

            // In embedded mode, we need to request the first set of tokens and set them explicitly.
            // After that, we can rely on the OAuth2Client to handle refreshing them when necessary
            tokens = await firstValueFrom(this.cspServiceSdk.getCspTokens());

            this.oauth2Client.setToken(tokens);
        } else {
            // Wait for any code exchange to be performed, we don't need the result of this
            await firstValueFrom(autoExchangeResult$);

            tokens = this.oauth2Client.token();

            if (!tokens) {
                this.crossOrgSwitcherService.clearOverrideOrgId();

                // triggers redirect for auth
                await this.oauth2Client.login();
            } else if (orgId && this.oauth2Client.getOrganizationId() !== orgId) {
                // trigger redirect with a specific org ID
                await this.oauth2Client.login({ orgId });
            }
        }

        // check session storage
        if (!sessionStorage.getItem(ORG_OVERRIDE_LS_KEY)) {
            this.crossOrgSwitcherService.setCurrentOrg(this.oauth2Client.getOrganizationId()).subscribe();
        }

        // Now we know we are logged in, we can initialize the CSP service
        const cspInitResult = await firstValueFrom(
            this.cspApiService.initialize(
                Object.assign(config, {
                    authToken: tokens.access_token,
                })
            )
        );

        this.cspApiService.setLocale(cspInitResult.locale);

        await this.themeService.initialize();

        this.cspServiceSdk.attachThemeChangeListener((theme: VmwClarityTheme) => {
            this.themeService.theme = theme;
        });

        // initialize the csp feature flag
        await this.ffClient.initialize({
            sdkKey: this.environment.cspSdkKey,
            token: tokens.access_token,
        });

        // Now that we have tokens, the guards can execute properly.
        if (initialRoute) {
            this.router.navigate(initialRoute);
        }

        this.router.initialNavigation();

        console.timeEnd("early init");

        const result: EarlyPreloadResult = {
            cspInitializationResult: cspInitResult,
            cspHost: this.cspApiService.getCspHost(),
        };

        this.next(result);
    }
}

export const EARLY_PRELOAD_PROVIDER = {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [
        EarlyPreloadService,
        OAuth2ModuleConfiguration,
        APP_ENVIRONMENT,
        VmwClarityThemeService,
        CspFeatureFlagClient,
        CspCrossOrgSwitcherService,
    ],
    useFactory: earlyPreloadFactory,
};
