<clr-checkbox-container>
    <clr-checkbox-wrapper *ngFor="let displayStatus of displayStatuses;">
        <input  type="checkbox" 
                clrCheckbox
                [value]="displayStatus.status" 
                [attr.data-test-id]="displayStatus.status" 
                [ngModel]="selectedStatuses.includes(displayStatus.status)" 
                (ngModelChange)="toggleStatusSelection(displayStatus.status)" />
        <label>{{translate(displayStatus.l10nKey)}}</label>
    </clr-checkbox-wrapper>
</clr-checkbox-container>
<button class="btn btn-link btn-sm" (click)="onClearSelection()" [disabled]="selectedStatuses.length === 0">
    {{translate("clear-all")}}
</button>
