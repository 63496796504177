import { Component, OnInit } from "@angular/core";
import { UsageManagementBudgetsService } from "../usage-management-budgets.service";
import { CspApiService, CspCrossOrgSwitcherService, CspServiceIconDto } from "@vmw/csp-ngx-components";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
    CceBudget,
    CceBudgetSourcePage,
    CceUsageManagementBudgetType,
    CceUsageManagementBudgetView,
} from "../usage-management-budgets.model";
import { Router } from "@angular/router";
import { VmwComboboxItem } from "@vmw/ngx-components";
import { TranslateFunction } from "@cce/core";
import { ENGLISH } from "./usage-management-budgets-create.component.l10n";
import { L10nService, LocalizedComponent, Mixin } from "@vmw/ngx-vip";
import { filter } from "rxjs";
import { UsageService } from "../../usage-management-overview/usage.service";
import { CceUsageMetricUsageRecordViewModel } from "../../usage-management-overview/usage-management.model";
import { VmwNgxButtonType, VmwNgxModalService } from "@vmw/ngx-modal-service";

class ComboboxItem {
    toString() {
        return this.displayName;
    }
    constructor(public displayName: string, public id: string, public unit?: string) {}
}

@Mixin([LocalizedComponent], {
    L10nKey: "usage-management-budgets",
    L10nSourceMap: ENGLISH,
})
@Component({
    templateUrl: "./usage-management-budgets-create.component.html",
    styleUrls: ["./usage-management-budgets-create.component.scss"],
})
export class UsageManagementBudgetsCreateComponent implements OnInit {
    public translate: TranslateFunction<typeof ENGLISH>;

    public selectedBudgetType: string | undefined;

    public showSelectBudgetTypeView: boolean = true;
    public showSelectMetricInformationView: boolean = false;
    public loadingMetricOptions: boolean = true;

    public services: VmwComboboxItem<ComboboxItem>[] = [];
    public metrics: VmwComboboxItem<ComboboxItem>[] = [];

    public form: FormGroup;
    public orgId: string;
    public unit: string = "";
    public readonly CceUsageManagementBudgetType = CceUsageManagementBudgetType;

    public isCreatingBudget: boolean = false;
    get isError(): boolean {
        return !this.form.get("budgetTypeValue").valid && this.form.get("budgetTypeValue").touched;
    }

    constructor(
        private budgetsService: UsageManagementBudgetsService,
        private crossOrgSwitcherService: CspCrossOrgSwitcherService,
        private cspApiService: CspApiService,
        private formBuilder: FormBuilder,
        private router: Router,
        public l10nService: L10nService,
        private usageService: UsageService,
        private modalService: VmwNgxModalService
    ) {}

    ngOnInit(): void {
        this.crossOrgSwitcherService.orgDetails$.pipe(filter(Boolean)).subscribe({
            next: (org) => {
                this.orgId = org.id;
                this.initCreateBudget();
            },
        });
    }

    private initCreateBudget() {
        this.usageService.getOrganizationMetricUsageRecords(this.orgId, 0, 50).subscribe({
            next: (orgMetrics) => {
                this.populateServices(orgMetrics.metrics);
                this.populateMetrics(orgMetrics.metrics);

                this.form = this.formBuilder.group({
                    service: ["", [Validators.required, this.notEmpty.bind(this)]],
                    metric: ["", [Validators.required, this.notEmpty.bind(this)]],
                    budgetTypeValue: ["", Validators.required],
                });

                this.form.get("service")?.valueChanges.subscribe((s) => {
                    this.form.get("metric").setValue("");
                    this.populateMetrics(orgMetrics.metrics, s.value);
                });

                this.form.get("metric")?.valueChanges.subscribe((m) => {
                    this.unit = m?.displayValue?.unit ?? "";
                });

                this.loadingMetricOptions = false;
            },
            error: (err) => {
                this.loadingMetricOptions = false;
                console.error(err);
            },
        });
    }

    private populateServices(metrics: CceUsageMetricUsageRecordViewModel[]): void {
        const uniqueServices = metrics
            .map((orgUsages) => {
                return { service_definition_id: orgUsages.serviceId, service: orgUsages.serviceDisplayName };
            })
            .reduce((acc: any[], current) => {
                if (!acc.some((obj) => obj.service_definition_id === current.service_definition_id)) {
                    acc.push(current);
                }
                return acc;
            }, []);

        uniqueServices.forEach((s) => {
            this.services.push(new VmwComboboxItem(new ComboboxItem(s.service, s.service_definition_id), s.service_definition_id));
        });

        this.services.forEach((s: VmwComboboxItem<ComboboxItem>) => {
            this.cspApiService.getServiceIcon(s.displayValue.id).subscribe((iconDto: CspServiceIconDto) => {
                s.img = iconDto.svg;
            });
        });
    }

    private populateMetrics(metrics: CceUsageMetricUsageRecordViewModel[], serviceId?: string): void {
        this.metrics = [];
        const uniqueUsageTypes = metrics
            .filter((orgUsages) => {
                return orgUsages.serviceId === serviceId;
            })
            .map((orgUsages) => {
                return { metricId: orgUsages.metricId, usageType: orgUsages.usageType, unit: orgUsages.unit };
            })
            .reduce((acc: any[], current) => {
                if (!acc.some((obj) => obj.metricId === current.metricId)) {
                    acc.push(current);
                }
                return acc;
            }, []);

        uniqueUsageTypes.forEach((m) => {
            this.metrics.push(new VmwComboboxItem(new ComboboxItem(m.usageType, m.metricId, m.unit), m.metricId));
        });
    }

    private notEmpty(control: FormControl) {
        if (control.value?.value !== "") {
            return null;
        } else {
            return {
                empty: "true",
            };
        }
    }

    public continueCreatingBudget() {
        this.showSelectBudgetTypeView = false;
        this.showSelectMetricInformationView = true;
    }

    public cancelMetricInformationSelection() {
        this.selectedBudgetType = undefined;
        this.resetForm();
        this.showSelectMetricInformationView = false;
        this.showSelectBudgetTypeView = true;
    }

    private resetForm() {
        this.form.get("metric").setValue("");
        this.form.get("service").setValue("");
        this.form.get("budgetTypeValue").setValue("");
    }

    public createBudget() {
        this.isCreatingBudget = true;

        const b: CceBudget = {
            service_definition_id: this.form.get("service").value,
            metric_id: this.form.get("metric").value,
            budget_type: this.selectedBudgetType,
            budget_value: this.form.get("budgetTypeValue").value,
        };

        this.budgetsService.createBudget(this.orgId, b).subscribe({
            next: (budgetView: CceUsageManagementBudgetView) => {
                this.isCreatingBudget = false;
                this.router.navigate(["usage-management/budgets"], { state: { sourcePage: CceBudgetSourcePage.CREATE } });
            },
            error: (err) => {
                console.error(err);
                this.modalService.openAlert(this.translate("creating-budget-failed-message"), {
                    title: this.translate("creating-budget-failed-title"),
                    buttons: {
                        closeButton: {
                            type: VmwNgxButtonType.Danger,
                        },
                    },
                });
                this.isCreatingBudget = false;
            },
        });
    }
}
