import { Component, ContentChild, ContentChildren, ElementRef, HostListener, Input, QueryList, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { CceHorizontalBarChartBarComponent } from "./cce-horizontal-bar-chart-bar/cce-horizontal-bar-chart-bar.component";
import { CceHorizontalBarChartService } from "./cce-horizontal-bar-chart.service";

@Component({
    selector: "cce-bar-chart-threshold-label",
    template: "<ng-content></ng-content>",
    styles: [
        `
            :host {
                position: absolute;
                display: block;
            }
        `,
    ],
})
export class CceHorizontalBarChartThresholdLabelComponent {}

@Component({
    selector: "cce-horizontal-bar-chart",
    templateUrl: "./cce-horizontal-bar-chart.component.html",
    styles: [
        `
            :host {
                position: relative;
                display: block;
            }
        `,
    ],
    providers: [CceHorizontalBarChartService],
})
export class CceHorizontalBarChartComponent {
    @ContentChild(CceHorizontalBarChartThresholdLabelComponent, { read: ElementRef })
    private thresholdLabel: ElementRef;

    public isThresholdEnabled: boolean;

    private barSub: Subscription;

    @Input()
    set enableThreshold(enabled: boolean) {
        this.service.isThresholdEnabled = enabled;
        this.isThresholdEnabled = enabled;
    }

    @ContentChildren(CceHorizontalBarChartBarComponent)
    private bars: QueryList<CceHorizontalBarChartBarComponent>;

    @ViewChild("thresholdSpacer", { static: true }) private thresholdSpacerEl: ElementRef;

    constructor(private service: CceHorizontalBarChartService) {}

    ngAfterViewInit() {
        this.bars.changes.subscribe((bars) => {
            this.service.bars = bars;
            if (this.barSub) {
                this.barSub.unsubscribe();
                this.subscribeToBar();
            }
        });
        this.service.bars = Array.from(this.bars);

        if (this.bars.get(0)) {
            this.subscribeToBar();
        }
    }

    private subscribeToBar() {
        this.barSub = this.bars.get(0).ready$.subscribe(this.calculateThresholdLabelPosition.bind(this));
    }

    private calculateThresholdLabelPosition() {
        if (!this.isThresholdEnabled) {
            return;
        }
        const x = this.bars.get(0).getThresholdX();
        const xPos = x - this.thresholdLabel.nativeElement.getBoundingClientRect().width / 2;
        this.thresholdLabel.nativeElement.style.left = `${xPos}px`;
        const labelHeight = this.thresholdLabel.nativeElement.getBoundingClientRect().height;

        this.thresholdSpacerEl.nativeElement.style.height = `${labelHeight}px`;
        this.bars.last.el.nativeElement.style.marginBottom = "10px";
    }

    @HostListener("window:resize")
    public resize() {
        this.calculateThresholdLabelPosition();
    }
}
