import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CspServiceSdkToolkit } from "@vmw/csp-service-sdk";

@Injectable({
    providedIn: "root",
})
export class CcePageTitleService {
    constructor(private cspServiceSdk: CspServiceSdkToolkit, private titleService: Title) {}

    public setPageTitle(title: string) {
        // if we are running in embedded mode - send the page title to the host
        if (this.cspServiceSdk.isEmbedded) {
            this.cspServiceSdk.setPageTitle(title);
        } else {
            // we want to set the page title ourselves
            this.titleService.setTitle(title);
        }
    }
}
